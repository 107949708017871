<template>
  <base-page>
    <div class="pb-6">
      <sw-page-header :title="$tc('settings.setting', 1)">
        <sw-breadcrumb slot="breadcrumbs">
          <sw-breadcrumb-item
            :title="$t('general.home')"
            to="/admin/dashboard"
          />
          <sw-breadcrumb-item
            :title="$tc('settings.setting', 2)"
            to="/admin/settings/user-profile"
            active
          />
        </sw-breadcrumb>
      </sw-page-header>
    </div>

    <div class="w-full mb-6 select-wrapper xl:hidden">
      <sw-select
        :options="menuItems[0]"
        v-model="currentSetting"
        :searchable="true"
        :show-labels="false"
        :allow-empty="false"
        :custom-label="getCustomLabel"
        @input="navigateToSetting"
      />
    </div>

    <div class="grid md:grid-cols-12">
      <div class="hidden col-span-3 mt-1 xl:block">
        <sw-list>
          <sw-list-item
            v-for="(menuItem, index) in menuItems[0]"
            :title="$t(menuItem.title)"
            :key="index"
            :to="menuItem.link"
            :active="hasActiveUrl(menuItem.link)"
            tag-name="router-link"
            class="py-3"
          >
            <component slot="icon" :is="menuItem.icon" class="h-5" />
          </sw-list-item>
        </sw-list>
      </div>

      <div class="col-span-12 xl:col-span-9">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </base-page>
</template>

<script>
import {
  UserIcon,
  OfficeBuildingIcon,
  BellIcon,
  CheckCircleIcon,
  ClipboardListIcon,
  CubeIcon,
  ClipboardCheckIcon,  
  UserGroupIcon,
  ArrowsExpandIcon,
} from '@vue-hero-icons/outline'

import {
  RefreshIcon,
  CogIcon,
  MailIcon,
  PencilAltIcon,
  CloudUploadIcon,
  FolderIcon,
  DatabaseIcon,
  CreditCardIcon,
  LibraryIcon,
  BriefcaseIcon,
  TruckIcon,
  ShoppingBagIcon,
  ViewGridAddIcon,
  DocumentTextIcon

} from '@vue-hero-icons/solid'
import { mapGetters, mapActions } from 'vuex'

import $utils from '../../helpers/utilities'
import $permissionsStub from '../../stub/permissions'
import Ls from '../../services/ls'
export default {
  
  components: {
    UserIcon,
    OfficeBuildingIcon,
    PencilAltIcon,
    CogIcon,
    CheckCircleIcon,
    ClipboardListIcon,
    MailIcon,
    BellIcon,
    FolderIcon,
    LibraryIcon,
    RefreshIcon,
    CubeIcon,
    CloudUploadIcon,
    DatabaseIcon,
    CreditCardIcon,
    ClipboardCheckIcon,
    BriefcaseIcon,
    ShoppingBagIcon,
    DocumentTextIcon,
    TruckIcon,
    ArrowsExpandIcon,
    ViewGridAddIcon,
    UserGroupIcon
  },

  data() {
    return {
      currentSetting: {
        link: '/admin/settings/user-profile',
        title: 'settings.menu_title.account_settings',
        icon: 'user-icon',
      },
      closeNotification : false,
      closeNotes: false,
      closeCustomFields : false,
      closeExpenses : false,
      isRequestOnGoing: false,
     
    }
  },

  computed : {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('company', ['getSelectedCompany']),
    ...mapGetters('company', {
      selectedCompany: 'getSelectedCompany',
    }),
    company(){
     return this.getSelectedCompany
    },

     menuItems() {
     
      let menu = [
        [
          {
            link: '/admin/settings/user-profile',
            title: 'settings.menu_title.account_settings',
            icon: 'user-icon',
          },         
         
        ]
      ]

      if (Ls.get('currentRole') == 'super admin') {
        menu[0] = [
          ...menu[0],
          {
            link: '/admin/settings/company-info',
            title: 'settings.menu_title.company_information',
            icon: 'office-building-icon',
          },
          {
          link: '/admin/settings/preferences',
          title: 'settings.menu_title.preferences',
          icon: 'cog-icon',
        },
        {
          link: '/admin/settings/customization',
          title: 'settings.menu_title.customization',
          icon: 'pencil-alt-icon',
        },
        
        {
          link: '/admin/settings/document-types',
          title: 'document_types.title',
          icon: 'document-text-icon',
        },

        ]
      }
      if(Ls.get('currentRole') == 'user') {
        menu[0] = [
          ...menu[0],
          {
            link: '/admin/settings/contract',
            title: 'navigation.contract',
            icon: 'document-text-icon',
          },
        ]
      }

   
      return menu
      },
  },

  watch: {
    '$route.path'(newValue) {
      if (newValue === '/admin/settings') {
        this.$router.push('/admin/settings/user-profile')
      }
    },
  },

  mounted() {
    this.currentSetting = this.menuItems.find(
      (item) => item.link == this.$route.path
    )
  },

  created() {
    if (this.$route.path === '/admin/settings') {
      this.$router.push('/admin/settings/user-profile')
    }
  },

  methods: {

    getCustomLabel({ title }) {
      return this.$t(title)
    },
    hasActiveUrl(url) {
      return this.$route.path.indexOf(url) > -1
    },
    navigateToSetting(setting) {
      this.$router.push(setting.link)
    },
  },
}
</script>
