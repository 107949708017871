import * as types from './mutation-types'

export const fetchDocumentTypes = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/document_types`, { params })
      .then((response) => {
        commit(types.SET_DOCUMENT_TYPES, response.data.document_types.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchDocumentType = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/document_types/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addDocumentType = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/document_types', data)
      .then((response) => {
        commit(types.ADD_DOCUMENT_TYPE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateDocumentType = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/document_types/${data.id}`, data)
      .then((response) => {
        commit(types.UPDATE_DOCUMENT_TYPE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteDocumentType = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/v1/document_types/${id}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.DELETE_DOCUMENT_TYPE, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
