<template>
  <form action="" @submit.prevent="submitData">
    <div class="p-8 sm:p-6">
        
        <sw-input-group
          :label="$tc('settings.account_settings.email')"
          :error="emailError"
        >
          <sw-input
            v-model="formData.email"
            :invalid="$v.formData.email.$error"
            :placeholder="$t('settings.user_profile.email')"
            class="mt-2"
            @input="$v.formData.email.$touch()"
          />
        </sw-input-group>

        <sw-input-group
          :label="$tc('settings.account_settings.password')"
          :error="passwordError"
        >
          <sw-input
            v-model="formData.password"
            :invalid="$v.formData.password.$error"
            :placeholder="$t('settings.user_profile.password')"
            type="password"
            class="mt-2"
            @input="$v.formData.password.$touch()"
          />
        </sw-input-group>

        <sw-input-group
          :label="$tc('settings.account_settings.confirm_password')"
          :error="confirmPasswordError"
          class="mt-1 mb-2"
        >
          <sw-input
            v-model="formData.confirm_password"
            :invalid="$v.formData.confirm_password.$error"
            :placeholder="$t('settings.user_profile.confirm_password')"
            type="password"
            @input="$v.formData.confirm_password.$touch()"
          />
        </sw-input-group>
    </div>
    <div
      class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg"
    >
      <sw-button
        type="button"
        variant="primary-outline"
        class="mr-3 text-sm"
        @click="closeCompanyModal"
      >
        {{ $t('general.cancel') }}
      </sw-button>
      <sw-button variant="primary" type="submit" :loading="isLoading">
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ !isEdit ? $t('general.save') : $t('general.update') }}
      </sw-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const { required, minLength, maxLength, email, sameAs } = require('vuelidate/lib/validators')
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      pack :null,
      packs : [],
      formData: {
        id: null,
        email: null,
        password : null,
        confirm_password : null,
      },
    }
  },

  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      'refreshData',
    ]),

    confirmPasswordError() {
      if (!this.$v.formData.confirm_password.$error) {
        return ''
      }

      if (!this.$v.formData.confirm_password.sameAsPassword) {
        return this.$tc('validation.password_incorrect')
      }
    },

     emailError() {
      if (!this.$v.formData.email.$error) {
        return ''
      }
      if (!this.$v.formData.email.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.email.email) {
        return this.$tc('validation.email_incorrect')
      }
    },

    passwordError() {
      if (!this.$v.formData.password.$error) {
        return ''
      }
      if (!this.$v.formData.password.minLength) {
        return this.$tc(
          'validation.password_min_length',
          this.$v.formData.password.$params.minLength.min,
          { count: this.$v.formData.password.$params.minLength.min }
        )
      }
    },
   
  },
  validations: {
    formData: {
        email: {
            required,
            email,
        },
        password: {
            minLength: minLength(8),
        },
        confirm_password: {
            sameAsPassword: sameAs('password'),
        },
    },
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true
        this.setData()
      } else {
        this.isEdit = false
      }
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData()
      }
    },
  },

  mounted() {
    if (this.modalDataID) {
      this.isEdit = true
      this.setData()
    }
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('users', ['updateAdminInfo']),

    resetFormData() {
      this.formData = {
        id: null,
        email: null,
        password : null,
        confirm_password : null
      }
      this.$v.formData.$reset()
    },

    async submitData() {
        this.$v.formData.$touch()

        if (this.$v.$invalid) {
            return true
        }
        this.isLoading = true

        let res = await this.updateAdminInfo(this.formData)

        if (res.data.success) {
            window.toastr['success'] ('Admin modifié avec succès !')
            this.refreshData ? this.refreshData() : ''
            this.closeCompanyModal()
            
            this.isLoading = false
            return true
        }
        window.toastr['error'](res.data.error)
      
    },

    async setData() {
      this.formData = {
        id: this.modalData.id,
        email: this.modalData.email,
      }
    },


    closeCompanyModal() {
      this.resetFormData()
      this.closeModal()
    },
  },
}
</script>
