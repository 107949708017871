var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white mt-4" },
    [
      _c("div", { staticClass: "mt-8" }, [
        _c("h2"),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "flex justify-center text-lg xl:text-lg" },
          [
            _c("gmap-autocomplete", {
              staticClass: "border border-gray-400 p-2",
              on: { place_changed: _vm.initMarker },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "bg-gray-400 p-2",
                on: { click: _vm.addLocationMarker },
              },
              [_vm._v(" Ajouter")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "gmap-map",
        {
          staticStyle: { width: "100%", height: "600px" },
          attrs: { zoom: 14, center: _vm.center, options: _vm.options },
        },
        [
          _vm._l(_vm.locationMarkers, function (m, index) {
            return _c("gmap-marker", {
              key: index,
              attrs: { position: m.position },
              on: {
                click: function ($event) {
                  _vm.center = m.position
                },
              },
            })
          }),
          _vm._v(" "),
          _c("GmapPolyline"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }