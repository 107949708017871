var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        {
          attrs: {
            title:
              _vm.currentUser.role == "user"
                ? _vm.$tc("recaps.title", 2)
                : _vm.$tc("recaps.title", 1),
          },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title:
                    _vm.currentUser.role == "user"
                      ? _vm.$tc("recaps.title", 2)
                      : _vm.$tc("recaps.title", 1),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalRecaps,
                      expression: "totalRecaps",
                    },
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentUser.role != "user"
                ? _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: { variant: "primary", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.addRecap()
                        },
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("recaps.add_recap")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _vm.currentUser.role != "user"
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$tc("documents.user", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "userSelect",
                        on: {
                          select: _vm.onSelectUser,
                          deselect: _vm.clearUserSearch,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("recaps.date"), color: "black-light" },
                },
                [
                  _c("VueDatePicker", {
                    attrs: {
                      type: "month",
                      placeholder: "Choisir un mois",
                      "fullscreen-mobile": "",
                    },
                    model: {
                      value: _vm.filters.recap_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "recap_date", $$v)
                      },
                      expression: "filters.recap_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("recaps.no_recaps"),
            description: _vm.$t("recaps.list_of_recaps"),
          },
        },
        [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [
              _c(
                "p",
                { staticClass: "text-sm", staticStyle: { top: "50px" } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.recaps.length))]),
                  _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                  _c("b", [_vm._v(_vm._s(_vm.totalRecaps))]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.recaps && _vm.recaps.length,
                expression: "recaps && recaps.length",
              },
            ],
            staticClass:
              "absolute z-10 items-center pl-4 mt-2 select-none md:mt-6",
          }),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c(
                "sw-table-column",
                {
                  attrs: {
                    sortable: false,
                    filterable: false,
                    "cell-class": "no-click",
                  },
                },
                [_c("span")]
              ),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  "sort-as": "recap_date",
                  label: _vm.$t("recaps.date"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("recaps.date")))]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.format(row.recap_date)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.currentUser.role != "user"
                ? _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      "sort-as": "partenaire_name",
                      label: _vm.$t("recaps.partenaire"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("recaps.partenaire"))),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.partenaire_name) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1833338426
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, label: _vm.$t("recaps.recap_admin") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("recaps.recap_admin"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            staticClass: "px-1 text-xs",
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.recap_admin ? "ACCEPTED" : "REJECTED"
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(
                                row.recap_admin ? "ACCEPTED" : "REJECTED"
                              ).color,
                              "font-size": _vm.$utils.getBadgeStatusColor(
                                row.recap_admin ? "ACCEPTED" : "REJECTED"
                              ).fontSize,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.recap_admin
                                    ? _vm.currentUser.role != "user"
                                      ? "Envoyé"
                                      : "Reçu"
                                    : "NON"
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label:
                    _vm.currentUser.role == "user"
                      ? _vm.$t("recaps.my_recap")
                      : _vm.$t("recaps.recap_user"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("recaps.recap_user"))),
                        ]),
                        _vm._v(".\n          "),
                        _c(
                          "sw-badge",
                          {
                            staticClass: "px-1 text-xs",
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.recap_partenaire ? "ACCEPTED" : "REJECTED"
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(
                                row.recap_partenaire ? "ACCEPTED" : "REJECTED"
                              ).color,
                              "font-size": _vm.$utils.getBadgeStatusColor(
                                row.recap_partenaire ? "ACCEPTED" : "REJECTED"
                              ).fontSize,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.recap_partenaire
                                    ? _vm.currentUser.role != "user"
                                      ? "Reçu"
                                      : "Envoyé"
                                    : "NON"
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("recaps.action")) + " "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _vm.currentUser.role == "user" &&
                            !row.recap_partenaire
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.addRecap(row.recap_date)
                                      },
                                    },
                                  },
                                  [
                                    _c("plus-circle-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: {
                                    path: `recaps/${row.id}/view/${row.media[0].id}`,
                                  },
                                },
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "sw-table-column",
                { attrs: { sortable: false, filterable: false } },
                [[_c("span")]],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }