<template>
  <form action="" @submit.prevent="submitData">
    <div class="p-8 sm:p-6">
      <sw-input-group
        :label="$t('documents.type')"
        :error="typeError"
        required
        v-if="!modalData"
      >
        <sw-select
          v-model="selectedTypeDocument"
          :options="document_types"
          :searchable="true"
          :show-labels="false"
          :allow-empty="false"
          :invalid="$v.selectedTypeDocument.$error"
          placeholder="Tapez ou cliquez pour choisir un type"
          class="mt-2"
          label ="name"
        />
      </sw-input-group>

      <sw-input-group
        :label="$t('documents.date')"
        :error="dateError"
        required
      >
         <!-- <base-date-picker
            v-model="date_delivrance"
            :calendar-button="true"
            calendar-button-icon="calendar"
            :invalid="$v.date_delivrance.$error"
            class="mt-2"
        /> -->
        <!-- <date-picker v-model="date_delivrance"
            :popover="{ visibility : 'click' }"
            class="relative w-full"
        >
            <template v-slot="{ inputValue, inputEvents }">
                <input
                class="bg-white border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
                />
            </template>
        </date-picker> -->
        <VueDatePicker 
          fullscreen-mobile
          v-model="date_delivrance"
          :allowed-dates="allowedDates"
          placeholder="Cliquez et choisissez une date"
       />

      </sw-input-group>
       <sw-input-group 
            :label="$t('estimates.file')"
            required         
            :error="fileError"  
          >
            <div
              id="receipt-box"
              class="relative flex items-center justify-center h-28 p-10 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box"
            >
              <img
                v-if="attachment"
                :src="attachment"
                class="absolute opacity-100 preview-logo"
                style="max-height: 80%; animation: fadeIn 2s ease"
              />
              <div v-else class="flex flex-col items-center">
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-500"
                />
                <p class="text-xs leading-4 text-center text-gray-500">
                Cliquez ici et 
                  <span id="pick-avatar" class="cursor-pointer text-primary-500" >choisissez</span>
                  un fichier
                </p>
              </div>
            </div>
           
            <sw-avatar
              :preview-avatar="attachment"
              :enable-cropper="false"
              trigger="#receipt-box"
              @changed="onChange"
            >
              <template v-slot:icon>
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-400"
                />
              </template>
            </sw-avatar>
           
        </sw-input-group>    
    </div>
    <div
      class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg"
    >
      <sw-button
        type="button"
        variant="primary-outline"
        class="mr-3 text-sm"
        @click="closeDocumentModal"
      >
        {{ $t('general.cancel') }}
      </sw-button>
      <sw-button variant="primary" type="submit" :loading="isLoading">
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ !isEdit ? $t('general.save') : $t('general.update') }}
      </sw-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CloudUploadIcon } from '@vue-hero-icons/solid'
import moment from 'moment'
const { required, minLength, requiredIf, numeric } = require('vuelidate/lib/validators')
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
export default {
    components : {
        CloudUploadIcon,
        // DatePicker,
        VueDatePicker
    },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      attachment: null,
      fileObject: null,
      date_delivrance : null,
      type_document_id : null,
      document_types : [],
      selectedTypeDocument : null
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      'refreshData',
    ]),
    fileError() {
      if (!this.$v.attachment.$error) {
        return ''
      }

      if (!this.$v.attachment.required) {
        return this.$tc('validation.required')
      }
    },
    dateError () {
      if (!this.$v.date_delivrance.$error) {
        return ''
      }
      if (!this.$v.date_delivrance.required) {
        return this.$tc('validation.required')
      }
    },
    typeError () {
      if (!this.$v.selectedTypeDocument.$error) {
        return ''
      }
      if (!this.$v.selectedTypeDocument.required) {
        return this.$tc('validation.required')
      }
    },
  },
  validations: {
    date_delivrance :{
       required
    },
    attachment : {
        required : requiredIf(function(){return !this.isEdit})
    },
    selectedTypeDocument : {
        required : requiredIf(function(){ return !this.modalData})
    }
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true
        this.setData()
      } else {
        this.isEdit = false
      }
    },
    date_delivrance(val) {
        if(val)
            return moment(val).format('YYYY-MM-DD')
        console.log(val)
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData()
      }
    },
  },

  mounted() {
    if (this.modalDataID) {
      this.isEdit = true
      this.setData()
    }
    if (this.modalData) {
        this.type_document_id = this.modalData.type_id
    }
    this.loadTypes()
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('document', ['addDocument', 'updateDocument','getDocumentFile']),
    ...mapActions('documentType', ['fetchDocumentTypes', ]),

    async loadTypes() {
        let res = await this.fetchDocumentTypes({limit:'all', all_types : 'false'})
        this.document_types = res.data.document_types
    },
    async getFile() {
      let res = await this.getDocumentFile(this.modalDataID)

      if (res.data.error) {
        this.isReceiptAvailable = false
        return true
      }
        console.log(res.data)
      this.isReceiptAvailable = true
      this.attachment = res.data.image
      this.fileObject = new File([''],res.data.media.file_name, {type: res.data.type, size : res.data.media.size})
      console.log(this.fileObject)
    },
    resetFormData() {
      this.date_delivrance = null
      this.attachment = null
      this.fileObject = null
      this.$v.date_delivrance.$reset()
      this.$v.attachment.$reset()
    },
    onChange(data) {
      this.attachment = data.image
      this.fileObject = data.file
      console.log(this.fileObject)
    },

    allowedDates: date => {
      // date is : YYYY-MM-DD
      return date <= moment().format('YYYY-MM-DD');
    },

    async submitData() {
      this.$v.date_delivrance.$touch()
      this.$v.attachment.$touch()

      if (this.$v.$invalid) {
        return true
      }
      this.date_delivrance = moment(this.date_delivrance).format('YYYY-MM-DD')
      console.log(this.date_delivrance)
      console.log(this.fileObject)

      if(!this.type_document_id)
        this.type_document_id = this.selectedTypeDocument.id
    //   return
      console.log(this.type_document_id)

      this.isLoading = true
      let response
      let data = new FormData()

      if (this.fileObject) {
          data.append('attachment', this.fileObject)
          data.append('type_document_id', this.type_document_id)
          data.append('date_delivrance', this.date_delivrance)
          data.append('file', this.fileObject.name)
          if(this.isEdit)
            data.append('_method', 'PUT')

      }
      else if(this.isEdit) {
        // data.append('type_document_id', this.type_document_id)
        data.append('date_delivrance', this.date_delivrance)
        data.append('_method', 'PUT')
      }
      try {
        if (!this.isEdit) {
              response = await this.addDocument(data)
          } else {
              console.log(data.entries())
              response = await this.updateDocument({data : data, id : this.modalDataID} )
          }

        if (response.data) {
          if (!this.isEdit) {
            window.toastr['success'](
              this.$t('documents.created_message')
            )
             this.$router.push(`/admin/documents/${response.data.document.id}/view`)
          } else {
            window.toastr['success'](
              this.$t('documents.updated_message')
            )
            if(this.$route.name == 'documents.view')
              this.$router.go()
          }
          window.hub.$emit('newDocument', response.data.document)
          this.refreshData ? this.refreshData() : ''
          this.closeDocumentModal()
          this.isLoading = false
          return true
        }
        window.toastr['error'](response.data.error)
     }catch(error) {
            console.log(error)
            this.isLoading = false
            this.resetFormData()
            // window.toastr['error'](error)
        }

    },
    test() {
        console.log(this.date_delivrance)
    },
    setData() {
        // this.getFile()
      this.date_delivrance = moment(
                this.modalData.document.date_delivrance
              ).format('YYYY-MM-DD'),
      console.log(this.modalData)
      console.log(this.date_delivrance)
    },
    closeDocumentModal() {
      this.resetFormData()
      this.closeModal()
    },
  },
}
</script>
