import * as types from './mutation-types'

export default {
  [types.SET_DOCUMENTS](state, data) {
    state.documents = data
  },


  [types.SET_TOTAL_DOCUMENTS](state, totalDocuments) {
    state.totalDocuments = totalDocuments
  },

  [types.ADD_DOCUMENT](state, data) {
    state.documents = [...state.documents, data]
  },

  [types.DELETE_DOCUMENT](state, id) {
    let index = state.documents.findIndex((document) => document.id === id)
    state.documents.splice(index, 1)
  },

  [types.SET_SELECTED_DOCUMENTS](state, data) {
    state.selectedEstimates = data
  },

  [types.DELETE_MULTIPLE_DOCUMENTS](state, selectedEstimates) {
    selectedEstimates.forEach((document) => {
      let index = state.documents.findIndex((_est) => _est.id === document.id)
      state.documents.splice(index, 1)
    })

    state.selectedEstimates = []
  },

  [types.UPDATE_DOCUMENT](state, data) {
    let pos = state.documents.findIndex(
      (document) => document.id === data.document.id
    )

    state.documents[pos] = data.document
  },

  [types.UPDATE_DOCUMENT_STATUS](state, data) {
    let pos = state.documents.findIndex((document) => document.id === data.id)
    if (state.documents[pos]) {
      // state.documents[pos] = { ...state.documents[pos], status: data.status }
      state.documents[pos].status = data.status
    }
  },

  [types.RESET_SELECTED_DOCUMENTS](state, data) {
    state.selectedEstimates = []
    state.selectAllField = false
  },


  [types.SET_SELECT_ALL_STATE](state, data) {
    state.selectAllField = data
  },

  [types.VIEW_DOCUMENT](state, document) {
    state.selectedEstimate = document
  },

}
