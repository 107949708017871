import * as types from './mutation-types'
import Ls from '@/services/ls'

export default {
  [types.RESET_CURRENT_USER](state, user) {
    state.currentUser = null
    Ls.set('currentRole', '')
    // Ls.set('userRole', '' ) 
    // Ls.set('userPermissions', [])
  },

  [types.BOOTSTRAP_CURRENT_USER](state, user) {
    state.currentUser = user
    // Ls.set('userRole', user.roles.length > 0 ? user.roles[0].name : '' ) 
    Ls.set('currentRole', user.role)

    let permissions = ''
    // if (user.roles.length > 0) {
    //   user.roles[0].permissions.forEach(per => {
    //     permissions+= per.name+' '
    //   });
    // }
    
    Ls.set('userPermissions', permissions) 
  },

  [types.UPDATE_CURRENT_USER](state, user) {
    state.currentUser = user
     
  },

  [types.UPDATE_USER_AVATAR](state, data) {
    if (state.currentUser) {
      state.currentUser.avatar = data.avatar
    }
  },

  [types.SET_DEFAULT_LANGUAGE](state, data) {
    window.i18n.locale = data
  },
}
