var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 58 58" },
      attrs: {
        version: "1.1",
        id: "Capa_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 58 58",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          staticStyle: { fill: "#2CB742" },
          attrs: {
            d: "M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5\n\t\tS45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticStyle: { fill: "#FFFFFF" },
          attrs: {
            d: "M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42\n\t\tc0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242\n\t\tc0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169\n\t\tc-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097\n\t\tc5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }