<template>
  <sw-card variant="setting-card">
    <div slot="header" class="flex flex-wrap justify-between lg:flex-nowrap">
      <div>
        <h6 class="sw-section-title">
          {{ $t('document_types.title') }}
        </h6>
        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
          {{ $t('document_types.description') }}
        </p>
      </div>
      <div class="mt-4 lg:mt-0 lg:ml-2">
        <sw-button
          variant="primary-outline"
          size="lg"
          @click="addDocumentType"
        >
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('document_types.add_document_type') }}
        </sw-button>
      </div>
    </div>

    <sw-table-component
      ref="table"
      :show-filter="false"
      :data="fetchData"
      variant="gray"
    >
      <sw-table-column
        :label="$t('document_types.name')"
        show="name"
      >
        <template slot-scope="row">
          <span>{{ $t('document_types.name') }}}</span>
          <span class="mt-6">{{ row.name }}</span>
        </template>
      </sw-table-column>

      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('document_types.validity') + ' en jours'"
      >
        <template slot-scope="row">
          <span>{{
            $t('document_types.validity')
          }}</span>
          <div class="w-48 overflow-hidden notes">
            <div
              class="overflow-hidden whitespace-nowrap"
              style="text-overflow: ellipsis"
            >
              {{ row.validity != -1 ? row.validity : '' }}
            </div>
          </div>
        </template>
      </sw-table-column>

      <sw-table-column
        :sortable="false"
        :filterable="false"
        cell-class="action-dropdown"
      >
        <template slot-scope="row">
          <span>{{ $t('document_types.action') }}</span>
          <sw-dropdown>
            <dot-icon slot="activator" class="h-5" />

            <sw-dropdown-item @click="editDocumentType(row.id)">
              <pencil-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.edit') }}
            </sw-dropdown-item>

            <sw-dropdown-item @click="removeDocumentType(row.id)">
              <trash-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.delete') }}
            </sw-dropdown-item>
          </sw-dropdown>
        </template>
      </sw-table-column>
    </sw-table-component>
  </sw-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TrashIcon, PencilIcon, PlusIcon, } from '@vue-hero-icons/solid'

export default {
  components: {
    TrashIcon,
    PencilIcon,
    PlusIcon,
    
  },

  computed: {
    ...mapGetters('document-type', ['document_types', 'getDocumentTypeById']),
  },

  methods: {
    ...mapActions('modal', ['openModal']),

    ...mapActions('documentType', [
      'fetchDocumentTypes',
      'fetchDocumentType',
      'deleteDocumentType',
    ]),

    async fetchData({ page, filter, sort }) {
      let data = {
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchDocumentTypes(data)
      this.isRequestOngoing = false

      return {
        data: response.data.document_types.data,
        pagination: {
          totalPages: response.data.document_types.last_page,
          currentPage: page,
          count: response.data.document_types.count,
        },
      }
    },

    async removeDocumentType(id, index) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('document_types.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let response = await this.deleteDocumentType(id)
          if (response.data.success) {
            window.toastr['success'](
              this.$tc('document_types.deleted_message')
            )
            this.id = null
            this.$refs.table.refresh()
            return true
          }
          window.toastr['error'](
            this.$t('document_types.already_in_use')
          )
        }
      })
    },

    addDocumentType() {
      this.openModal({
        title: this.$t('document_types.add_document_type'),
        componentName: 'DocumentTypeModal',
        refreshData: this.$refs.table.refresh,
      })
    },

    async editDocumentType(id) {
      let response = await this.fetchDocumentType(id)
      let document_type = response.data.document_type
      if (response.data.document_type.validity == -1)
        document_type.validity = null
      this.openModal({
        title: this.$t('document_types.edit_document_type'),
        componentName: 'DocumentTypeModal',
        id: id,
        data: document_type,
        refreshData: this.$refs.table.refresh,
      })
    },
  },
}
</script>
