var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "sw-input-group",
        {
          staticClass: "mb-4",
          attrs: {
            error: _vm.emailError,
            label: _vm.$t("login.enter_email"),
            required: "",
          },
        },
        [
          _c("sw-input", {
            attrs: {
              invalid: _vm.$v.email.$error,
              focus: "",
              type: "email",
              name: "email",
            },
            on: {
              input: function ($event) {
                return _vm.$v.email.$touch()
              },
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            type: "submit",
            variant: "primary",
          },
        },
        [
          !_vm.isSent
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("validation.send_reset_link")) +
                    "\n    "
                ),
              ])
            : _c("div", [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("validation.not_yet")) + "\n    "
                ),
              ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4 mb-4 text-sm" },
        [
          _c(
            "router-link",
            {
              staticClass: "text-sm text-primary-400 hover:text-gray-700",
              attrs: { to: "/login" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.back_to_login")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }