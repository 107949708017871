import * as types from './mutation-types'

export default {
  [types.SET_DOCUMENT_TYPES](state, document_types) {
    state.document_types = document_types
  },

  [types.ADD_DOCUMENT_TYPE](state, data) {
    state.document_types.push(data.document_type)
  },

  [types.UPDATE_DOCUMENT_TYPE](state, data) {
    let pos = state.document_types.findIndex(
      (document_type) => document_type.id === data.document_type.id
    )
    Object.assign(state.document_types[pos], { ...data.document_type })
  },

  [types.DELETE_DOCUMENT_TYPE](state, id) {
    let pos = state.document_types.findIndex((document_type) => document_type.id === id)
    state.document_types.splice(pos, 1)
  },
}
