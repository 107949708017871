var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        [
          _c(
            "sw-tabs",
            { staticClass: "p-2" },
            [
              _c(
                "sw-tab-item",
                { attrs: { title: _vm.$t("documents.title") } },
                [_c("documents-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-tab-item",
                { attrs: { title: _vm.$tc("recaps.title", 1) } },
                [_c("recaps-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }