export default {
   view_estimate : 'view-estimate',
   view_affaire : 'view-affaire',
   create_estimate : 'create-estimate',
   edit_estimate : 'edit-estimate',
   delete_estimate : 'delete-estimate',
   upload_file : 'upload-file',
   send_estimate : 'send-estimate',

   view_invoice : 'view-invoice',
   create_invoice : 'create-invoice',
   edit_invoice : 'edit-invoice',
   delete_invoice : 'delete-invoice',
   send_invoice : 'send-invoice',

   view_payment : 'view-payment',
   create_payment : 'create-payment',
   edit_payment : 'edit-payment',
   delete_payment : 'delete-payment',
   send_payment : 'send-payment',
   
   view_expense : 'view-expense',
   create_expense : 'create-expense',
   edit_expense : 'edit-expense',
   delete_expense : 'delete-expense',

   view_customer : 'view-customer',
   create_customer : 'create-customer',
   edit_customer : 'edit-customer',
   delete_customer : 'delete-customer',

   view_item : 'view-item',
   create_item : 'create-item',
   edit_item : 'edit-item',
   delete_item : 'delete-item',

   view_note : 'view-note',
   manage_note : 'manage-note',

   view_tax_type : 'view-tax-type',
   create_tax_type : 'create-tax-type',
   edit_tax_type : 'edit-tax-type',
   delete_tax_type : 'delete-tax-type',

   view_custom_field : 'view-custom-field',
   create_custom_field : 'create-custom-field',
   edit_custom_field : 'edit-custom-field',
   delete_custom_field : 'delete-custom-field',

   view_reports : 'view-reports',
   manage_notification : 'manage-notification',
   manage_company : 'manage-company',
   

  }
  