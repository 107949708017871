export const getContacts = (state) => state.contacts
export const getUsers = (state) => state.users
export const getInvoices = (state) => state.invoices

export const getExpenses = (state) => state.expenses
export const getRecentInvoices = (state) => state.recentInvoices
export const getNewContacts = (state) => state.newContacts
export const getNewUsers = (state) => state.newUsers
export const getTotalDueAmount = (state) => state.totalDueAmount
export const getDueInvoices = (state) => state.dueInvoices

export const getDocuments = (state) => state.documents
export const getRecaps = (state) => state.recaps
export const getTypeDocuments = (state) => state.type_documents
export const getDueDocuments = (state) => state.dueDocuments
export const getInvalidDocuments = (state) => state.invalidDocuments
export const getRecentRecaps = (state) => state.recentRecaps

export const getDashboardDataLoaded = (state) => state.isDashboardDataLoaded

export const getWeeklyInvoicesCounter = (state) => state.weeklyInvoices.counter
export const getWeeklyInvoicesDays = (state) => state.weeklyInvoices.days

export const getChartMonths = (state) => state.chartData.months
export const getChartInvoices = (state) => state.chartData.invoiceTotals
export const getChartExpenses = (state) => state.chartData.expenseTotals
export const getNetProfits = (state) => state.chartData.netProfits
export const getReceiptTotals = (state) => state.chartData.receiptTotals

export const getTotalSales = (state) => state.salesTotal
export const getTotalReceipts = (state) => state.totalReceipts
export const getTotalExpenses = (state) => state.totalExpenses
export const getNetProfit = (state) => state.netProfit
