<template>
  <sw-card variant="setting-card">
    <div slot="header" class="flex flex-wrap justify-between lg:flex-nowrap">
      <div>
        <h6 class="sw-section-title">
          Votre contrat avec Tunvita
        </h6>
        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
         <!-- Signer votre contart ou téléchargé le -->
        </p>
      </div>
      
    </div>
    <div
      v-if="!loadContract "
      class="flex flex-col w-full min-h-0 mt-8 overflow-hidden sw-scroll"
      style="height: 75vh"
    >
      <div id="iframe-container"></div>
      <iframe
        v-if="signatureLink"
        :src="`${signatureLink}`"
        class="flex-1 border border-gray-400 border-solid rounded-md frame-style"
      />
      <div v-else >
      pas de contrat en ce moment
    </div>
    </div>
    
    
  </sw-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TrashIcon, PencilIcon, PlusIcon, } from '@vue-hero-icons/solid'
import Yousign from './yousign-sdk';
export default {
  components: {
    TrashIcon,
    PencilIcon,
    PlusIcon,
    
  },
  data() {
      return {
        sign_url :'',
        loadContract : true ,        
        isSandbox: true,
      }
  },

  computed: {
    ...mapGetters('document-type', ['document_types', 'getDocumentTypeById']),
    signatureLink() {
      return `${this.sign_url}`
    },
  },

  mounted() {
    
  },

  created() {
    this.getContract()
    .then(() => {
        // const yousign = new Yousign({
        //     signatureLink: this.signatureLink,
        //     iframeContainerId: 'iframe-container',
        //     isSandbox: this.isSandbox,
        // });
        // yousign.onStarted((data) => {
        //  console.log("Signer has started signed");
        // });
    })
  },

  methods: {
    ...mapActions('modal', ['openModal']),

    ...mapActions('documentType', [
      'fetchDocumentTypes',
      'fetchDocumentType',
      'deleteDocumentType',
    ]),

    async getContract(){
      this.loadContract = true
        let res = await window.axios.get('/api/v1/get_sign_url')
        if(res.data.url)
          this.sign_url = res.data.url+'&disable_domain_validation=true'
        
        console.log(res)
      this.loadContract = false
        
    },

    async fetchData({ page, filter, sort }) {
      let data = {
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchDocumentTypes(data)
      this.isRequestOngoing = false

      return {
        data: response.data.document_types.data,
        pagination: {
          totalPages: response.data.document_types.last_page,
          currentPage: page,
          count: response.data.document_types.count,
        },
      }
    },

    async removeDocumentType(id, index) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('document_types.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let response = await this.deleteDocumentType(id)
          if (response.data.success) {
            window.toastr['success'](
              this.$tc('document_types.deleted_message')
            )
            this.id = null
            this.$refs.table.refresh()
            return true
          }
          window.toastr['error'](
            this.$t('document_types.already_in_use')
          )
        }
      })
    },

    addDocumentType() {
      this.openModal({
        title: this.$t('document_types.add_document_type'),
        componentName: 'DocumentTypeModal',
        refreshData: this.$refs.table.refresh,
      })
    },

    async editDocumentType(id) {
      let response = await this.fetchDocumentType(id)
      let document_type = response.data.document_type
      if (response.data.document_type.validity == -1)
        document_type.validity = null
      this.openModal({
        title: this.$t('document_types.edit_document_type'),
        componentName: 'DocumentTypeModal',
        id: id,
        data: document_type,
        refreshData: this.$refs.table.refresh,
      })
    },
  },
}
</script>
