var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-class": "duration-300 ease-out",
        "enter-active-class":
          "duration-700 translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95",
        "enter-to-class": "duration-700 translate-y-0 opacity-100 sm:scale-100",
        "leave-active-class": "duration-300 ease-in",
        "leave-class": "duration-300 translate-y-0 opacity-100 sm:scale-100",
        "leave-to-class":
          "duration-300 translate-y-4 opacity-0 sm:translate-y-0 sm:scale-90",
      },
    },
    [
      _vm.isShow
        ? _c("div", { class: _vm.modalStyle.overlayContainer }, [
            _c("div", { class: _vm.modalStyle.base }, [
              _c(
                "div",
                { class: _vm.modalStyle.header },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c("div", { class: _vm.modalStyle.body }, [_vm._t("default")], 2),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasFooter,
                      expression: "hasFooter",
                    },
                  ],
                  class: _vm.modalStyle.footer,
                },
                [_vm._t("footer")],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }