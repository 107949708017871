<template>
  <form id="loginForm" @submit.prevent="onSubmit">
    <sw-input-group
      :error="emailError"
      :label="$t('login.enter_email')"
      class="mb-4"
      required
    >
      <sw-input
        v-model="email"
        :invalid="$v.email.$error"
        focus
        type="email"
        name="email"
        @input="$v.email.$touch()"
      />
    </sw-input-group>
    <sw-button
      :loading="isLoading"
      :disabled="isLoading"
      type="submit"
      variant="primary"
    >
      <div v-if="!isSent">
        {{ $t('validation.send_reset_link') }}
      </div>
      <div v-else>
        {{ $t('validation.not_yet') }}
      </div>
    </sw-button>

    <div class="mt-4 mb-4 text-sm">
      <router-link
        to="/login"
        class="text-sm text-primary-400 hover:text-gray-700"
      >
        {{ $t('general.back_to_login') }}
      </router-link>
    </div>
  </form>
</template>

<script type="text/babel">
import { mapActions } from 'vuex'
const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  data() {
    return { 
    email: '',
    isLoading: false,
    isSent:false,
    }
  },
  validations: {
   
    email: {
        required,
        email,    
    },
  },

  computed: {
    emailError() {
      if (!this.$v.email.$error) {
        return ''
      }
      if (!this.$v.email.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.email.email) {
        return this.$tc('validation.email_incorrect')
      }
    },
  },
  methods: {
    ...mapActions('auth', ['forgotPassword']),

    async onSubmit(e) {
        this.$v.email.$touch()
        let data  = {
          email : this.email
        }
        console.log(data)
        if (!this.$v.email.$invalid) {
            try {
            this.isLoading = true
          
            await this.forgotPassword(data)
            
            window.toastr['success']('email envoyé')
            
            this.isSent = true
            this.isLoading = false
            } catch (error) {
                console.log(error)
                window.toastr['error'](error)
                this.isLoading = false
                
            }
        }
    },
  },
}
</script>



