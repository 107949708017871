<template>
  <div>
    <form action="" @submit.prevent="sendData">
      <div class="px-8 py-8 sm:p-6">
          <sw-input-group 
            :label="$t('estimates.file')"
            required
           
          >
            <div
              id="receipt-box"
              class="relative flex items-center justify-center h-24 p-6 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box"
            >
              <img
                v-if="previewReceipt"
                :src="previewReceipt"
                class="absolute opacity-100 preview-logo"
                style="max-height: 80%; animation: fadeIn 2s ease"
              />
              <div v-else class="flex flex-col items-center">
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-400"
                />
                <p class="text-xs leading-4 text-center text-gray-400">
                 Faites glisser un fichier ici ou 
                  <span id="pick-avatar" class="cursor-pointer text-primary-500" >parcourez</span>
                  pour choisir un fichier
                </p>
              </div>
            </div>
           
            <sw-avatar
              :preview-avatar="previewReceipt"
              :enable-cropper="false"
              trigger="#receipt-box"
              @changed="onChange"
              accept=".pdf"
           
              
            >
              <template v-slot:icon>
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-400"
                />
              </template>
            </sw-avatar>
           
          </sw-input-group>           
      </div>
      <div
        class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid"
      >
        <sw-button
          class="mr-3"
          variant="primary-outline"
          type="button"
          @click="closeUploadModal"
         
        >
          {{ $t('general.cancel') }}
        </sw-button>
        <sw-button
          :loading="isLoading"
          :disabled="isLoading"
          variant="primary"
          type="submit"
          v-if="previewReceipt"
        >
          <cloud-upload-icon class="h-5 mb-2 text-xl leading-6 text-gray-400" />
          {{ $t('general.upload') }}
        </sw-button>
      
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PaperAirplaneIcon } from '@vue-hero-icons/solid'
import { DownloadIcon } from '@vue-hero-icons/outline'
import { CloudUploadIcon, ShoppingCartIcon } from '@vue-hero-icons/solid'
const { required } = require('vuelidate/lib/validators')
const _ = require('lodash')

export default {
  components: {
    PaperAirplaneIcon,
    CloudUploadIcon,
    ShoppingCartIcon,
    DownloadIcon,
  
  },
  data() {
    return {
      previewReceipt: null,
      fileObject: null,
      isLoading: false,
      estimate : null,
      cropperOutputMime: '',

    }
  },
  validations: {
    previewReceipt : required,
  },
  computed: {
    ...mapGetters('modal', ['modalDataID', 'modalData', 'modalActive']),
    ...mapGetters('user', ['currentUser']),

    created(){
      this.setInitialData()
     
    },
 
  },

  mounted() {
    this.setInitialData()
    
    },

  methods: {
    ...mapActions('modal', ['closeModal']),

    ...mapActions('estimate', ['uploadFile']),

    ...mapActions('company', ['fetchCompanySettings', 'fetchMailConfig']),

     onUploadHandler(cropper) {
      this.previewReceipt = cropper
        .getCroppedCanvas()
        .toDataURL(this.cropperOutputMime)
    },

    onHandleUploadError() {
      window.toastr['error']('Oops! Something went wrong...')
    },

    setInitialData(){
      console.log(this.modalData)

      this.estimate = this.modalData
    },

    fileError(){
       if (!this.$v.previewReceipt.$error) {
        return ''
      }
      if (this.previewReceipt == null) {
        return this.$t('validation.required')
      }
    },

    onChange(data) {
      this.previewReceipt = data.image
      this.fileObject = data.file
    },

    resetFormData() {
      this.previewReceipt = null
      this.fileObject= null
      this.isLoading= false
    },
    async getReceipt() {
      let res = await this.getExpenseReceipt(this.$route.params.id)

      if (res.data.error) {
        this.isReceiptAvailable = false
        return true
      }

      this.isReceiptAvailable = true
      this.previewReceipt = res.data.image
    },


    async sendData() {
     
      if (this.$v.$invalid) {
        return true
      }

      let data = new FormData()

      if (this.fileObject) {
        data.append('attachment', this.fileObject)
        data.append('estimate_id', this.modalDataID)
        data.append('name', this.fileObject.name)
        data.append('type', this.fileObject.type)
      console.log(this.fileObject)
      console.log(this.fileObject.type)


      }
     
    
        this.isLoading = true
        console.log('upload commencé')

        let response = await this.uploadFile(data)

        console.log('upload terminée')
        this.isLoading = false

        if (response.data.success) {
          
          console.log('upload success')

          window.toastr['success'](this.$t('estimates.upload_successfully'))
         
          this.$router.push(`/admin/affaires/${this.estimate.id}/document/${response.data.document.id}`)
          
          this.closeUploadModal()
          this.$router.go()

          return true
        }
        else 
          {
          console.log('upload error')

            window.toastr['error'](response.data.error)
             this.resetFormData()
          }
      
    },

    closeUploadModal(){
      this.resetFormData()
      this.closeModal()
    }
   
  }
}
</script>
