var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-6" },
    [
      !_vm.getDashboardDataLoaded ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _vm.getInvalidDocuments.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full",
              attrs: { role: "alert" },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-8 h-8 lg:w-4 lg:h-4 mr-2 fill-current",
                  attrs: {
                    "aria-hidden": "true",
                    focusable: "false",
                    "data-prefix": "fas",
                    "data-icon": "times-circle",
                    role: "img",
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z",
                    },
                  }),
                ]
              ),
              _vm._v(
                "\n    Attention ! Vous avez des documents expirés. Supprimez les pour libérer l'espace sur le systéme.\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.getDueDocuments.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full",
              attrs: { role: "alert" },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-8 h-8 lg:w-4 lg:h-4 mr-2 fill-current",
                  attrs: {
                    "aria-hidden": "true",
                    focusable: "false",
                    "data-prefix": "fas",
                    "data-icon": "exclamation-triangle",
                    role: "img",
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 576 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z",
                    },
                  }),
                ]
              ),
              _vm._v(
                "\n    Vous avez des documents qui seront expirés aprés moin de " +
                  _vm._s(_vm.x_day) +
                  " jours. Pensez de les modifier ou supprimer.\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-6 mt-10 xl:grid-cols-2" },
        [
          _c(
            "div",
            { staticClass: "due-documents bg-white rounded-lg p-4" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative z-10 flex items-center justify-between",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "mb-0 text-xl font-semibold leading-normal",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dashboard.recent_documents_card.title")
                          ) +
                          " " +
                          _vm._s(
                            _vm.x_day ? "( - " + _vm.x_day + "jours)" : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/documents",
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dashboard.recent_documents_card.view_all")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "doc_table",
                  attrs: {
                    data: _vm.getDueDocuments,
                    "show-filter": false,
                    "table-class": "table",
                    variant: "gray",
                  },
                },
                [
                  _c(
                    "sw-table-column",
                    { attrs: { sortable: false } },
                    [[_c("span")]],
                    2
                  ),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.date2"),
                      show: "formattedDateDelivrance",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("documents.date"))),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mt-6" }, [
                              _vm._v(_vm._s(row.formattedDateDelivrance)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.type"),
                      show: "type_document.name",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("documents.type"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `documents/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.type_document.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.user.role != "user"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("documents.user"),
                          show: "partenaire.name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("documents.user"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.partenaire.name) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          768903628
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.status"),
                      "sort-as": "status",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("documents.status"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "sw-badge",
                              {
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$utils.getStatusTranslation(
                                        row.status
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class":
                        "action-dropdown dashboard-recent-document-options no-click",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "sw-dropdown",
                            {},
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  attrs: {
                                    "tag-name": "router-link",
                                    to: `documents/${row.id}/view`,
                                  },
                                },
                                [
                                  _c("eye-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.view")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDocument("due", row.id)
                                    },
                                  },
                                },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              row.status !== "ACCEPTED" &&
                              _vm.user.role != "user"
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onMarkAsAccepted(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("check-circle-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("documents.mark_as_accepted")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status !== "REJECTED" &&
                              _vm.user.role != "user"
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onMarkAsRejected(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("x-circle-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("documents.mark_as_rejected")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "due-documents bg-white rounded-lg p-4" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative z-10 flex items-center justify-between",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "mb-0 text-xl font-semibold leading-normal",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dashboard.invalid_documents_card.title")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/documents",
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dashboard.recent_documents_card.view_all")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "doc_invalid_table",
                  attrs: {
                    variant: "gray",
                    data: _vm.getInvalidDocuments,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c(
                    "sw-table-column",
                    { attrs: { sortable: false } },
                    [[_c("span")]],
                    2
                  ),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.date2"),
                      show: "formattedDateDelivrance",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("documents.date"))),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mt-6" }, [
                              _vm._v(_vm._s(row.formattedDateDelivrance)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.type"),
                      show: "type_document.name",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("documents.type"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `documents/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.type_document.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.user.role != "user"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("documents.user"),
                          show: "partenaire.name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("documents.user"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.partenaire.name) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          768903628
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.status"),
                      "sort-as": "status",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("documents.status"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "sw-badge",
                              {
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$utils.getStatusTranslation(
                                        row.status
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class":
                        "action-dropdown dashboard-recent-document-options no-click",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "sw-dropdown",
                            {},
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  attrs: {
                                    "tag-name": "router-link",
                                    to: `documents/${row.id}/view`,
                                  },
                                },
                                [
                                  _c("eye-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.view")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDocument(
                                        "invalid",
                                        row.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "recent-recaps bg-white rounded-lg p-4" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative z-10 flex items-center justify-between",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "mb-0 text-xl font-semibold leading-normal",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("dashboard.recent_recap_card.title")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/recaps",
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dashboard.recent_recap_card.view_all")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "recap_table",
                  attrs: {
                    data: _vm.getRecentRecaps,
                    "show-filter": false,
                    "table-class": "table",
                    variant: "gray",
                  },
                },
                [
                  _c(
                    "sw-table-column",
                    { attrs: { sortable: false } },
                    [[_c("span")]],
                    2
                  ),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      "sort-as": "recap_date",
                      label: _vm.$t("recaps.date"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [_vm._v(_vm._s(_vm.$t("recaps.date")))]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: {
                                    path: `recaps/${row.id}/view/${row.media[0].id}`,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.format(row.recap_date)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.user.role != "user"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          "sort-as": "partenaire_name",
                          label: _vm.$t("recaps.partenaire"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("recaps.partenaire"))),
                                  ]),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.partenaire_name) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1820635194
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("recaps.recap_admin"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("recaps.recap_admin"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-1 text-xs",
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.recap_admin ? "ACCEPTED" : "REJECTED"
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.recap_admin ? "ACCEPTED" : "REJECTED"
                                  ).color,
                                  "font-size": _vm.$utils.getBadgeStatusColor(
                                    row.recap_admin ? "ACCEPTED" : "REJECTED"
                                  ).fontSize,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.recap_admin
                                        ? _vm.user.role != "user"
                                          ? "Envoyé"
                                          : "Reçu"
                                        : "NON"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label:
                        _vm.user.role == "user"
                          ? _vm.$t("recaps.my_recap")
                          : _vm.$t("recaps.recap_partenaire"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("recaps.recap_user"))),
                            ]),
                            _vm._v(".\n            "),
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-1 text-xs",
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.recap_partenaire
                                      ? "ACCEPTED"
                                      : "REJECTED"
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.recap_partenaire
                                      ? "ACCEPTED"
                                      : "REJECTED"
                                  ).color,
                                  "font-size": _vm.$utils.getBadgeStatusColor(
                                    row.recap_partenaire
                                      ? "ACCEPTED"
                                      : "REJECTED"
                                  ).fontSize,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.recap_partenaire
                                        ? _vm.user.role != "user"
                                          ? "Reçu"
                                          : "Envoyé"
                                        : "NON"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown no-click",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "sw-dropdown",
                            {},
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              _vm.user.role == "user" && !row.recap_partenaire
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRecap(row.recap_date)
                                        },
                                      },
                                    },
                                    [
                                      _c("plus-circle-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.add")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  attrs: {
                                    "tag-name": "router-link",
                                    to: {
                                      path: `recaps/${row.id}/view/${row.media[0].id}`,
                                    },
                                  },
                                },
                                [
                                  _c("eye-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.view")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }