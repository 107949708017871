export const BOOTSTRAP_COMPANIES = 'BOOTSTRAP_COMPANIES'

export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'

export const SET_MOMENT_DATE_FORMAT = 'SET_MOMENT_DATE_FORMAT'
export const SET_COLOR = 'SET_COLOR'

export const SET_CARBON_DATE_FORMAT = 'SET_CARBON_DATE_FORMAT'

export const SET_DEFAULT_FISCAL_YEAR = 'SET_DEFAULT_FISCAL_YEAR'

export const SET_DEFAULT_TIME_ZONE = 'SET_DEFAULT_TIME_ZONE'

export const SET_ITEM_DISCOUNT = 'SET_ITEM_DISCOUNT'

export const SET_DEFAULT_CURRENCY = 'SET_DEFAULT_CURRENCY'
export const UPDATE_PACK = 'UPDATE_PACK'
export const DELETE_COMPANY = 'DELETE_COMPANY'
