var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateDocumentSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mt-3",
              attrs: {
                label: _vm.$t("documents.nb_days"),
                error: _vm.dayError,
              },
            },
            [
              _c("sw-input", {
                staticClass:
                  "relative mt-1 w-full focus:border focus:border-solid focus:border-primary-500",
                staticStyle: { "max-width": "80px" },
                attrs: {
                  invalid: _vm.$v.documents.notification_x_day.$error,
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.documents.notification_x_day.$touch()
                  },
                },
                model: {
                  value: _vm.documents.notification_x_day,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.documents,
                      "notification_x_day",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "documents.notification_x_day",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("documents.default_document_email_subject"),
                error: _vm.mailSubjectError,
              },
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.documents.document_mail_subject.$error,
                },
                model: {
                  value: _vm.documents.document_mail_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.documents, "document_mail_subject", $$v)
                  },
                  expression: "documents.document_mail_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("documents.default_document_email_body"),
                error: _vm.mailBodyError,
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.documents.document_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.documents, "document_mail_body", $$v)
                  },
                  expression: "documents.document_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }