var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [_c("dashboard-stats"), _vm._v(" "), _c("dashboard-table")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }