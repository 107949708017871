<template>
  <div v-if="isAppLoaded" class="h-full">
    <base-modal />
    <site-header />
    <div class="flex h-screen pt-16 pb-10 overflow-hidden">
      <site-sidebar />
      <router-view />
    </div>
    <site-footer />
  </div>
  <div v-else class="h-full">
    <refresh-icon class="h-6 animate-spin" />
  </div>
</template>

<script type="text/babel">
import SiteHeader from './partials/TheSiteHeader.vue'
import SiteFooter from './partials/TheSiteFooter.vue'
import SiteSidebar from './partials/TheSiteSidebar.vue'
import BaseModal from '../../components/base/modal/BaseModal'
import { RefreshIcon } from '@vue-hero-icons/solid'
import { mapActions, mapGetters } from 'vuex'
import Ls from '../../services/ls'

export default {
  components: {
    SiteHeader,
    SiteSidebar,
    SiteFooter,
    BaseModal,
    RefreshIcon,
  },
  data() {
    return {
      color: '',
    }
  },

  computed: {
    ...mapGetters(['isAppLoaded']),

    ...mapGetters('company', {
      selectedCompany: 'getSelectedCompany',
      currentColor : 'getColor',
    }),

    isShow() {
      return true
    },
  },

  created() {
    this.bootstrap().then(() => {
      console.log('bootstrap done')
      this.setInitialCompany()
      this.fetchSettings()
      // this.loadColorPack()
      
    })
  },

  methods: {
    ...mapActions(['bootstrap']),

    ...mapActions('company', ['setSelectedCompany','fetchCompanySettings']),

    setInitialCompany() {
      this.setSelectedCompany(this.selectedCompany)
    },

    fetchSettings() {
      // let res = await this.fetchCompanySettings([
      //   'color',
      // ])
      // if(res.data)
      //   this.color = res.data.color

      let root = document.querySelector(':root')
     
      let docStyle = getComputedStyle(document.documentElement)
      //get variable
      let myVarVal = docStyle.getPropertyValue('--color500')
      console.log(this.currentColor)
      switch (this.currentColor) {
        case '#6b7280':
          root.style.setProperty('--color50', '#f9fafb')
          root.style.setProperty('--color100', '#f3f4f6')
          root.style.setProperty('--color200', '#e5e7eb')
          root.style.setProperty('--color300', '#d1d5db')
          root.style.setProperty('--color400', '#9ca3af')
          root.style.setProperty('--color500', '#6b7280')
          root.style.setProperty('--color600', '#4b5563')
          root.style.setProperty('--color700', '#374151')
          root.style.setProperty('--color800', '#1f2937')
          root.style.setProperty('--color900', '#111827')

          break;
        case '#ef4444' :
          root.style.setProperty('--color50', '#fef2f2')
          root.style.setProperty('--color100', '#fee2e2')
          root.style.setProperty('--color200', '#fecaca')
          root.style.setProperty('--color300', '#fca5a5')
          root.style.setProperty('--color400', '#f87171')
          root.style.setProperty('--color500', '#ef4444')
          root.style.setProperty('--color600', '#dc2626')
          root.style.setProperty('--color700', '#b91c1c')
          root.style.setProperty('--color800', '#991b1b')
          root.style.setProperty('--color900', '#7f1d1d')
          break;

        case '#f97316' :
          root.style.setProperty('--color50', '#fff7ed')
          root.style.setProperty('--color100', '#ffedd5')
          root.style.setProperty('--color200', '#fed7aa')
          root.style.setProperty('--color300', '#fdba74')
          root.style.setProperty('--color400', '#fb923c')
          root.style.setProperty('--color500', '#f97316')
          root.style.setProperty('--color600', '#ea580c')
          root.style.setProperty('--color700', '#c2410c')
          root.style.setProperty('--color800', '#9a3412')
          root.style.setProperty('--color900', '#7c2d12')
          break;

        case '#eab308' :
          root.style.setProperty('--color50', '#fefce8')
          root.style.setProperty('--color100', '#fef9c3')
          root.style.setProperty('--color200', '#fef08a')
          root.style.setProperty('--color300', '#fde047')
          root.style.setProperty('--color400', '#facc15')
          root.style.setProperty('--color500', '#eab308')
          root.style.setProperty('--color600', '#ca8a04')
          root.style.setProperty('--color700', '#a16207')
          root.style.setProperty('--color800', '#854d0e')
          root.style.setProperty('--color900', '#713f12')
          break;

        case '#84cc16' :
          root.style.setProperty('--color50', '#f7fee7')
          root.style.setProperty('--color100', '#ecfccb')
          root.style.setProperty('--color200', '#d9f99d')
          root.style.setProperty('--color300', '#bef264')
          root.style.setProperty('--color400', '#a3e635')
          root.style.setProperty('--color500', '#84cc16')
          root.style.setProperty('--color600', '#65a30d')
          root.style.setProperty('--color700', '#4d7c0f')
          root.style.setProperty('--color800', '#3f6212')
          root.style.setProperty('--color900', '#365314')
          break;

        case '#22c55e' :
          root.style.setProperty('--color50', '#f0fdf4')
          root.style.setProperty('--color100', '#dcfce7')
          root.style.setProperty('--color200', '#bbf7d0')
          root.style.setProperty('--color300', '#86efac')
          root.style.setProperty('--color400', '#4ade80')
          root.style.setProperty('--color500', '#22c55e')
          root.style.setProperty('--color600', '#16a34a')
          root.style.setProperty('--color700', '#15803d')
          root.style.setProperty('--color800', '#166534')
          root.style.setProperty('--color900', '#14532d')
          break;

        case '#06b6d4' :
          root.style.setProperty('--color50', '#ecfeff')
          root.style.setProperty('--color100', '#cffafe')
          root.style.setProperty('--color200', '#a5f3fc')
          root.style.setProperty('--color300', '#67e8f9')
          root.style.setProperty('--color400', '#22d3ee')
          root.style.setProperty('--color500', '#06b6d4')
          root.style.setProperty('--color600', '#0891b2')
          root.style.setProperty('--color700', '#0e7490')
          root.style.setProperty('--color800', '#155e75')
          root.style.setProperty('--color900', '#164e63')
          break;

        case '#3b82f6' :
          root.style.setProperty('--color50', '#eff6ff')
          root.style.setProperty('--color100', '#dbeafe')
          root.style.setProperty('--color200', '#bfdbfe')
          root.style.setProperty('--color300', '#93c5fd')
          root.style.setProperty('--color400', '#60a5fa')
          root.style.setProperty('--color500', '#3b82f6')
          root.style.setProperty('--color600', '#2563eb')
          root.style.setProperty('--color700', '#1d4ed8')
          root.style.setProperty('--color800', '#1e40af')
          root.style.setProperty('--color900', '#1e3a8a')
          break;

        case '#14b8a6' :
          root.style.setProperty('--color50', '#f0fdfa')
          root.style.setProperty('--color100', '#ccfbf1')
          root.style.setProperty('--color200', '#99f6e4')
          root.style.setProperty('--color300', '#5eead4')
          root.style.setProperty('--color400', '#2dd4bf')
          root.style.setProperty('--color500', '#14b8a6')
          root.style.setProperty('--color600', '#0d9488')
          root.style.setProperty('--color700', '#0f766e')
          root.style.setProperty('--color800', '#115e59')
          root.style.setProperty('--color900', '#134e4a')
          break;

        case '#d946ef' :
          root.style.setProperty('--color50', '#fdf4ff')
          root.style.setProperty('--color100', '#fae8ff')
          root.style.setProperty('--color200', '#f5d0fe')
          root.style.setProperty('--color300', '#f0abfc')
          root.style.setProperty('--color400', '#e879f9')
          root.style.setProperty('--color500', '#d946ef')
          root.style.setProperty('--color600', '#c026d3')
          root.style.setProperty('--color700', '#a21caf')
          root.style.setProperty('--color800', '#86198f')
          root.style.setProperty('--color900', '#701a75')
          break;

        case '#ec4899' :
          root.style.setProperty('--color50', '#fdf2f8')
          root.style.setProperty('--color100', '#fce7f3')
          root.style.setProperty('--color200', '#fbcfe8')
          root.style.setProperty('--color300', '#f9a8d4')
          root.style.setProperty('--color400', '#f472b6')
          root.style.setProperty('--color500', '#ec4899')
          root.style.setProperty('--color600', '#db2777')
          root.style.setProperty('--color700', '#be185d')
          root.style.setProperty('--color800', '#9d174d')
          root.style.setProperty('--color900', '#831843')
          break;
      
        default:
          break;
      }
    },

    loadColorPack() {
      let root = document.querySelector(':root')
     
      let docStyle = getComputedStyle(document.documentElement)
      //get variable
      let myVarVal = docStyle.getPropertyValue('--color500')
      
      switch (this.color) {
        case 'Gold':
          root.style.setProperty('--color50', '#FEFCE8')
          root.style.setProperty('--color100', '#FEF9C3')
          root.style.setProperty('--color200', '#FEF08A')
          root.style.setProperty('--color300', '#FDE047')
          root.style.setProperty('--color400', '#FACC15')
          root.style.setProperty('--color500', '#EAB308')
          root.style.setProperty('--color600', '#CA8A04')
          root.style.setProperty('--color700', '#A16207')
          root.style.setProperty('--color800', '#854D0E')
          root.style.setProperty('--color900', '#713F12')

          break;
        case 'Silver' :
          root.style.setProperty('--color50', '#FAFAF9')
          root.style.setProperty('--color100', '#F5F5F4')
          root.style.setProperty('--color200', '#E7E5E4')
          root.style.setProperty('--color300', '#D6D3D1')
          root.style.setProperty('--color400', '#A8A29E')
          root.style.setProperty('--color500', '#78716C')
          root.style.setProperty('--color600', '#57534E')
          root.style.setProperty('--color700', '#44403C')
          root.style.setProperty('--color800', '#292524')
          root.style.setProperty('--color900', '#1C1917')
          break
      
        default:
          break;
      }
    }
  },
}
</script>
<style  >
:root {
  --color50: #F7F6FD;
  --color100: #EEEEFB;
  --color200 :  #D5D4F5;
  --color300: #BCB9EF;
  --color400 : #8A85E4;
  --color500 : #5851D8;
  --color600: #4F49C2;
  --color700: #353182;
  --color800 :#282461;
  --color900: #1A1841;
}
.text-primary-500{
  color : var(--color500)
}

.text-primary-400{
  color : var(--color400)
}

.text-primary-200{
  color : var(--color200)
}

.bg-primary-500{
  background-color : var(--color500)
}

.border-primary-500,
.text-area-field:focus,
.v-money:focus {
  border-color: var(--color500)
}

.text-primary-800  {
  color: rgb(55 65 81);
}

.hover\:bg-primary-200:hover {
  background-color: var(--color200)
}

.hover\:bg-primary-400:hover {
  background-color: var(--color400)
}

.bg-primary-200 {
  background-color: var(--color200)
}

.bg-primary-100 {
  background-color: var(--color100)
}

.text-primary-600 {
  color: var(--color600);
}

.switch-circle {
  background : var(--color500) 
}

.from-primary-500 {
  --tw-gradient-from: var(--color500);
}
.to-primary-400 {
  --tw-gradient-to: var(--color400)
}
.to-primary-200 {
  --tw-gradient-to: var(--color200)
}


</style>