var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "items" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("users.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: { to: "#", title: "Contrat", active: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-card", { attrs: { variant: "" } }, [
        _c(
          "div",
          {
            staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c("div", [
              _c("h6", { staticClass: "sw-section-title" }, [
                _vm._v(
                  "\r\n            Contrat du " +
                    _vm._s(_vm.partenaire.name) +
                    " " +
                    _vm._s(_vm.partenaire.first_name) +
                    "\r\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.loadContract
          ? _c(
              "div",
              {
                staticClass:
                  "flex flex-col w-full min-h-0 mt-8 overflow-hidden sw-scroll",
                staticStyle: { height: "75vh" },
              },
              [
                _c("div", { attrs: { id: "iframe-container" } }),
                _vm._v(" "),
                _vm.signatureLink
                  ? _c("iframe", {
                      staticClass:
                        "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                      attrs: { src: `${_vm.signatureLink}` },
                    })
                  : _c("div", [
                      _vm._v(
                        "\r\n        pas de contrat en ce moment\r\n        "
                      ),
                    ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }