var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitData.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "px-8 py-8 lg:grid grid-cols-3 bg-white rounded sm:p-6",
        },
        [
          _c("div", { staticClass: "col-span-3 text-lg font-semibold" }, [
            _vm._v(
              "Doc " +
                _vm._s(_vm.index + 1) +
                " : " +
                _vm._s(_vm.documentType.name)
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("estimates.file"),
                required: "",
                error: _vm.attachmentError,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-center h-24 p-6 bg-transparent border-2 border-gray-300 border-dashed rounded-md image-upload-box",
                  attrs: { id: "receipt-box" + _vm.index },
                },
                [
                  _vm.newDocument.attachment
                    ? _c("img", {
                        staticClass: "absolute opacity-100 preview-logo",
                        staticStyle: {
                          "max-height": "80%",
                          animation: "fadeIn 2s ease",
                        },
                        attrs: { src: _vm.newDocument.attachment },
                      })
                    : _c(
                        "div",
                        { staticClass: "flex flex-col items-center" },
                        [
                          _c("cloud-upload-icon", {
                            staticClass:
                              "h-5 mb-2 text-xl leading-6 text-gray-400",
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-xs leading-4 text-center text-gray-400",
                            },
                            [
                              _vm._v(
                                "\r\n                    Faites glisser un fichier ici ou \r\n                    "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "cursor-pointer text-primary-500",
                                  attrs: { id: "pick-avatar" },
                                },
                                [_vm._v("parcourez")]
                              ),
                              _vm._v(
                                "\r\n                    pour choisir un fichier\r\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c("sw-avatar", {
                attrs: {
                  "preview-avatar": _vm.newDocument.attachment,
                  "enable-cropper": false,
                  trigger: "#receipt-box" + _vm.index,
                  accept: ".pdf",
                },
                on: { changed: _vm.onchange },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("cloud-upload-icon", {
                          staticClass:
                            "h-5 mb-2 text-xl leading-6 text-gray-400",
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2 lg:ml-2",
              attrs: {
                label: _vm.$t("documents.date"),
                required: "",
                error: _vm.dateError,
              },
            },
            [
              _c("base-date-picker", {
                staticClass: "mt-2",
                attrs: {
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                  invalid: _vm.$v.newDocument.date_delivrance.$error,
                },
                model: {
                  value: _vm.newDocument.date_delivrance,
                  callback: function ($$v) {
                    _vm.$set(_vm.newDocument, "date_delivrance", $$v)
                  },
                  expression: "newDocument.date_delivrance",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit || _vm.newDocument.id
            ? _c(
                "div",
                { staticClass: "flex justify-centerlg:m-auto mt-2" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: `documents/${_vm.newDocument.id}/view` } },
                    [_c("eye-icon", { staticClass: "h-5 mr-3 text-gray-600" })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: `documents/${_vm.newDocument.id}/edit` } },
                    [
                      _c("pencil-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "#" } },
                    [
                      _c("trash-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "mt-2 lg:m-auto" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        type: "submit",
                        size: "lg",
                        variant: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                    },
                    [
                      _c("cloud-upload-icon", {
                        staticClass: "w-6 h-6 mr-1 -ml-2",
                      }),
                      _vm._v(
                        "\r\n                        " +
                          _vm._s(_vm.$t("documents.upload")) +
                          "\r\n                    "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }