<template>
  <div class="flex justify-center">
      <img :src="modalData">
  </div>
</template>

<script>
import {mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters('modal', [
        'modalDataID',
        'modalData',
        'modalActive',
        'refreshData',
        ]),
    }
}
</script>
