var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "div",
      { staticClass: "pb-6" },
      [
        _c(
          "sw-page-header",
          { attrs: { title: _vm.$tc("settings.setting", 1) } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("settings.setting", 2),
                    to: "/admin/settings/user-profile",
                    active: "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full mb-6 select-wrapper xl:hidden" },
      [
        _c("sw-select", {
          attrs: {
            options: _vm.menuItems[0],
            searchable: true,
            "show-labels": false,
            "allow-empty": false,
            "custom-label": _vm.getCustomLabel,
          },
          on: { input: _vm.navigateToSetting },
          model: {
            value: _vm.currentSetting,
            callback: function ($$v) {
              _vm.currentSetting = $$v
            },
            expression: "currentSetting",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "grid md:grid-cols-12" }, [
      _c(
        "div",
        { staticClass: "hidden col-span-3 mt-1 xl:block" },
        [
          _c(
            "sw-list",
            _vm._l(_vm.menuItems[0], function (menuItem, index) {
              return _c(
                "sw-list-item",
                {
                  key: index,
                  staticClass: "py-3",
                  attrs: {
                    title: _vm.$t(menuItem.title),
                    to: menuItem.link,
                    active: _vm.hasActiveUrl(menuItem.link),
                    "tag-name": "router-link",
                  },
                },
                [
                  _c(menuItem.icon, {
                    tag: "component",
                    staticClass: "h-5",
                    attrs: { slot: "icon" },
                    slot: "icon",
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-span-12 xl:col-span-9" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }