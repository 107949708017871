var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recap
    ? _c(
        "base-page",
        { staticClass: "xl:pl-96" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.pageTitle } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _vm.currentUser.role != "user"
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "text-sm",
                          attrs: { variant: "primary" },
                          on: { click: _vm.addRecap },
                        },
                        [
                          _c("plus-icon", { staticClass: "h-5 mr-3" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.add")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mr-3 ml-3 text-sm" },
                    [
                      _vm.canEdit
                        ? _c(
                            "sw-button",
                            {
                              attrs: { variant: "primary-outline" },
                              on: {
                                click: function ($event) {
                                  return _vm.editRecap(_vm.$route.params.id)
                                },
                              },
                            },
                            [
                              _c("pencil-icon", { staticClass: "h-5 mr-3" }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.edit")) +
                                  "\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canEdit || _vm.currentUser.role != "user"
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "text-sm",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.removeRecap(
                                _vm.$route.params.id,
                                _vm.$route.params.media
                              )
                            },
                          },
                        },
                        [
                          _c("trash-icon", { staticClass: "h-5 mr-3" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isReceiptAvailable
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            href:
                              "/recaps/" +
                              _vm.$route.params.id +
                              "/file/" +
                              _vm.$route.params.media,
                            "tag-name": "a",
                            variant: "primary-outline",
                          },
                        },
                        [
                          _c("download-icon", { staticClass: "h-5 mr-3" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("recaps.download")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block",
            },
            [
              _vm.currentUser.role != "user"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center justify-between px-4 pt-8 pb-2 border border-b-0 border-gray-200 border-solid height-full",
                      },
                      [
                        _c(
                          "sw-input",
                          {
                            attrs: {
                              placeholder: _vm.$t("general.search"),
                              type: "text",
                              variant: "gray",
                            },
                            on: { input: _vm.onSearch },
                            model: {
                              value: _vm.searchData.searchText,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "searchText", $$v)
                              },
                              expression: "searchData.searchText",
                            },
                          },
                          [
                            _c("search-icon", {
                              staticClass: "h-5",
                              attrs: { slot: "rightIcon" },
                              slot: "rightIcon",
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex ml-3",
                            attrs: {
                              role: "group",
                              "aria-label": "First group",
                            },
                          },
                          [
                            _c(
                              "sw-dropdown",
                              {
                                attrs: {
                                  "close-on-select": false,
                                  align: "left",
                                  position: "bottom-start",
                                },
                              },
                              [
                                _c(
                                  "sw-button",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      size: "md",
                                      variant: "gray-light",
                                    },
                                    slot: "activator",
                                  },
                                  [_c("filter-icon", { staticClass: "h-5" })],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 py-1 mb-2 border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.sort_by")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    staticClass:
                                      "flex px-1 py-1 cursor-pointer",
                                  },
                                  [
                                    _c(
                                      "sw-input-group",
                                      {
                                        staticClass:
                                          "-mt-2 text-sm font-normal",
                                      },
                                      [
                                        _c("sw-radio", {
                                          attrs: {
                                            id: "filter_recap_date",
                                            label: _vm.$t("recaps.date"),
                                            name: "filter",
                                            size: "sm",
                                            value: "recap_date",
                                          },
                                          on: { change: _vm.onSearch },
                                          model: {
                                            value: _vm.searchData.orderByField,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchData,
                                                "orderByField",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchData.orderByField",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.currentUser.role != "user"
                                  ? _c(
                                      "sw-dropdown-item",
                                      {
                                        staticClass:
                                          "flex px-1 py-1 cursor-pointer",
                                      },
                                      [
                                        _c(
                                          "sw-input-group",
                                          {
                                            staticClass:
                                              "-mt-2 text-sm font-normal",
                                          },
                                          [
                                            _c("sw-radio", {
                                              attrs: {
                                                id: "filter_recap_name",
                                                label:
                                                  _vm.$t("recaps.partenaire"),
                                                name: "filter",
                                                size: "sm",
                                                value: "partenaire_name",
                                              },
                                              on: { change: _vm.onSearch },
                                              model: {
                                                value:
                                                  _vm.searchData.orderByField,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.searchData,
                                                    "orderByField",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "searchData.orderByField",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top-center",
                                    value: { content: _vm.getOrderName },
                                    expression: "{ content: getOrderName }",
                                    modifiers: { "top-center": true },
                                  },
                                ],
                                staticClass: "ml-1",
                                attrs: { size: "md", variant: "gray-light" },
                                on: { click: _vm.sortData },
                              },
                              [
                                _vm.getOrderBy
                                  ? _c("sort-ascending-icon", {
                                      staticClass: "h-5",
                                    })
                                  : _c("sort-descending-icon", {
                                      staticClass: "h-5",
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center justify-between px-4 pt8 pb-2 border border-t-0 border-gray-200 border-solid height-full",
                      },
                      [
                        _c("VueDatePicker", {
                          attrs: {
                            type: "month",
                            placeholder: "Filtrer par mois",
                            "fullscreen-mobile": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filtersRecapDate,
                            callback: function ($$v) {
                              _vm.filtersRecapDate = $$v
                            },
                            expression: "filtersRecapDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c(
                    "div",
                    {
                      staticClass:
                        "flex text-lg font-semibold text-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full",
                    },
                    [
                      _vm._v(
                        "\n        Récap et facture du mois " +
                          _vm._s(_vm.format(_vm.recap.recap_date)) +
                          "\n      "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _vm.isSearching
                ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                : _c(
                    "div",
                    {
                      staticClass:
                        "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll",
                    },
                    [
                      _vm.currentUser.role != "user"
                        ? _c(
                            "div",
                            _vm._l(_vm.recaps, function (recap, i) {
                              return _c(
                                "div",
                                { key: i + "recap" },
                                _vm._l(recap.media, function (media, index) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: index + "media",
                                      class: [
                                        "flex justify-between p-4  overflow-auto items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                                        {
                                          "bg-gray-100  border-l-4 border-primary-500 border-solid":
                                            _vm.hasActiveUrl(media.id),
                                        },
                                      ],
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid rgba(185, 193, 209, 0.41)",
                                      },
                                      attrs: {
                                        to: `/admin/recaps/${recap.id}/view/${media.id}`,
                                        id: "recap-" + media.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pr-2 mb-2 w-48 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(media.name) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-badge",
                                            {
                                              staticClass: "px-1 text-xs",
                                              attrs: {
                                                "bg-color":
                                                  _vm.$utils.getBadgeStatusColor(
                                                    media.custom_properties
                                                      .user_role == "user"
                                                      ? "DRAFT"
                                                      : "ACCEPTED"
                                                  ).bgColor,
                                                color:
                                                  _vm.$utils.getBadgeStatusColor(
                                                    media.custom_properties
                                                      .user_role == "user"
                                                      ? "DRAFT"
                                                      : "ACCEPTED"
                                                  ).color,
                                                "font-size":
                                                  _vm.$utils.getBadgeStatusColor(
                                                    media.custom_properties
                                                      .user_role == "user"
                                                      ? "DRAFT"
                                                      : "ACCEPTED"
                                                  ).fontSize,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    media.custom_properties
                                                      .user_role == "user"
                                                      ? _vm.$t(
                                                          "recaps.recap_user"
                                                        )
                                                      : _vm.$t(
                                                          "recaps.recap_admin"
                                                        )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-1 whitespace-nowrap right",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                recap.partenaire_name
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.format(recap.recap_date)
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              )
                            }),
                            0
                          )
                        : _vm._l(_vm.recap.media, function (media, index) {
                            return _c(
                              "router-link",
                              {
                                key: index + "-media-" + media.id,
                                class: [
                                  "flex justify-between overflow-auto p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                                  {
                                    "bg-gray-100 border-l-4 border-primary-500 border-solid":
                                      _vm.hasActiveUrl(media.id),
                                  },
                                ],
                                staticStyle: {
                                  "border-bottom":
                                    "1px solid rgba(185, 193, 209, 0.41)",
                                },
                                attrs: {
                                  to: `/admin/recaps/${_vm.recap.id}/view/${media.id}`,
                                  id: "recap-" + media.id,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pr-2 mb-2 w-48 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(media.name) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-1 text-xs",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              media.custom_properties
                                                .user_role == "user"
                                                ? "DRAFT"
                                                : "ACCEPTED"
                                            ).bgColor,
                                          color: _vm.$utils.getBadgeStatusColor(
                                            media.custom_properties.user_role ==
                                              "user"
                                              ? "DRAFT"
                                              : "ACCEPTED"
                                          ).color,
                                          "font-size":
                                            _vm.$utils.getBadgeStatusColor(
                                              media.custom_properties
                                                .user_role == "user"
                                                ? "DRAFT"
                                                : "ACCEPTED"
                                            ).fontSize,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              media.custom_properties
                                                .user_role == "user"
                                                ? _vm.$t("recaps.my_recap")
                                                : _vm.$t("recaps.recap_admin")
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-1 whitespace-nowrap right",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                                      domProps: { innerHTML: _vm._s("") },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.format(_vm.recap.recap_date)
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                      _vm._v(" "),
                      !_vm.recaps.length
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "flex justify-center px-4 mt-5 text-sm text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("recaps.no_matching_recaps")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex flex-col min-h-0 mt-8 overflow-hidden",
              staticStyle: { height: "75vh" },
            },
            [
              _c("iframe", {
                staticClass:
                  "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                attrs: { src: `${_vm.shareableLink}` },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }