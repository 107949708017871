var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-select" }, [
    _c("div", { staticClass: "flex flex-col w-full pb-4" }, [
      _c(
        "div",
        { staticClass: "flex px-4 pt-4 pb-2" },
        [
          _c("sw-input", {
            attrs: {
              placeholder: _vm.$t("general.search"),
              focus: "",
              type: "text",
              icon: "search",
            },
            on: { input: _vm.searchCustomer },
            scopedSlots: _vm._u([
              {
                key: "leftIcon",
                fn: function () {
                  return [
                    _c("search-icon", { staticClass: "h-5 m-2 text-gray-500" }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.customers.length > 0 && !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "relative flex flex-col overflow-auto sw-scroll list",
            },
            _vm._l(_vm.customers, function (customer, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex px-6 py-2 border-b border-gray-200 border-solid cursor-pointer hover:cursor-pointer hover:bg-gray-100 last:border-b-0",
                  on: {
                    click: function ($event) {
                      return _vm.selectNewCustomer(customer.id)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "flex items-center content-center justify-center w-10 h-10 mr-4 text-xl font-semibold leading-9 text-white bg-gray-400 rounded-full avatar",
                    },
                    [_vm._v(_vm._s(_vm.initGenerator(customer.name)))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex flex-col justify-center" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "m-0 leading-tight cursor-pointer font-base",
                      },
                      [_vm._v(_vm._s(customer.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "m-0 text-sm font-medium text-gray-500 cursor-pointer font-base",
                      },
                      [_vm._v(_vm._s(customer.contact_name))]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "flex items-center justify-center list" },
            [_c("refresh-icon", { staticClass: "animate-spin" })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customers.length === 0
        ? _c("div", { staticClass: "flex justify-center p-5 text-gray-400" }, [
            _c("label", { staticClass: "cursor-pointer" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("customers.no_customers_found")) +
                  "\n      "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.$utils.hasPermission(_vm.$permissionsStub.create_customer)
      ? _c(
          "button",
          {
            staticClass:
              "flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none",
            attrs: { type: "button" },
            on: { click: _vm.openCustomerModal },
          },
          [
            _c("user-add-icon", { staticClass: "text-primary-400" }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "m-0 ml-3 text-sm leading-none cursor-pointer font-base text-primary-400",
              },
              [_vm._v(_vm._s(_vm.$t("customers.add_new_customer")))]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }