import * as types from './mutation-types'

export default {
  [types.SET_RECAPS](state, data) {
    state.recaps = data
  },


  [types.SET_TOTAL_RECAPS](state, totalRecaps) {
    state.totalRecaps = totalRecaps
  },

  [types.ADD_RECAP](state, data) {
    state.recaps = [...state.recaps, data]
  },

  [types.DELETE_RECAP](state, id) {
    let index = state.recaps.findIndex((recap) => recap.id === id)
    state.recaps.splice(index, 1)
  },

  [types.SET_SELECTED_RECAPS](state, data) {
    state.selectedEstimates = data
  },

  [types.DELETE_MULTIPLE_RECAPS](state, selectedEstimates) {
    selectedEstimates.forEach((recap) => {
      let index = state.recaps.findIndex((_est) => _est.id === recap.id)
      state.recaps.splice(index, 1)
    })

    state.selectedEstimates = []
  },

  [types.UPDATE_RECAP](state, data) {
    let pos = state.recaps.findIndex(
      (recap) => recap.id === data.recap.id
    )

    state.recaps[pos] = data.recap
  },

  [types.UPDATE_RECAP_STATUS](state, data) {
    let pos = state.recaps.findIndex((recap) => recap.id === data.id)
    if (state.recaps[pos]) {
      // state.recaps[pos] = { ...state.recaps[pos], status: data.status }
      state.recaps[pos].status = data.status
    }
  },

  [types.RESET_SELECTED_RECAPS](state, data) {
    state.selectedEstimates = []
    state.selectAllField = false
  },


  [types.SET_SELECT_ALL_STATE](state, data) {
    state.selectAllField = data
  },

  [types.VIEW_RECAP](state, recap) {
    state.selectedEstimate = recap
  },

}
