<template>
  <div>
    <div slot="header" class="flex flex-wrap justify-between lg:flex-nowrap">
      <div>
        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
          {{ $t('recaps.notification_description') }}
        </p>
      </div>
    </div>
    <form action="" class="mt-6" @submit.prevent="updateRecapSetting">
      <sw-input-group
        :label="
          $t('recaps.default_recap_email_subject')
        "
        :error="mailSubjectError"
        class="mt-6 mb-4"
      >
        <sw-input
          v-model="recaps.recap_mail_subject"
          :invalid="$v.recaps.recap_mail_subject.$error"
        />
      </sw-input-group>
      <sw-input-group
        :label="
          $t('recaps.default_recap_email_body')
        "
        :error="mailBodyError"
        class="mt-6 mb-4"
      >
        <base-custom-input
          v-model="recaps.recap_mail_body"
          :fields="mailFields"
          @input="test()"
        />
      </sw-input-group>
      <sw-button
        :disabled="isLoading"
        :loading="isLoading"
        variant="primary"
        type="submit"
        class="mt-4"
      >
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ $t('settings.customization.save') }}
      </sw-button>
    </form>

    <sw-divider class="mt-6 mb-8" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {LockClosedIcon} from '@vue-hero-icons/solid'

const { required, maxLength, minValue, minLength } = require('vuelidate/lib/validators')

export default {
  components: {
   LockClosedIcon
  },
  props: {
    settings: {
      type: Object,
      require: true,
      default: false,
    },
    closed : {
      type : Boolean,
      require : true,
      default : false
    }
  },

  data() {
    return {

      recaps: {
        recap_mail_body: null,
        recap_mail_subject : null,
      },
      mailFields: [
        'user',
        'recap',
        'company',
        'recapCustom',
      ],
      isLoading: false,
    }
  },

  computed: {
     ...mapGetters('company', ['getSelectedCompany']),
    company(){
     return this.getSelectedCompany
    },

    mailBodyError() {
      if (!this.$v.recaps.recap_mail_body.$error) {
        return ''
      }

      if (!this.$v.recaps.recap_mail_body.required) {
        return this.$t('validation.required')
      }

      if (!this.$v.recaps.recap_mail_body.minLength) {
        return this.$t('validation.mail_body_min_length', {count : 10})
      }

      if (!this.$v.recaps.recap_mail_body.maxLength) {
        return this.$t('validation.mail_body_max_length', {count : 255})
      }

    },
    mailSubjectError() {
      if (!this.$v.recaps.recap_mail_subject.$error) {
        return ''
      }

      if (!this.$v.recaps.recap_mail_subject.required) {
        return this.$t('validation.required')
      }

      if (!this.$v.recaps.recap_mail_subject.minLength) {
        return this.$t('validation.mail_subject_min_length', {count : 10})
      }

      if (!this.$v.recaps.recap_mail_subject.maxLength) {
        return this.$t('validation.mail_subject_max_length', {count : 255})
      }

    },
  },

  validations: {
    recaps: {
      recap_mail_body: {
        required,
        maxLength: maxLength(255),
      },
      recap_mail_subject : {
        required,
        maxLength: maxLength(255),
      }
    },
  },

  watch: {
    settings(val) {

      this.recaps.recap_mail_body = val ? val.recap_mail_body : ''
      this.recaps.recap_mail_subject = val ? val.recap_mail_subject : ''

    },
  },

  methods: {
    ...mapActions('company', ['updateCompanySettings']),

    test(){
      console.log(this.recaps.recap_mail_body.length)
      console.log(this.recaps.recap_mail_body)
    },

    async updateRecapSetting() {
      this.$v.recaps.$touch()
      if (this.$v.recaps.$invalid) {
        return false
      }

      let data = {
        settings: {
          recap_mail_body: this.recaps.recap_mail_body,
          recap_mail_subject : this.recaps.recap_mail_subject
        },
      }

      if (this.updateSetting(data)) {
        window.toastr['success'](
          this.$t('recaps.recap_setting_updated')
        )
      }
    },

    async updateSetting(data) {
      this.isLoading = true
      let res = await this.updateCompanySettings(data)

      if (res.data.success) {
        this.isLoading = false
        return true
      }

      return false
    },
  },
}
</script>
