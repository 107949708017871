var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.checkBoxStyle.container }, [
    _c(
      "input",
      _vm._b(
        {
          class: [_vm.inputStyle, _vm.disabledClass],
          attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
          domProps: { checked: _vm.shouldBeChecked, value: _vm.value },
          on: {
            change: _vm.updateInput,
            keyup: (e) => _vm.$emit("keyup", e),
            blur: (e) => _vm.$emit("blur", e),
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm._v(" "),
    _vm.label
      ? _c(
          "label",
          {
            class: [_vm.lebelStyle, _vm.disabledClass],
            attrs: { for: _vm.id },
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }