import * as types from './mutation-types'
import * as dashboardTypes from '../dashboard/mutation-types'

export const fetchDocuments = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents`, { params })
      
      .then((response) => {
        console.log(response.data)
        commit(types.SET_DOCUMENTS, response.data.documents.data ? response.data.documents.data : response.data.documents)
        commit(types.SET_TOTAL_DOCUMENTS, response.data.documentTotalCount)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchMyDocuments = ({commit, dispath, state},params) => {
  return new Promise((resolve,reject) => {
    window.axios
      .get('/api/v1/my_documents', { params })
      .then((response) => {
        // commit(types.SET_DOCUMENTS, response.data.documents)
        // commit(types.SET_TOTAL_DOCUMENTS, response.data.documentTotalCount)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDocumentFile = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents/${id}/show/file`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const getRecord = ({ commit, dispatch, state }, record) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents/records?record=${record}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchDocument = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents/${id}`)
      .then((response) => {
        
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchViewDocument = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents/${id}`)
      .then((response) => {
        commit(types.VIEW_DOCUMENT, response.data.document)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendEmail = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.id}/send`, data)
      .then((response) => {
        if (response.data.success) {
          commit(types.UPDATE_DOCUMENT_STATUS, { id: data.id, status: 'SENT' })
          commit(types.VIEW_DOCUMENT)
          commit(
            'dashboard/' + dashboardTypes.UPDATE_DOCUMENT_STATUS,
            { id: data.id, status: 'SENT' },
            { root: true }
          )
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendDocument = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.id}/send`, data)
      .then((response) => {
       
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addDocument = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/documents', data)
      .then((response) => {
        commit(types.ADD_DOCUMENT, response.data.document)

        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteDocument = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/delete`, id)
      .then((response) => {
        commit(types.DELETE_DOCUMENT, id)
        commit('dashboard/' + dashboardTypes.DELETE_DOCUMENT, id, {
          root: true,
        })
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteInvalidDocument = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/delete`, id)
      .then((response) => {
        commit(types.DELETE_DOCUMENT, id)
        commit('dashboard/' + dashboardTypes.DELETE_INVALID_DOCUMENT, id, {
          root: true,
        })
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteMultipleDocuments = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/delete`, { ids: state.selectedDocuments })
      .then((response) => {
        commit(types.DELETE_MULTIPLE_DOCUMENTS, state.selectedDocuments)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateDocument = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    console.log(data)
    window.axios
      .post(`/api/v1/documents/${data.id}`, data.data)
      .then((response) => {
        commit(types.UPDATE_DOCUMENT, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const markAsAccepted = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.id}/status`, data)
      .then((response) => {
        commit(
          'dashboard/' + dashboardTypes.UPDATE_DOCUMENT_STATUS,
          { id: data.id, status: 'ACCEPTED' },
          { root: true }
        )
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const markAsRejected = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.id}/status`, data)
      .then((response) => {
        commit(
          'dashboard/' + dashboardTypes.UPDATE_DOCUMENT_STATUS,
          { id: data.id, status: 'REJECTED' },
          { root: true }
        )
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const markAsSent = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.id}/status`, data)
      .then((response) => {
        commit(types.UPDATE_DOCUMENT_STATUS, { id: data.id, status: 'SENT' })
        commit(
          'dashboard/' + dashboardTypes.UPDATE_DOCUMENT_STATUS,
          { id: data.id, status: 'SENT' },
          { root: true }
        )
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const searchDocument = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/documents?${data}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const searchMyDocument = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/my_documents?${data}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const uploadFile = ({ commit, dispatch, state }, data, ) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/documents/${data.document_id}/upload_file`, data )
      .then((response) => {
       
        resolve(response)
      })
      .catch((err) => {
       
        reject(err)
      })
  })
}


export const selectDocument = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_DOCUMENTS, data)
  if (state.selectedDocuments.length === state.documents.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllDocuments = ({ commit, dispatch, state }) => {
  if (state.selectedDocuments.length === state.documents.length) {
    commit(types.SET_SELECTED_DOCUMENTS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allDocumentIds = state.documents.map((estimt) => estimt.id)
    commit(types.SET_SELECTED_DOCUMENTS, allDocumentIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const resetSelectedDocuments = ({ commit, dispatch, state }) => {
  commit(types.RESET_SELECTED_DOCUMENTS)
}
