var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.document
    ? _c(
        "base-page",
        { staticClass: "xl:pl-96" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.pageTitle } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _vm.currentUser.role == "user"
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "text-sm",
                          attrs: { variant: "primary" },
                          on: { click: _vm.addDocument },
                        },
                        [
                          _c("plus-icon", { staticClass: "h-5 mr-3" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.add")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUser.role == "user"
                    ? _c(
                        "div",
                        { staticClass: "mr-3 ml-3 text-sm" },
                        [
                          _c(
                            "sw-button",
                            {
                              attrs: { variant: "primary-outline" },
                              on: {
                                click: function ($event) {
                                  return _vm.editDocument(_vm.$route.params.id)
                                },
                              },
                            },
                            [
                              _c("pencil-icon", { staticClass: "h-5 mr-3" }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.edit")) +
                                  "\n        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "text-sm",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.removeDocument(_vm.$route.params.id)
                        },
                      },
                    },
                    [
                      _c("trash-icon", { staticClass: "h-5 mr-3" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isReceiptAvailable
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            href:
                              "/documents/" + this.$route.params.id + "/file",
                            "tag-name": "a",
                            variant: "primary-outline",
                          },
                        },
                        [
                          _c("download-icon", { staticClass: "h-5 mr-3" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("documents.download")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUser.role != "user"
                    ? _c(
                        "sw-dropdown",
                        { staticClass: "ml-3" },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass: "h-10",
                              attrs: { slot: "activator", variant: "primary" },
                              slot: "activator",
                            },
                            [
                              _c("dots-horizontal-icon", {
                                staticClass: "h-5",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.document.status !== "ACCEPTED"
                            ? _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onMarkAsAccepted(
                                        _vm.$route.params.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("check-circle-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("documents.mark_as_accepted")
                                      ) +
                                      "\n        "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.document.status !== "REJECTED"
                            ? _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onMarkAsRejected(
                                        _vm.$route.params.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("x-circle-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("documents.mark_as_rejected")
                                      ) +
                                      "\n        "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full",
                },
                [
                  _c(
                    "sw-input",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        placeholder: _vm.$t("general.search"),
                        type: "text",
                        variant: "gray",
                      },
                      on: { input: _vm.onSearch },
                      model: {
                        value: _vm.searchData.searchText,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "searchText", $$v)
                        },
                        expression: "searchData.searchText",
                      },
                    },
                    [
                      _c("search-icon", {
                        staticClass: "h-5",
                        attrs: { slot: "rightIcon" },
                        slot: "rightIcon",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex mb-6 ml-3",
                      attrs: { role: "group", "aria-label": "First group" },
                    },
                    [
                      _c(
                        "sw-dropdown",
                        {
                          attrs: {
                            "close-on-select": false,
                            align: "left",
                            position: "bottom-start",
                          },
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              attrs: {
                                slot: "activator",
                                size: "md",
                                variant: "gray-light",
                              },
                              slot: "activator",
                            },
                            [_c("filter-icon", { staticClass: "h-5" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-2 py-1 mb-2 border-b border-gray-200 border-solid",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.sort_by")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.currentUser.role != "user"
                            ? _c(
                                "sw-dropdown-item",
                                {
                                  staticClass: "flex px-1 py-1 cursor-pointer",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "-mt-2 text-sm font-normal",
                                    },
                                    [
                                      _c("sw-radio", {
                                        attrs: {
                                          id: "filter_document_date",
                                          label: _vm.$t("documents.partenaire"),
                                          name: "filter",
                                          size: "sm",
                                          value: "user_id",
                                        },
                                        on: { change: _vm.onSearch },
                                        model: {
                                          value: _vm.searchData.orderByField,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchData,
                                              "orderByField",
                                              $$v
                                            )
                                          },
                                          expression: "searchData.orderByField",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { staticClass: "flex px-1 py-1 cursor-pointer" },
                            [
                              _c(
                                "sw-input-group",
                                { staticClass: "-mt-2 font-normal" },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      id: "filter_due_date",
                                      label: _vm.$t("documents.date"),
                                      name: "filter",
                                      size: "sm",
                                      value: "date_delivrance",
                                    },
                                    on: { change: _vm.onSearch },
                                    model: {
                                      value: _vm.searchData.orderByField,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchData,
                                          "orderByField",
                                          $$v
                                        )
                                      },
                                      expression: "searchData.orderByField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top-center",
                              value: { content: _vm.getOrderName },
                              expression: "{ content: getOrderName }",
                              modifiers: { "top-center": true },
                            },
                          ],
                          staticClass: "ml-1",
                          attrs: { size: "md", variant: "gray-light" },
                          on: { click: _vm.sortData },
                        },
                        [
                          _vm.getOrderBy
                            ? _c("sort-ascending-icon", { staticClass: "h-5" })
                            : _c("sort-descending-icon", {
                                staticClass: "h-5",
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSearching
                ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                : _c(
                    "div",
                    {
                      staticClass:
                        "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll",
                    },
                    [
                      _vm.currentUser.role == "user"
                        ? _c(
                            "div",
                            _vm._l(_vm.documents, function (type, index) {
                              return _c(
                                "div",
                                { key: index + "type" },
                                _vm._l(
                                  type.documents,
                                  function (document, index) {
                                    return _c(
                                      "router-link",
                                      {
                                        key: index,
                                        class: [
                                          "flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                                          {
                                            "bg-gray-100 border-l-4 border-primary-500 border-solid":
                                              _vm.hasActiveUrl(document.id),
                                          },
                                        ],
                                        staticStyle: {
                                          "border-bottom":
                                            "1px solid rgba(185, 193, 209, 0.41)",
                                        },
                                        attrs: {
                                          to: `/admin/documents/${document.id}/view`,
                                          id: "document-" + document.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex-2" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(type.name) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "sw-badge",
                                              {
                                                staticClass: "px-1 text-xs",
                                                attrs: {
                                                  "bg-color":
                                                    _vm.$utils.getBadgeStatusColor(
                                                      document.status
                                                    ).bgColor,
                                                  color:
                                                    _vm.$utils.getBadgeStatusColor(
                                                      document.status
                                                    ).color,
                                                  "font-size":
                                                    _vm.$utils.getBadgeStatusColor(
                                                      document.status
                                                    ).fontSize,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.$utils.getStatusTranslation(
                                                        document.status
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-1 whitespace-nowrap right",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      document.formattedDateDelivrance
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            }),
                            0
                          )
                        : _vm._l(_vm.documents, function (document, index) {
                            return _c(
                              "router-link",
                              {
                                key: index,
                                class: [
                                  "flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                                  {
                                    "bg-gray-100 border-l-4 border-primary-500 border-solid":
                                      _vm.hasActiveUrl(document.id),
                                  },
                                ],
                                staticStyle: {
                                  "border-bottom":
                                    "1px solid rgba(185, 193, 209, 0.41)",
                                },
                                attrs: {
                                  to: `/admin/documents/${_vm.$route.params.user}/view/${document.id}`,
                                  id: "document-" + document.id,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(document.partenaire.name) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-1 text-xs",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              document.status
                                            ).bgColor,
                                          color: _vm.$utils.getBadgeStatusColor(
                                            document.status
                                          ).color,
                                          "font-size":
                                            _vm.$utils.getBadgeStatusColor(
                                              document.status
                                            ).fontSize,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$utils.getStatusTranslation(
                                                document.status
                                              )
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-1 whitespace-nowrap right",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          document.type_document.name
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              document.formattedDateDelivrance
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                      _vm._v(" "),
                      !_vm.documents.length
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "flex justify-center px-4 mt-5 text-sm text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t("documents.no_matching_documents")
                                  ) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex flex-col min-h-0 mt-8 overflow-hidden",
              staticStyle: { height: "75vh" },
            },
            [
              _c("iframe", {
                staticClass:
                  "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                attrs: { src: `${_vm.shareableLink}` },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }