<template>
  <div>
    <form action="" class="mt-6" @submit.prevent="updateDocumentSetting">
      <sw-input-group
        :label="$t('documents.nb_days')"
        class="mt-3"
        :error="dayError"
      >
        <sw-input
            v-model.trim="documents.notification_x_day"
            :invalid="$v.documents.notification_x_day.$error"
            class="relative mt-1 w-full focus:border focus:border-solid focus:border-primary-500"
            @input="$v.documents.notification_x_day.$touch()"
            type="number"
            style="max-width: 80px"
        />   
      
      </sw-input-group>
      <sw-input-group
        :label="
          $t('documents.default_document_email_subject')
        "
        :error="mailSubjectError"
        class="mt-6 mb-4"
      >
        <sw-input
          v-model="documents.document_mail_subject"
          :invalid="$v.documents.document_mail_subject.$error"
        />
      </sw-input-group>
      <sw-input-group
        :label="
          $t('documents.default_document_email_body')
        "
        :error="mailBodyError"
        class="mt-6 mb-4"
      >
        <base-custom-input
          v-model="documents.document_mail_body"
          :fields="mailFields"
        />
      </sw-input-group>
      <sw-button
        :disabled="isLoading"
        :loading="isLoading"
        variant="primary"
        type="submit"
        class="mt-4"
      >
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ $t('settings.customization.save') }}
      </sw-button>
    </form>

    <sw-divider class="mt-6 mb-8" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {LockClosedIcon} from '@vue-hero-icons/solid'

const { required, maxLength, minValue, minLength,numeric } = require('vuelidate/lib/validators')

export default {
  components: {
   LockClosedIcon
  },
  props: {
    settings: {
      type: Object,
      require: true,
      default: false,
    },
    closed : {
      type : Boolean,
      require : true,
      default : false
    }
  },

  data() {
    return {

      documents: {
        document_mail_body: null,
        document_mail_subject : null,
        notification_x_day : null,
      },
      mailFields: [
        'user',
        'document',
        'company',
        'documentCustom',
      ],
      isLoading: false,
    }
  },

  computed: {
     ...mapGetters('company', ['getSelectedCompany']),
    company(){
     return this.getSelectedCompany
    },

    mailBodyError() {
      if (!this.$v.documents.document_mail_body.$error) {
        return ''
      }

      if (!this.$v.documents.document_mail_body.required) {
        return this.$t('validation.required')
      }

      if (!this.$v.documents.document_mail_body.minLength) {
        return this.$t('validation.mail_body_min_length', {count : 10})
      }

      if (!this.$v.documents.document_mail_body.maxLength) {
        return this.$t('validation.mail_body_max_length', {count : 255})
      }

    },
    dayError() {
      if (!this.$v.documents.notification_x_day.$error) {
          return ''
      }

      if (!this.$v.documents.notification_x_day.required) {
          return this.$t('validation.required')
      }

      if (!this.$v.documents.notification_x_day.numeric) {
          return this.$t('validation.numbers_only')
      }
    },
    mailSubjectError() {
      if (!this.$v.documents.document_mail_subject.$error) {
        return ''
      }

      if (!this.$v.documents.document_mail_subject.required) {
        return this.$t('validation.required')
      }

      if (!this.$v.documents.document_mail_subject.minLength) {
        return this.$t('validation.mail_subject_min_length', {count : 10})
      }

      if (!this.$v.documents.document_mail_subject.maxLength) {
        return this.$t('validation.mail_subject_max_length', {count : 255})
      }

    },
  },

  validations: {
    documents: {
      document_mail_body: {
        required,
        maxLength: maxLength(255),
      },
      document_mail_subject : {
        required,
        maxLength: maxLength(255),
      },
      notification_x_day : {
          required,
          numeric,
      }
    },
  },

  watch: {
    settings(val) {

      this.documents.document_mail_body = val ? val.document_mail_body : ''
      this.documents.document_mail_subject = val ? val.document_mail_subject : ''
      this.documents.notification_x_day = val ? val.notification_x_day : null

    },
  },

  methods: {
    ...mapActions('company', ['updateCompanySettings']),

    test(){
      console.log(this.documents.document_mail_body.length)
      console.log(this.documents.document_mail_body)
    },

    async updateDocumentSetting() {
      this.$v.documents.$touch()
      if (this.$v.documents.$invalid) {
        return false
      }

      let data = {
        settings: {
          document_mail_body: this.documents.document_mail_body,
          document_mail_subject : this.documents.document_mail_subject,
          notification_x_day : this.documents.notification_x_day
        },
      }

      if (this.updateSetting(data)) {
        window.toastr['success'](
          this.$t('documents.document_setting_updated')
        )
      }
    },

    async updateSetting(data) {
      this.isLoading = true
      let res = await this.updateCompanySettings(data)

      if (res.data.success) {
        this.isLoading = false
        return true
      }

      return false
    },
  },
}
</script>
