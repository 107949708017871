var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentUser.role != "user"
    ? _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("documents.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("documents.document", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalDocuments,
                          expression: "totalDocuments",
                        },
                      ],
                      attrs: { size: "lg", variant: "primary-outline" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/documents/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        _vm._s(_vm.$t("documents.new_document")) + "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "relative grid grid-flow-col grid-rows",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$tc("customers.customer", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:mx-8",
                      attrs: { label: _vm.$t("documents.status") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.status,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": false,
                          label: "name",
                          placeholder: _vm.$t("general.select_a_status"),
                        },
                        on: {
                          select: _vm.setActiveTab,
                          remove: function ($event) {
                            return _vm.clearStatusSearch()
                          },
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$t("general.from"),
                        color: "black-light",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block",
                    staticStyle: { "margin-top": "3.5rem" },
                  }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$t("general.to"),
                        color: "black-light",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: {
                        label: _vm.$t("documents.ref"),
                        color: "black-light",
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.document_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "document_number", $$v)
                            },
                            expression: "filters.document_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-black cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("documents.no_documents"),
                description: _vm.$t("documents.list_of_documents"),
              },
            },
            [
              _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/documents/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("documents.add_new_document")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-dropdown",
            { attrs: { right: "", split: "", text: "Split Menu" } },
            [
              _c("sw-dropdown-item", [_vm._v("Item 1")]),
              _vm._v(" "),
              _c("sw-dropdown-item", [_vm._v("Item 2")]),
              _vm._v(" "),
              _c("sw-dropdown-divider"),
              _vm._v(" "),
              _c("sw-dropdown-item", [_vm._v("Item 3")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative",
            },
            [
              _c(
                "div",
                { staticClass: "relative mt-5" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "absolute right-0 m-0 text-sm",
                      staticStyle: { top: "50px" },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.documents.length))]),
                      _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                      _c("b", [_vm._v(_vm._s(_vm.totalDocuments))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-tabs",
                    {
                      staticClass: "mb-10",
                      attrs: { "active-tab": _vm.activeTab },
                      on: { update: _vm.setStatusFilter },
                    },
                    [
                      _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$t("general.draft"),
                          filter: "DRAFT",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$t("documents.accepted"),
                          filter: "ACCEPTED",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$t("general.sent"),
                          filter: "SENT",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-tab-item", {
                        attrs: { title: _vm.$t("general.all"), filter: "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-transition",
                    { attrs: { type: "fade" } },
                    [
                      _vm.selectedDocuments.length
                        ? _c(
                            "sw-dropdown",
                            {
                              staticClass: "absolute float-right",
                              staticStyle: { "margin-top": "-70px" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n            "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.removeMultipleDocuments } },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.documents && _vm.documents.length,
                      expression: "documents && documents.length",
                    },
                  ],
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-6",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary", size: "sm" },
                    on: { change: _vm.selectAllDocuments },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      label: _vm.$t("general.select_all"),
                      variant: "primary",
                      size: "sm",
                    },
                    on: { change: _vm.selectAllDocuments },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              { staticClass: "flex items-center" },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.id,
                                    value: row.id,
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  model: {
                                    value: _vm.selectField,
                                    callback: function ($$v) {
                                      _vm.selectField = $$v
                                    },
                                    expression: "selectField",
                                  },
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3008535907
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      "sort-as": "type",
                      label: _vm.$t("documents.type"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n\n          " +
                                  _vm._s(row.type_document.name) +
                                  "\n      "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1112122986
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$tc("documents.document", 1),
                      show: "ref",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$tc("documents.document", 1))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: { path: `documents/${row.id}/view` },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          \n            " +
                                      _vm._s(row.ref) +
                                      "\n\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1149600806
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("documents.partenaire"),
                      "sort-as": "name",
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      "sort-as": "status",
                      label: _vm.$t("documents.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.status
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.status
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$utils.getStatusTranslation(
                                          row.status
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2828191613
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                { attrs: { containerClass: "w-56" } },
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        "tag-name": "router-link",
                                        to: {
                                          path: `documents/${row.id}/edit`,
                                        },
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeDocument(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        "tag-name": "router-link",
                                        to: {
                                          path: `documents/${row.id}/view`,
                                        },
                                      },
                                    },
                                    [
                                      _c("eye-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.view")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendDocument(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("paper-airplane-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("documents.send_document")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  row.status !== "ACCEPTED"
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.onMarkAsAccepted(
                                                row.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("check-circle-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "documents.mark_as_accepted"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status !== "REJECTED"
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.onMarkAsRejected(
                                                row.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("x-circle-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "documents.mark_as_rejected"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3459768145
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("documents.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("documents.document", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.document_types, function (type, index) {
            return _c("document-row", {
              key: index,
              attrs: {
                user: _vm.currentUser,
                index: index,
                "document-type": type,
                document: _vm.loadDocument(type.id),
              },
            })
          }),
          _vm._v(" "),
          _c("sw-divider"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }