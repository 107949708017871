var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { display: "inline-block" },
      attrs: {
        fill: "none",
        stroke: "red",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2.5",
        viewBox: "0 0 24 24",
      },
    },
    [_c("path", { attrs: { d: "M6 18L18 6M6 6l12 12" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }