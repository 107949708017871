import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index.js'
import Ls from './services/ls'
import permissions from './stub/permissions'
import utils from './helpers/utilities'

/*
 |--------------------------------------------------------------------------
 | Views
 |--------------------------------------------------------------------------|
 */

// Layouts
import LayoutBasic from './views/layouts/LayoutBasic.vue'
import LayoutLogin from './views/layouts/LayoutLogin.vue'
import LayoutWizard from './views/layouts/LayoutWizard.vue'

// Auth
import Login from './views/auth/Login.vue'
import ForgotPassword from './views/auth/ForgotPassword.vue'
import ResetPassword from './views/auth/ResetPassword.vue'
import Register from './views/auth/Register.vue'

import NotFoundPage from './views/errors/404.vue'

// Dashbord
import Dashboard from './views/dashboard/Dashboard.vue'

//Documents
import DocumentsIndex from './views/documents/Index.vue'
import DocumentIndex from './views/documents/Index_.vue'
import DocumentsView from './views/documents/View.vue'

//Recaps
import recapIndex from './views/recaps/Index.vue'
import recapView from './views/recaps/View.vue'

//User
import UserIndex from './views/users/Index.vue'
import UserCreate from './views/users/Create.vue'
import UserContract from "./views/users/Sign.vue"

// Report
import SalesReports from './views/reports/SalesReports'
import ExpensesReport from './views/reports/ExpensesReport'
import ProfitLossReport from './views/reports/ProfitLossReport'
import TaxReport from './views/reports/TaxReport.vue'
import ReportLayout from './views/reports/layout/Index.vue'

// Settings
import SettingsLayout from './views/settings/SettingsIndex.vue'
import CompanyInfo from './views/settings/CompanyInfoSetting.vue'
import Customization from './views/settings/CustomizationSetting.vue'
import Notifications from './views/settings/NotificationsSetting.vue'
import Preferences from './views/settings/PreferencesSetting.vue'
import UserProfile from './views/settings/UserProfileSetting.vue'
import Wizard from './views/wizard/Wizard.vue'
import DocumentType from './views/settings/DocumentTypeSetting.vue'
import TestView from './views/test.vue'
import ContractSign from './views/settings/ContractSign.vue'


Vue.use(VueRouter)

const routes = [
  /*
   |--------------------------------------------------------------------------
   | Auth & Registration
   |--------------------------------------------------------------------------|
   */
   
    // subscribe  
  {
    path: '/',
    component: LayoutLogin,
    children: [
      {
        path: '/',
        component: Login,
      },
      {
        path: 'login',
        component: Login,
        name: 'login',
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: 'reset-password',
      },

      {
        path: 'test',
        component: TestView,
        name: 'test',
      },
      // {
      //   path: 'register',
      //   component: Register,
      //   name: 'register',
      // },
     
    ],
  },

  /*
   |--------------------------------------------------------------------------
   | Onboarding
   |--------------------------------------------------------------------------|
   */
  {
    path: '/on-boarding',
    component: LayoutWizard,
    children: [
      {
        path: '/',
        component: Wizard,
        name: 'wizard',
      },

     
    ],
  },

  /*
   |--------------------------------------------------------------------------
   | Admin
   |--------------------------------------------------------------------------|
   */
  {
    path: '/admin',
    component: LayoutBasic,
    meta: { requiresAuth: true, isSubscribed : true },
    children: [
      // Dashboard
      {
        path: '/',
        component: Dashboard,
        name: 'dashboard.index',
       
      },
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'dashboard',
      },
  
      // documents
      {
        path: 'documents',
        name: 'documents.index',
        component: DocumentsIndex,
      },
      {
        path: 'documents/:id/view',
        name: 'documents.view',
        component: DocumentsView,
      },
      {
        path: 'documents/:user/view/:id',
        name: 'documents.view.admnin',
        component: DocumentsView,
      },

       // recaps
       {
        path: 'recaps',
        name: 'recaps.index',
        component: recapIndex,
      },
      {
        path: 'recaps/:id/view',
        name: 'recaps.view',
        component: recapView,
      },
      {
        path: 'recaps/:id/view/:media',
        name: 'recaps.media.view',
        component: recapView,
      },
      {
        path: 'recaps/:user/view/:id',
        name: 'recaps.view.admnin',
        component: recapView,
      },

      // User
      {
        path: 'users',
        name: 'users.index',
        component: UserIndex,
        beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_company,next)
            },
      },
      {
        path: 'users/create',
        name: 'users.create',
        component: UserCreate,
        beforeEnter : (to,from,next) => {
          utils.hasPermissionRouter(permissions.manage_company,next)
        },
      },
      {
        path: 'users/:id/edit',
        name: 'users.edit',
        component: UserCreate,
        beforeEnter : (to,from,next) => {
          utils.hasPermissionRouter(permissions.manage_company,next)
        },
      },

      {
        path: 'users/:id/contrat',
        name: 'users.edit',
        component: UserContract,
      },

      // // Reports
      // {
      //   path: 'reports',
      //   component: ReportLayout,
      //   meta : {goldPAck : true},   
      //   name : 'reports.index',
      //   children: [
      //     {
      //       path: 'sales',
      //       component: SalesReports,
      //     },
      //     {
      //       path: 'expenses',
      //       component: ExpensesReport,
      //     },
      //     {
      //       path: 'profit-loss',
      //       component: ProfitLossReport,
      //     },
      //     {
      //       path: 'taxes',
      //       component: TaxReport,
      //     },
      //   ],
      //   beforeEnter : (to,from,next) => {
      //     utils.hasPermissionRouter(permissions.view_reports,next)
      //   },
      // },

     
      // Settings
      {
        path: 'settings',
        component: SettingsLayout,
        children: [
          {
            path: 'company-info',
            name: 'company.info',
            component: CompanyInfo,
            beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_company,next)
            },
          },
          {
            path: 'customization',
            name: 'customization',
            component: Customization,
            beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_company,next)
            },
          },
          {
            path: 'user-profile',
            name: 'user.profile',
            component: UserProfile,
          },
          {
            path: 'preferences',
            name: 'preferences',
            component: Preferences,
            beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_company,next)
            },
          },

          // type documents
          {
            path: 'document-types',
            name: 'document_types',
            component: DocumentType,
            beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_company,next)
            },
          },

          // contract
          {
            path: 'contract',
            name: 'contract',
            component: ContractSign,
          },
          
          {
            path: 'notifications',
            name: 'notifications',
            component: Notifications,
            meta : {silverOrGoldPack : true},
            beforeEnter : (to,from,next) => {
              utils.hasPermissionRouter(permissions.manage_notification,next)
            },
          },

          // {
          //   path: 'roles',
          //   name: 'roles',
          //   component: Roles,
          //   beforeEnter : (to,from,next) => {
          //     if (Ls.get('userRole') == 'super admin')
          //       next()
          //       else {
          //         window.toastr['error']('Cette action n\'est pas autorisé')
          //          next({'name': 'user.profile'})
          //       }
          //   },
          // },
         
        ],
      },
    ],
  },

  //  DEFAULT ROUTE
  { path: '*', component: NotFoundPage },
]



const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition){
    return savedPosition || to.hash
  }
})


router.beforeEach((to, from, next) => {
 console.log('each 01')
 console.log(Ls.get('currentRole'))
 next()   
  // if (to.matched.some(route => route.meta.pathSubscribe)) {
  //   next()
  // }
  // else if (to.matched.some(route => route.meta.isSubscribed)) {
  //   store.dispatch('auth/isSubscribed')
  //     .then(response => {
  //         //console.log(response)
  //         if (response.data.subscribed){
  //           store.dispatch('users/getAuthUser')
  //           .then (res => {
  //             //console.log(res)
            
  //             if (to.matched.some(route => route.meta.silverOrGoldPack)){
  //               if (to.matched.some(route => route.meta.limited)) {
  //                 store.dispatch('company/fetchNumberUsers')
  //                 .then(response => {

  //                   let users_number = res.data.user.company.pack_.settings.find(setting =>setting.option == 'users_number')
  //                   //console.log(users_number)
  //                   if ( users_number){
  //                     if(response.data.totalUsers >= ( parseInt(users_number.value) -1)) {
  //                       next({name: 'users.index'})
  //                     }
  //                     else 
  //                       next()                      
  //                   }              
  //                   else 
  //                     next()

  //                 })
  //               }

  //               res.data.user.company.pack_.settings.forEach(setting => {
  //                 if ( setting.option == 'notes' && setting.value === '0' && to.name == 'notes') {
                  
  //                     next({name: 'dashboard'})
  //                   }

  //                 else if (setting.option == 'notifications' &&  setting.value === '0' && to.name =='notifications')
  //                 {
                  
  //                   console.log('out')
  //                   next({name: 'dashboard'})
  //                 }
  //                 else
  //                   next()
                    
  //               })
                
  //               next()
  //             }
  //             else if (to.matched.some(route => route.meta.goldPAck)){     

  //               res.data.user.company.pack_.settings.forEach(setting => {
  //                 if ( setting.option == 'expenses' && setting.value === '0' && to.name == 'expenses.index')
  //                   next({name: 'dashboard'})
  //                 else if( setting.option == 'reports' && setting.value === '0' && to.name == 'reports.index')
  //                   next({name: 'dashboard'})
  //                 else if( setting.option == 'custom_fields' && setting.value === '0' && to.name == 'custom.fields')
  //                   next({name: 'dashboard'})
  //                 else
  //                   next()
                  
  //               })
                
  //               next()
  //             }
  //             else if (to.matched.some(route => route.meta.outilCommercial)){              
  //               res.data.user.company.pack_.settings.forEach(setting => {
  //                 if ( setting.option == 'outil_commercial' ){
  //                   if (setting.value === '0')
  //                     next({name: 'dashboard'})
  //                   else
  //                     next()
  //                 }
  //               })
                
  //               next()
  //             }

  //             else if (to.matched.some(route => route.meta.helpDecision)){              
  //               res.data.user.company.pack_.settings.forEach(setting => {
  //                 if ( setting.option == 'help_decision' ){
  //                   if (setting.value === '0')
  //                     next({name: 'dashboard'})
  //                   else
  //                     next()
  //                 }
  //               })
                
  //               next()
  //             }
             
  //             else 
  //               next()
  //           })
           
  //         }
  //         else 
  //         next({name: 'subscribe.index'})
  //       })
         
  //     }
  // else {
  //       next()
  //     }
 
})

export default router

