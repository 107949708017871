<template>
  <base-page v-if="currentUser.role != 'user'">
    <sw-page-header :title="$t('documents.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />

        <sw-breadcrumb-item
          to="#"
          :title="$tc('documents.document', 2)"
          active
        />
      </sw-breadcrumb>

      <template slot="actions">
        <sw-button
          v-show="totalDocuments"
          size="lg"
          variant="primary-outline"
          @click="toggleFilter"
        >
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="w-4 h-4 ml-2 -mr-1" />
        </sw-button>
        <sw-button
            tag-name="router-link" to="/admin/documents/create"
            size="lg"
            variant="primary"
            class="ml-4"
        >
            <plus-icon class="w-6 h-6 mr-1 -ml-2" />{{$t('documents.new_document') }}
        </sw-button>
       
      </template>
    </sw-page-header>

    <slide-y-up-transition>
      <sw-filter-wrapper
        v-show="showFilters"
        class="relative grid grid-flow-col grid-rows"
      >
        <sw-input-group :label="$tc('customers.customer', 1)" class="mt-2">
          <base-customer-select
            ref="customerSelect"
            @select="onSelectCustomer"
            @deselect="clearCustomerSearch"
          />
        </sw-input-group>

        <sw-input-group :label="$t('documents.status')" class="mt-2 xl:mx-8">
          <sw-select
            v-model="filters.status"
            :options="status"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            :placeholder="$t('general.select_a_status')"
            @select="setActiveTab"
            @remove="clearStatusSearch()"
          />
        </sw-input-group>

        <sw-input-group
          :label="$t('general.from')"
          color="black-light"
          class="mt-2"
        >
          <base-date-picker
            v-model="filters.from_date"
            :calendar-button="true"
            calendar-button-icon="calendar"
          />
        </sw-input-group>

        <div
          class="hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block"
          style="margin-top: 3.5rem"
        />

        <sw-input-group
          :label="$t('general.to')"
          color="black-light"
          class="mt-2"
        >
          <base-date-picker
            v-model="filters.to_date"
            :calendar-button="true"
            calendar-button-icon="calendar"
          />
        </sw-input-group>

        <sw-input-group
          :label="$t('documents.ref')"
          color="black-light"
          class="mt-2 xl:ml-8"
        >
          <sw-input v-model="filters.document_number">
            <hashtag-icon slot="leftIcon" class="h-5 ml-1 text-gray-500" />
          </sw-input>
        </sw-input-group>

        <label
          class="absolute text-sm leading-snug text-black cursor-pointer"
          style="top: 10px; right: 15px"
          @click="clearFilter"
          >{{ $t('general.clear_all') }}</label
        >
      </sw-filter-wrapper>
    </slide-y-up-transition>

    <sw-empty-table-placeholder
      v-show="showEmptyScreen"
      :title="$t('documents.no_documents')"
      :description="$t('documents.list_of_documents')"
    >
      <moon-walker-icon class="mt-5 mb-4" />

      <sw-button
        slot="actions"
        tag-name="router-link"
        to="/admin/documents/create"
        size="lg"
        variant="primary-outline"
      >
        <plus-icon class="w-6 h-6 mr-1 -ml-2" />
        {{ $t('documents.add_new_document') }}
      </sw-button>
     
    </sw-empty-table-placeholder>
    <sw-dropdown right split text="Split Menu">
        <sw-dropdown-item>Item 1</sw-dropdown-item>
        <sw-dropdown-item>Item 2</sw-dropdown-item>
        <sw-dropdown-divider></sw-dropdown-divider>
        <sw-dropdown-item>Item 3</sw-dropdown-item>
    </sw-dropdown>

    <div v-show="!showEmptyScreen" class="relative">
      <div class="relative mt-5">
        <p class="absolute right-0 m-0 text-sm" style="top: 50px">
          {{ $t('general.showing') }}: <b>{{ documents.length }}</b>
          {{ $t('general.of') }} <b>{{ totalDocuments }}</b>
        </p>

        <!-- Tabs -->
        <sw-tabs
          :active-tab="activeTab"
          class="mb-10"
          @update="setStatusFilter"
        >
          <sw-tab-item :title="$t('general.draft')" filter="DRAFT" />
          <sw-tab-item :title="$t('documents.accepted')" filter="ACCEPTED" />
          <sw-tab-item :title="$t('general.sent')" filter="SENT" />
          <sw-tab-item :title="$t('general.all')" filter="" />
        </sw-tabs>

        <sw-transition type="fade">
          <sw-dropdown
            v-if="selectedDocuments.length"
            class="absolute float-right"
            style="margin-top: -70px"
          >
            <span
              slot="activator"
              class="flex block text-sm font-medium cursor-pointer select-none text-primary-400"
            >
              {{ $t('general.actions') }}
              <chevron-down-icon class="h-5" />
            </span>

            <sw-dropdown-item @click="removeMultipleDocuments">
              <trash-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.delete') }}
            </sw-dropdown-item>
          </sw-dropdown>
        </sw-transition>
      </div>

      <div
        v-show="documents && documents.length"
        class="absolute z-10 items-center pl-4 mt-2 select-none md:mt-6"
      >
        <sw-checkbox
          v-model="selectAllFieldStatus"
          variant="primary"
          size="sm"
          class="hidden md:inline"
          @change="selectAllDocuments"
        />

        <sw-checkbox
          v-model="selectAllFieldStatus"
          :label="$t('general.select_all')"
          variant="primary"
          size="sm"
          class="md:hidden"
          @change="selectAllDocuments"
        />
      </div>

      <sw-table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <div slot-scope="row" class="flex items-center">
            <sw-checkbox
              :id="row.id"
              v-model="selectField"
              :value="row.id"
              variant="primary"
              size="sm"
            />
          </div>
        </sw-table-column>

       <sw-table-column
          :sortable="true"
          sort-as="type"
          :label="$t('documents.type')"

        >
        <template slot-scope="row">

            {{row.type_document.name}}
        </template>

      </sw-table-column>

      <sw-table-column
          :sortable="true"
          :label="$tc('documents.document', 1)"
          show="ref"
        >
      

        <template slot-scope="row">
              
            <span>{{ $tc('documents.document', 1) }}</span>
            <router-link
              :to="{ path: `documents/${row.id}/view` }"
              class="font-medium text-primary-500"
            >
            
              {{ row.ref }}

            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          :label="$t('documents.partenaire')"
          sort-as="name"
          show="name"
        />

        <sw-table-column
          :sortable="true"
          sort-as="status"
          :label="$t('documents.status')"
          show="status"
        >
          <template slot-scope="row">
            <span> {{ $t('documents.status') }}</span>
            <sw-badge
              :bg-color="$utils.getBadgeStatusColor(row.status).bgColor"
              :color="$utils.getBadgeStatusColor(row.status).color"
              class="px-3 py-1"
            >
              {{ $utils.getStatusTranslation(row.status) }}
            </sw-badge>
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span> {{ $t('documents.action') }} </span>
            <sw-dropdown containerClass="w-56">
              <dot-icon slot="activator" />

               <sw-dropdown-item
                tag-name="router-link"
                :to="{ path: `documents/${row.id}/edit` }"
              >
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item
               @click="removeDocument(row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                tag-name="router-link"
                :to="{ path: `documents/${row.id}/view` }"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                @click="sendDocument(row)"
              >
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('documents.send_document') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status !== 'ACCEPTED'"
                @click="onMarkAsAccepted(row.id)"
              >
                <check-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('documents.mark_as_accepted') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status !== 'REJECTED'"
                @click="onMarkAsRejected(row.id)"
              >
                <x-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('documents.mark_as_rejected') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
  </base-page>

  <base-page v-else>
     <sw-page-header :title="$t('documents.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />

        <sw-breadcrumb-item
          to="#"
          :title="$tc('documents.document', 2)"
          active
        />
      </sw-breadcrumb>
    </sw-page-header>

     
      <document-row
        v-for="(type, index) in document_types" 
        :key="index"
        :user="currentUser" 
        :index="index" 
        :document-type="type"
        :document="loadDocument(type.id)"
      />
      <sw-divider></sw-divider>

  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Permissions from '../../stub/permissions'
import Ls from '../../services/ls'
import DocumentRow from "./partials/DocumentRow.vue"
import MoonWalkerIcon from '@/components/icon/MoonwalkerIcon'
import {
  PlusIcon,
  CalculatorIcon,
  DocumentIcon,
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  EyeIcon,
  XCircleIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  TrashIcon,
  PencilIcon,
  HashtagIcon,
   TruckIcon,
    ViewGridAddIcon,
    CloudUploadIcon,
  LightBulbIcon,
  
} from '@vue-hero-icons/solid'

import { DotsHorizontalIcon } from '@vue-hero-icons/outline'

export default {
  components: {
    MoonWalkerIcon,
    CalculatorIcon,
    DotsHorizontalIcon,
    DocumentIcon,
    PlusIcon,
    FilterIcon,
    XIcon,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    CheckCircleIcon,
    PaperAirplaneIcon,
    DocumentTextIcon,
    XCircleIcon,
    EyeIcon,
    HashtagIcon,
    TruckIcon,
    ViewGridAddIcon,
    LightBulbIcon,
    CloudUploadIcon,
    DocumentRow
  },

  data() {
    return {
      showFilters: false,
      currency: null,
      previewReceipt: null,
      newDocument : [{
          date_delivrance :null,
          type_id : null,
          attachment : null,
          fileObject : null,
          status : ''
      }],
      status: [
        { id : 'DRAFT', name:this.$t('general.draft') },
        { id : 'SENT', name:  this.$t('general.sent') } ,
        { id : 'VIEWED', name: this.$t('general.viewed') },
        { id : 'EXPIRED', name: this.$t('general.expired') },
        { id : 'ACCEPTED', name: this.$t('general.accepted') }, 
        { id : 'REJECTED', name: this.$t('general.rejected')}
      ],
      isRequestOngoing: true,
      activeTab: this.$t('general.draft'),
      closed :false,
      helpDecision : false,
      permissionsStub : Permissions,

      filters: {
        customer: '',
        status: {id: 'DRAFT', name : this.$t('general.draft')},
        from_date: '',
        to_date: '',
        document_number: '',
      },
      my_documents : [],
    }
  },

  created() {
    this.permissionsStub = Permissions
    if(this.currentUser.role == 'user')
      this.initialiseDocuments()
    console.log(this.my_documents)
  },

  computed: {
    showEmptyScreen() {
      return !this.totalDocuments && !this.isRequestOngoing
    },

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },

    ...mapGetters('customer', ['customers']),

    ...mapGetters('document', [
      'selectedDocuments',
      'totalDocuments',
      'documents',
      'selectAllField',
    ]),

    ...mapGetters('user', ['currentUser','userPermissions']),
    ...mapGetters('documentType', ['document_types']),
    ...mapGetters('company', ['getSelectedCompany']),
    company(){
     return this.getSelectedCompany
    },

    selectField: {
      get: function () {
        return this.selectedDocuments
      },
      set: function (val) {
        this.selectDocument(val)
      },
    },

    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllDocuments()
    }
  },

  methods: {
    ...mapActions('document', [
      'fetchDocuments',
      'fetchMyDocuments',
      'resetSelectedDocuments',
      'getRecord',
      'selectDocument',
      'selectAllDocuments',
      'deleteDocument',
      'deleteMultipleDocuments',
      'markAsSent',
      'convertToInvoice',
      'setSelectAllState',
      'markAsAccepted',
      'markAsRejected',
      'sendEmail',
    ]),

    ...mapActions('modal', ['openModal']),
    ...mapActions('documentType',['fetchDocumentTypes']),
   
    hasPermission(permission) {
      // tester si permission dans userPermissions
      //console.log(Ls.get('userRole'))
      if (Ls.get('userRole') == 'super admin')
        return true
      else {
        return (Ls.get('userPermissions').includes(permission))          
      }
     // return this.userPermissions.includes(permission)
    },


    refreshTable() {
      this.$refs.table.refresh()
    },
    initialiseDocuments (){
        Promise.all([
          this.fetchDocumentTypes(),
          this.fetchMyDocuments()
        ])
        .then(async([res1,res2]) => {

          this.my_documents = res2.data.documents
            console.log(res2)
        })
        .catch((error) => {
          console.log(error)
        })
        // if(this.currentUser.role == 'user') {
        //    let res = await this.fetchMyDocuments()
        //    this.my_documents = res.data.documents
        //     console.log(res)
        // }
        // console.log(this.documents)
        
    },

    loadDocument(id) {
        let res = null
        Promise.all([this.fetchMyDocuments({
            type_document_id : id
        })
        ]).then(async([response]) => {
            console.log(response)
            return {
              data : response.data.documents[0]
            }

        })
    },
    getDocument(id) {
        let doc = this.my_documents.filter((item) => {
            return item.type_document_id == id
        });
        console.log(id)
        console.log(doc)
        if(doc[0]){
            console.log(doc[0])
            return doc[0]
        }
        else return null
    },

    async fetchData({ page, filter, sort }) {

      let data = {
        user_id:
          this.filters.customer === ''
            ? this.filters.customer
            : this.filters.customer.id,
        status: this.filters.status.id,
        date_delivrance: this.filters.from_date,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchDocuments(data)
      console.log(response.data)
      this.isRequestOngoing = false

      this.currency = response.data.currency

      return {
        data: response.data.documents.data,
        pagination: {
          totalPages: response.data.documents.last_page,
          currentPage: page,
          count: response.data.documents.count,
        },
      }
    },

    setStatusFilter(val) {
      if (this.activeTab == val.title) {
        return true
      }
      this.activeTab = val.title
      switch (val.title) {
        case this.$t('general.draft'):
          this.filters.status = {id:'DRAFT'}
          break
        case this.$t('general.sent'):
          this.filters.status = {id:'SENT'}
          break
        case this.$t('documents.accepted'):
          this.filters.status = {id:'ACCEPTED'}
          break
        default:
          this.filters.status = {id:''}
          break
      }
    },

    async onMarkAsAccepted(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_mark_as_accepted'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsRejected) => {
        if (markedAsRejected) {
          const data = {
            id: id,
            status: 'ACCEPTED',
          }

          let response = await this.markAsAccepted(data)

          if (response.data) {
            this.$refs.table.refresh()
            window.toastr['success'](
              this.$tc('documents.marked_as_accepted_message')
            )
          }
        }
      })
    },

    async onMarkAsRejected(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_mark_as_rejected'),
        icon: '/assets/icon/times-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsRejected) => {
        if (markedAsRejected) {
          const data = {
            id: id,
            status: 'REJECTED',
          }

          let response = await this.markAsRejected(data)

          if (response.data) {
            this.$refs.table.refresh()
            window.toastr['success'](
              this.$tc('documents.marked_as_rejected_message')
            )
          }
        }
      })
    },

    setFilters() {
      this.resetSelectedDocuments()
      this.$refs.table.refresh()
    },

    clearFilter() {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(
          this.filters.customer
        )
      }

      this.filters = {
        customer: '',
        status: {id: '', name : ''},
        from_date: '',
        to_date: '',
        document_number: '',
      }

      this.activeTab = this.$t('general.all')
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    onSelectCustomer(customer) {
      this.filters.customer = customer
    },

    async removeDocument(id) {
      this.id = id
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('documents.confirm_delete', 1),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteDocument({ ids: [this.id] })

          if (res.data.success) {
            this.$refs.table.refresh()
            this.resetSelectedDocuments()
            window.toastr['success'](this.$tc('documents.deleted_message', 1))
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async convertInToinvoice(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_conversion'),
        icon: '/assets/icon/file-alt-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willConvertInToinvoice) => {
        if (willConvertInToinvoice) {
          let res = await this.convertToInvoice(id)

          if (res.data) {
            window.toastr['success'](this.$t('documents.conversion_message'))
            this.$router.push(`invoices/${res.data.invoice.id}/view`)
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async removeMultipleDocuments() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('documents.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteMultipleDocuments()

          if (res.data.success) {
            this.$refs.table.refresh()
            this.resetSelectedDocuments()
            window.toastr['success'](this.$tc('documents.deleted_message', 2))
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = ''
      this.refreshTable()
    },

    async clearStatusSearch(removedOption, id) {
      this.filters.status = {id: '', name: ''}
      console.log(this.filters.status)
      this.activeTab = this.$t('general.all')
      this.refreshTable()
    },

    async onMarkAsSent(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_mark_as_sent'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willMarkAsSent) => {
        if (willMarkAsSent) {
          const data = {
            id: id,
            status: 'SENT',
          }

          let response = await this.markAsSent(data)
          this.refreshTable()

          if (response.data) {
            window.toastr['success'](
              this.$tc('documents.mark_as_sent_successfully')
            )
          }
        }
      })
    },
    async sendDocument(Document) {
      this.openModal({
        title: this.$t('documents.send_document'),
        componentName: 'SendDocumentModal',
        id: document.id,
        data: document,
        variant: 'lg',
      })
    },
    setActiveTab(val) {
      switch (val.name) {
        case this.$t('general.draft'):
          this.activeTab = this.$t('general.draft')
          break
        case this.$t('general.sent'):
          this.activeTab = this.$t('general.sent')
          break
        case this.$t('documents.accepted'):
          this.activeTab = this.$t('documents.accepted')
          break
        default:
          this.activeTab = this.$t('general.all')
          break
      }
    },
  },
}
</script>
