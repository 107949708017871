import * as types from './mutation-types'
import * as dashboardTypes from '../dashboard/mutation-types'

export const fetchRecaps = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps`, { params })
      
      .then((response) => {
        console.log(response)
        commit(types.SET_RECAPS, response.data.recaps.data ? response.data.recaps.data : response.data.recaps)
        commit(types.SET_TOTAL_RECAPS, response.data.recapTotalCount)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchMyRecaps = ({commit, dispath, state},params) => {
  return new Promise((resolve,reject) => {
    window.axios
      .get('/api/v1/my_RECAPs', { params })
      .then((response) => {
        // commit(types.SET_RECAPS, response.data.recaps)
        // commit(types.SET_TOTAL_RECAPS, response.data.recapTotalCount)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getRecapFile = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps/${id}/show/file`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const getRecord = ({ commit, dispatch, state }, record) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps/records?record=${record}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchRecap = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps/${id}`)
      .then((response) => {
        
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchViewRecap = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps/${id}`)
      .then((response) => {
        commit(types.VIEW_RECAP, response.data.recap)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendEmail = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.id}/send`, data)
      .then((response) => {
        if (response.data.success) {
          commit(types.UPDATE_RECAP_STATUS, { id: data.id, status: 'SENT' })
          commit(types.VIEW_RECAP)
         
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendRecap = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.id}/send`, data)
      .then((response) => {
       
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addRecap = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/recaps', data)
      .then((response) => {
        commit(types.ADD_RECAP, response.data.recap)

        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteRecap = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/delete`, id)
      .then((response) => {
        // commit(types.DELETE_RECAP, id)
        // commit('dashboard/' + dashboardTypes.DELETE_RECAP, id, {
        //   root: true,
        // })
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteMultipleRecaps = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/delete`, { ids: state.selectedRecaps })
      .then((response) => {
        commit(types.DELETE_MULTIPLE_RECAPS, state.selectedRecaps)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateRecap = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    console.log(data)
    window.axios
      .post(`/api/v1/recaps/${data.id}`, data.data,{
        headers: {
         'Content-Type': 'multipart/form-data',
        }
      })
      .then((response) => {
        console.log(response)
        commit(types.UPDATE_RECAP, response.data)
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}

export const markAsAccepted = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.id}/status`, data)
      .then((response) => {
        
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const markAsRejected = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.id}/status`, data)
      .then((response) => {
    
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const markAsSent = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.id}/status`, data)
      .then((response) => {
        commit(types.UPDATE_RECAP_STATUS, { id: data.id, status: 'SENT' })
    
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const searchRecap = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/recaps?${data}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const uploadFile = ({ commit, dispatch, state }, data, ) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/recaps/${data.recap_id}/upload_file`, data )
      .then((response) => {
       
        resolve(response)
      })
      .catch((err) => {
       
        reject(err)
      })
  })
}


export const selectRecap = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_RECAPS, data)
  if (state.selectedRecaps.length === state.recaps.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllRecaps = ({ commit, dispatch, state }) => {
  if (state.selectedRecaps.length === state.recaps.length) {
    commit(types.SET_SELECTED_RECAPS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allRecapIds = state.recaps.map((estimt) => estimt.id)
    commit(types.SET_SELECTED_RECAPS, allRecapIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const resetSelectedRecaps = ({ commit, dispatch, state }) => {
  commit(types.RESET_SELECTED_RECAPS)
}
