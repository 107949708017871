<template>
<base-page  class="items">
    <sw-page-header :title="$t('users.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="#" :title="'Contrat'" active />
      </sw-breadcrumb>
    </sw-page-header>
    <sw-card variant="">
        <div slot="header" class="flex flex-wrap justify-between lg:flex-nowrap">
        <div>
            <h6 class="sw-section-title">
            Contrat du {{partenaire.name}} {{partenaire.first_name }}
            </h6>
            <p
            class="mt-2 text-sm leading-snug text-gray-500"
            style="max-width: 680px"
            >
            <!-- Signer votre contart ou téléchargé le -->
            </p>
        </div>
        
        </div>
        <div
        v-if="!loadContract "
        class="flex flex-col w-full min-h-0 mt-8 overflow-hidden sw-scroll"
        style="height: 75vh"
        >
        <div id="iframe-container"></div>
        <iframe
            v-if="signatureLink"
            :src="`${signatureLink}`"
            class="flex-1 border border-gray-400 border-solid rounded-md frame-style"
        />
        <div v-else >
        pas de contrat en ce moment
        </div>
        </div>
        
        
    </sw-card>
</base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TrashIcon, PencilIcon, PlusIcon, } from '@vue-hero-icons/solid'
export default {
  components: {
    TrashIcon,
    PencilIcon,
    PlusIcon,
    
  },
  data() {
      return {
        sign_url :'',
        loadContract : true ,        
        isSandbox: true,
        partenaire : null,
      }
  },

  computed: {
    signatureLink() {
      return `${this.sign_url}`
    },
  },

  mounted() {
    
  },

  created() {
    this.loadData()
    .then(() => {
        this.getContract()
    })
  },

  methods: {

    ...mapActions('users', [
      'fetchUser',
    ]),

    async loadData() {
      let response = await this.fetchUser(this.$route.params.id)

      if (response.data) {
        this.partenaire = {  ...response.data.user }
      }
      console.log(response.data)
     
    },
    async getContract(){
      this.loadContract = true
        let res = await window.axios.get(`/api/v1/get_admin_url/${this.$route.params.id}`)
        if(res.data.url)
          this.sign_url = res.data.url+'&disable_domain_validation=true'
        
        console.log(res)
      this.loadContract = false
        
    },


  },
}
</script>
