var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("recaps.notification_description")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateRecapSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("recaps.default_recap_email_subject"),
                error: _vm.mailSubjectError,
              },
            },
            [
              _c("sw-input", {
                attrs: { invalid: _vm.$v.recaps.recap_mail_subject.$error },
                model: {
                  value: _vm.recaps.recap_mail_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.recaps, "recap_mail_subject", $$v)
                  },
                  expression: "recaps.recap_mail_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("recaps.default_recap_email_body"),
                error: _vm.mailBodyError,
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                on: {
                  input: function ($event) {
                    return _vm.test()
                  },
                },
                model: {
                  value: _vm.recaps.recap_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.recaps, "recap_mail_body", $$v)
                  },
                  expression: "recaps.recap_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }