<template>
  <div class="relative">
    <base-loader v-if="isRequestOnGoing" :show-bg-overlay="true" />
    <sw-card>
      <sw-tabs class="p-2">
        <!-- Documents -->
        <sw-tab-item :title="$t('documents.title')">
          <documents-tab :settings="settings"  />
        </sw-tab-item>

         <!-- Recaps -->
        <sw-tab-item :title="$tc('recaps.title',1)">
          <recaps-tab :settings="settings" />
        </sw-tab-item>

            
      </sw-tabs>
    </sw-card>
  </div>
</template>

<script>
import RecapsTab from './customization-tabs/RecapsTab'
import DocumentsTab from './customization-tabs/DocumentsTab'


import { mapActions,mapGetters } from 'vuex'

export default {
  data() {
    return {
      settings: {},
      closed : false,
      closeComercial: false,
      isRequestOnGoing: false,
    }
  },

  components: {
    RecapsTab,
    DocumentsTab,
  },

  created() {
    this.isRequestOnGoing = true
    this.fetchSettings()
    this.isRequestOnGoing = false
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('company', ['getSelectedCompany']),
    company(){
     return this.getSelectedCompany
    },
  },

  methods: {
    ...mapActions('company', ['fetchCompanySettings']),
    async fetchSettings() {
      
      let res = await this.fetchCompanySettings([
        'document_mail_body',
        'document_mail_subject',
        'recap_mail_body',
        'recap_mail_subject',
        'notification_x_day'
      ])

      this.settings = res.data
    },

  },
    
}
</script>
