<template>
  <div class="item-selector">
    <sw-select
      ref="baseSelect"
      v-model="userSelect"
      :options="users"
      :show-labels="false"
      :preserve-search="false"
      :placeholder="$t('customers.type_or_click')"
      label="name"
      class="multi-select-item"
      @close="checkUsers"
      @value="onTextChange"
      @select="(val) => $emit('select', val)"
      @remove="deselectUser"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      userSelect: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('users', ['users']),
  },

  created() {
    this.fetchUsers()
  },

  methods: {
    ...mapActions('users', ['fetchUsers']),
    async searchUsers(search) {
      this.loading = true

      await this.fetchUsers({ search })

      this.loading = false
    },
    onTextChange(val) {
      this.searchUsers(val)
    },
    checkUsers(val) {
      if (!this.users.length) {
        this.fetchUsers()
      }
    },
    deselectUser() {
      this.userSelect = null
      this.$emit('deselect')
    },
  },
}
</script>
