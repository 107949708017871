<template>
  <base-page>
    <dashboard-stats />
    <!-- <dashboard-calendar /> -->
    <!-- <dashboard-chart /> -->
    <dashboard-table />
  </base-page>
</template>

<script>
import DashboardStats from '../dashboard/DashboardStats'
// import DashboardChart from '../dashboard/DashboardChart'
import DashboardTable from '../dashboard/DashboardTable'
// import DashboardCalendar from '../dashboard/DashboardCalendar'


export default {
  components: {
    DashboardStats,
    // DashboardChart,
    DashboardTable,
    // DashboardCalendar
  },
}
</script>
