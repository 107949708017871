<template>
  <form action="" @submit.prevent="updatePreferencesData" class="relative">
    <base-loader v-if="isRequestOnGoing" :show-bg-overlay="true" />
    <sw-card variant="setting-card">
      <template slot="header">
        <h6 class="sw-section-title inline-flex">
          {{ $t('settings.menu_title.preferences') }} 
          <label  v-if="closed" title="Mettre à niveau pour activer ces paramètres" class="inline-flex items-center">
            <lock-closed-icon               
              @click="goToSubscribe()"
              class="ml-2 text-gray-600 cursor-pointer"
            />
          </label>
        </h6>

        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
          {{ $t('settings.preferences.general_settings') }}
        </p>
      </template>

      <div class="grid gap-6 sm:grid-col-1 md:grid-cols-2">
        <!-- <sw-input-group
          :label="$tc('settings.preferences.currency')"
          :error="currencyError"
          required
        >
          <sw-select
            v-model="formData.currency"
            :options="currencies"
            :custom-label="currencyNameWithCode"
            :class="{ error: $v.formData.currency.$error }"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :disabled="closed"
            :placeholder="$tc('settings.currencies.select_currency')"
            class="mt-2"
            label="name"
            track-by="id"
          />
        </sw-input-group> -->

        <sw-input-group
          :label="$tc('settings.preferences.default_language')"
          :error="languageError"
          required
        >
          <sw-select
            :disabled="true"
            v-model="formData.language"
            :options="languages"
            :class="{ error: $v.formData.language.$error }"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :placeholder="$tc('settings.preferences.select_language')"
            class="mt-2"
            label="name"
            track-by="code"
          />
        </sw-input-group>

        <sw-input-group
          :label="$tc('settings.preferences.time_zone')"
          :error="timeZoneError"
          required
        >
          <sw-select
            v-model="formData.timeZone"
            :options="timeZones"
            :class="{ error: $v.formData.timeZone.$error }"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :disabled="closed"
            :placeholder="$tc('settings.preferences.select_time_zone')"
            class="mt-2"
            label="key"
            track-by="key"
          />
        </sw-input-group>

        <sw-input-group
          :label="$tc('settings.preferences.date_format')"
          :error="dateFormatError"
          required
        >
          <sw-select
            v-model="formData.dateFormat"
            :options="dateFormats"
            :class="{ error: $v.formData.dateFormat.$error }"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :disabled="closed"
            :placeholder="$tc('settings.preferences.select_date_format')"
            class="mt-2"
            label="display_date"
          />
        </sw-input-group>

        <sw-input-group
          :label="$tc('settings.preferences.fiscal_year')"
          :error="fiscalYearError"
          class="mb-2"
          required
        >
          <sw-select
            v-model="formData.fiscalYear"
            :options="fiscalYears"
            :class="{ error: $v.formData.fiscalYear.$error }"
            :show-labels="false"
            :allow-empty="false"
            :searchable="true"
            :disabled="closed"
            :placeholder="$tc('settings.preferences.select_financial_year')"
            label="key"
            track-by="value"
          />
        </sw-input-group>
      
         <sw-input-group 
          :label="$tc('general.color')"
          class="my-2"
        >
            <v-swatches
              v-model="formData.color"
              :swatches="swatches"
              row-length="4"
              show-border
              popover-x="right"
              popover-y="top"
            ></v-swatches>
          </sw-input-group>
      </div>

      <sw-button
        class="mt-6"
        variant="primary"
        type="submit"
        :disabled="isLoading"
        :loading="isLoading"
        v-if="!closed"
      >
        <save-icon v-if="!isLoading" class="mr-2 -ml-1" />
        {{ $tc('settings.company_info.save') }}
      </sw-button>
    </sw-card>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const { required } = require('vuelidate/lib/validators')
import {LockClosedIcon} from '@vue-hero-icons/solid'
import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"

export default {
  components: {
    VSwatches,
   LockClosedIcon
  },

  data() {
    return {
      isLoading: false,
      formData: {
        language: null,
        // currency: null,
        timeZone: null,
        dateFormat: null,
        fiscalYear: null,
        color: '',
      },
      isRequestOnGoing: false,
      discount_per_item: null,
      closed : false,
      
      swatches: ['#6b7280', '#ef4444', '#f97316', '#eab308', '#84cc16', '#22c55e', '#06b6d4','#3b82f6','#14b8a6','#d946ef','#ec4899','']
    }
  },

  validations: {
    formData: {
      language: {
        required,
      },
      dateFormat: {
        required,
      },
      timeZone: {
        required,
      },
      fiscalYear: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters([
      // 'currencies',
      'timeZones',
      'dateFormats',
      'fiscalYears',
      'languages',
    ]),

    ...mapGetters('company', ['defaultFiscalYear', 'defaultTimeZone']),
    ...mapGetters('company', ['getSelectedCompany']),
    company(){
     return this.getSelectedCompany
    },

   
    // currencyError() {
    //   if (!this.$v.formData.currency.$error) {
    //     return ''
    //   }
    //   if (!this.$v.formData.currency.required) {
    //     return this.$tc('validation.required')
    //   }
    // },

    languageError() {
      if (!this.$v.formData.language.$error) {
        return ''
      }
      if (!this.$v.formData.language.required) {
        return this.$tc('validation.required')
      }
    },

    timeZoneError() {
      if (!this.$v.formData.timeZone.$error) {
        return ''
      }
      if (!this.$v.formData.timeZone.required) {
        return this.$tc('validation.required')
      }
    },

    fiscalYearError() {
      if (!this.$v.formData.fiscalYear.$error) {
        return ''
      }
      if (!this.$v.formData.fiscalYear.required) {
        return this.$tc('settings.company_info.errors.required')
      }
    },

    dateFormatError() {
      if (!this.$v.formData.dateFormat.$error) {
        return ''
      }

      if (!this.$v.formData.dateFormat.required) {
        return this.$tc('validation.required')
      }
    },
  },

  async mounted() {
    this.setInitialData()
  },

  methods: {
    ...mapActions('company', [
      // 'setDefaultCurrency',
      'fetchCompanySettings',
      'updateCompanySettings',
    ]),

    ...mapActions([
      'fetchLanguages',
      'fetchFiscalYears',
      'fetchDateFormats',
      'fetchTimeZones',
    ]),

    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`
    },

    goToSubscribe(){
      this.$router.push({ name: 'subscribe.create' })
    },

    async setInitialData() {
      this.isRequestOnGoing = true

      await this.fetchDateFormats()
      await this.fetchLanguages()
      await this.fetchFiscalYears()
      await this.fetchTimeZones()

      let response = await this.fetchCompanySettings([
        'color',
        'time_zone',
        'language',
        'fiscal_year',
        'carbon_date_format',
        'moment_date_format',
        // 'discount_per_item',
      ])

      if (response.data) {
        // response.data.discount_per_item === 'YES'
        //   ? (this.discount_per_item = true)
        //   : (this.discount_per_item = false)

        // this.formData.currency = this.currencies.find(
        //   (currency) => currency.id == response.data.currency
        // )

        this.formData.language = this.languages.find(
          (language) => language.code == response.data.language
        )

        this.formData.color = response.data.color == 'none' ? '' : response.data.color

        this.formData.timeZone = this.timeZones.find(
          (timeZone) => timeZone.value == this.defaultTimeZone
        )

        this.formData.fiscalYear = this.fiscalYears.find(
          (fiscalYear) => fiscalYear.value == this.defaultFiscalYear
        )

        this.formData.dateFormat = this.dateFormats.find(
          (dateFormat) =>
            dateFormat.carbon_format_value == response.data.carbon_date_format
        )
      }

      this.isRequestOnGoing = false
    },

    async updatePreferencesData() {
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      let data = {
        settings: {
          time_zone: this.formData.timeZone.value,
          fiscal_year: this.formData.fiscalYear.value,
          language: this.formData.language.code,
          color: this.formData.color ?  this.formData.color : 'none',
          carbon_date_format: this.formData.dateFormat.carbon_format_value,
          moment_date_format: this.formData.dateFormat.moment_format_value,
        },
      }
      let response = await this.updateCompanySettings(data)
      if (response.data.success) {
        this.isLoading = false
        // window.i18n.locale = this.formData.language.code
        // this.setDefaultCurrency(this.formData.currency)
        window.toastr['success'](
          this.$t('settings.preferences.updated_message')
        )
        this.$router.go()
      }
      window.toastr['error'](response.data.error)
      return true
    },

  },
}
</script>
