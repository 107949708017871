<template>
 <div class="grid grid-cols-12">
    <div class="col-span-12 md:col-span-12">
      <form id="registerForm"  @submit.prevent="validateBeforeSubmit">
      <!-- {{ csrf_field() }} -->
        <div v-show="!loadPackInfo" class="grid gap-2 sm:grid-col-1 md:grid-cols-2">
          <sw-input-group
            :label="$t('login.name')"
            placeholder=""
            :error="nameError"
            class="mb-4"
            required
          >
            <sw-input
            
              v-model="registerData.name"
              focus
              :invalid="$v.registerData.name.$error"

              type="text"
              name="name"
              @input="$v.registerData.name.$touch()"
            />
          </sw-input-group>
          <sw-input-group
            :label="$t('login.email')"
            :error="emailError"
            class="mb-4"
            required
          >
            <sw-input
            
              placeholder=""
              v-model="registerData.email"
              :invalid="$v.registerData.email.$error"
              
              type="email"
              name="email"
              @input="$v.registerData.email.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('login.password')"
            :error="passwordError"
            class="mb-4"
            required
          >
            <sw-input
              v-model="registerData.password"
              :invalid="$v.registerData.password.$error"
              :type="getInputType"
              name="password"
              @input="$v.registerData.password.$touch()"
            >
              <template v-slot:rightIcon>
                <eye-off-icon
                  v-if="!isShowPassword"
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="isShowPassword = !isShowPassword"
                />
                <eye-icon
                  v-else
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="isShowPassword = !isShowPassword"
                />
              </template>
            </sw-input>
          </sw-input-group>

          <sw-input-group
            :label="$t('login.password_confirmation')"
            :error="passwordConfirmError"
            class="mb-4"
            required
          >
            <sw-input
              v-model="registerData.password_confirmation"
              :type="getInputType2"
              :invalid="$v.registerData.password_confirmation.$error"
              name="password_confirmation"
              @input="$v.registerData.password_confirmation.$touch()"
            >
              <template v-slot:rightIcon>
                <eye-off-icon
                  v-if="!isShowPasswordConfirm"
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="isShowPasswordConfirm = !isShowPasswordConfirm"
                />
                <eye-icon
                  v-else
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="isShowPasswordConfirm = !isShowPasswordConfirm"
                />
              </template>
            </sw-input>
          </sw-input-group>

          <sw-input-group
            :label="$t('settings.company_info.company_name')"
            :error="companyError"
            class="mb-4"
            required
          >
            <sw-input
              :invalid="$v.registerData.company.$error"
              placeholder="Nom de l'entreprise"
              v-model="registerData.company"
              type="text"
              name="company"
              @input="$v.registerData.company.$touch()"
            />
          </sw-input-group>
        </div>
        <div v-show="loadPackInfo" class="flex justify-center grid grid-cols-2 gap-2">
          <div  class="form-check form-check-inline">
              <sw-input-group>
                    <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                       name="quantity" 
                        type="radio"
                        id="inlineRadio2" 
                        v-model="registerData.quantity"
                        checked
                        :value="{'pack': 'basic', 'price': 0}"
                    />
                     <label class="form-check-label inline-block text-gray-800 " for="inlineRadio2">
                      1 mois d'essai  <span class="text-primary-400" v-html="'(Gratuit)'" /><br>
                       <span class="text-xs text-green-500"> 30 jours </span>
                    </label>
                </sw-input-group>
          </div>
          <div   class="form-check form-check-inline">
            <sw-input-group>
                <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2" 
                    name="quantity" 
                    type="radio"
                    v-model="registerData.quantity"
                    id="inlineRadio3" 
                    :value="{'pack': 'basic', 'price':price_three_month_basic}"
                />
                  <label class="form-check-label inline-block text-gray-800 " for="inlineRadio3">
                    3 mois Basic <span class="text-primary-400" v-html="'('+$utils.formatMoney(price_three_month_basic, currency)+')'" /><br>
                    <span class="text-xs text-green-500"> 90 jours </span>
                </label>
            </sw-input-group>
        </div>
        <div class="form-check form-check-inline">
          <sw-input-group>
            <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                name="quantity" 
                id="inlineRadio4" 
                type="radio"
                v-model="registerData.quantity"
                :value="{'pack': 'silver', 'price':price_three_month_silver}"
            />
              <label class="form-check-label inline-block text-gray-800 " for="inlineRadio4">
                3 mois Silver <span class="text-primary-400" v-html="'('+$utils.formatMoney(price_three_month_silver, currency)+')'" /> <br>
                <span class="text-xs text-green-500"> 90 jours </span>
            </label>
          </sw-input-group>
        </div>
        <div class="form-check form-check-inline">
          <sw-input-group>
            <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                name="quantity" 
                id="inlineRadio5" 
                type="radio"
                v-model="registerData.quantity"
                :value="{'pack': 'gold', 'price': price_three_month_gold}"
            />
              <label class="form-check-label inline-block text-gray-800 " for="inlineRadio5">
                3 mois Gold <span class="text-primary-400" v-html="'('+$utils.formatMoney(price_three_month_gold, currency)+')'" /> <br>
                <span class="text-xs text-green-500"> 90 jours </span>
            </label>
          </sw-input-group>
        </div>
        </div>
        <div v-show="!loadPackInfo" class="grid gap-2 sm:grid-col-2 md:grid-cols-2">
          <sw-button 
            @click="showPackInfo()"
            variant="primary-outline"
            class="btn btn-full"
            type="button"
          >
            {{$t('login.next') }}
          </sw-button>
        </div>
        <div v-if="loadPackInfo && ($v.registerData.name.$error 
          || $v.registerData.email.$error
          || $v.registerData.password.$error || $v.registerData.password_confirmation.$error
          || $v.registerData.company.$error)" 
          class="text-danger mt-6"
        >
          Veuillez saisir vos coordonnées dans la page précédente !
        </div>

        <div v-if="loadPackInfo && $v.registerData.quantity.$error " class="text-danger mt-2">
          Veuillez choisir un pack de début !
        </div>
        <div  v-show="loadPackInfo" class="grid gap-2 mt-8 grid-col-2 md:grid-cols-2">
          <sw-button 
            @click="notShowPackInfo()"
            variant="primary-outline"
            class="btn btn-full"
            type="button"
          >
            {{$t('login.previous') }}
          </sw-button>
          <sw-button 
            :loading="isLoading"
            :disabled="isLoading"
            type="button"
            @click="validateBeforeSubmit()"
            variant="primary"
            class="btn btn-login btn-full"
          >
            {{$t('login.register') }}
          </sw-button>
        </div>
        
      </form>
    </div>
  </div>
</template>

<script type="text/babel">

import { mapActions,mapGetters } from 'vuex'
import { EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline'
import IconFacebook from '../../components/icon/facebook'
import IconTwitter from '../../components/icon/twitter'
import IconGoogle from '../../components/icon/google'
const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  components: {
    IconFacebook,
    IconTwitter,
    IconGoogle,
    EyeIcon,
    EyeOffIcon,
  },
  data() {
    return {
      registerData :{
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        company : '',
        quantity: null,

      },

      defaulBasicPrice : 0,
      defaultSilverPrice : 0,
      defaultGoldPrice : 0,

      loadPackInfo : false,
      submitted: false,
      isLoading: false,
      isShowPassword: false,
      isShowPasswordConfirm : false,
      languages : [],
      language : null,
    }
  },

  validations :{
    registerData: {
      name :{
        required
      },
      email : {
        email,
        required
      },
      password: {
          required,
          minLength: minLength(8),
        },
      password_confirmation: {
          required,
          minLength: minLength(8),
        },
      quantity : {
          required
        },

      company :{
        required
      }
    },
   
  },
  computed : {
    ...mapGetters('company', ['getSelectedCompany','defaultCurrency','defaultCurrencyForInput']),

    getInputType() {
      if (this.isShowPassword) {
        return 'text'
      }
      return 'password'
    },
    currency() {
        return this.defaultCurrency
    },

    price_three_month_basic(){
      return (this.defaulBasicPrice * 90) 
    },
    price_three_month_silver(){
      return (this.defaultSilverPrice * 90) 
    },
    price_three_month_gold(){
      return (this.defaultGoldPrice * 90) 
    },

    getInputType2() {
      if ( this.isShowPasswordConfirm) {
        return 'text'
      }
      return 'password'
    },

    emailError() {
      if (!this.$v.registerData.email.$error) {
        return ''
      }
      if (!this.$v.registerData.email.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.registerData.email.email) {
        return this.$tc('validation.email_incorrect')
      }
    },

    nameError() {
      if (!this.$v.registerData.name.$error) {
        return ''
      }
      if (!this.$v.registerData.name.required) {
        return this.$tc('validation.required')
      }
    
    },

    companyError() {
      if (!this.$v.registerData.company.$error) {
        return ''
      }
      if (!this.$v.registerData.company.required) {
        return this.$tc('validation.required')
      }
    
    },

    passwordError() {
      if (!this.$v.registerData.password.$error) {
        return ''
      }
      if (!this.$v.registerData.password.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.registerData.password.minLength) {
        return this.$tc(
          'validation.password_min_length',
          this.$v.registerData.password.$params.minLength.min,
          { count: this.$v.registerData.password.$params.minLength.min }
        )
      }
    },

    passwordConfirmError() {
      if (!this.$v.registerData.password_confirmation.$error) {
        return ''
      }
      if (!this.$v.registerData.password_confirmation.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.registerData.password_confirmation.minLength) {
        return this.$tc(
          'validation.password_min_length',
          this.$v.registerData.password_confirmation.$params.minLength.min,
          { count: this.$v.registerData.password_confirmation.$params.minLength.min }
        )
      }
    },

    languageError(){
      if (!this.$v.language.$error) {
        return ''
      }
      if (!this.$v.language.required) {
        return this.$tc('validation.required')
      }
    },
  
  },

  created(){
    console.log(this.registerData.quantity)
  },
  methods: {
    
    async getLanguages(){
      let res = await window.axios.get('/languages')
     // console.log(res.data.languages)
      if (res)
        this.languages = res.data.languages
    },
    async validateBeforeSubmit(e) {
     
      // console.log(this.registerData)
      axios.defaults.withCredentials = true
    
      this.$v.registerData.$touch()
      if (this.$v.registerData.$invalid) {
        return true
      }

      this.isLoading = true

      try {
      //  console.log(this.registerData)
        await window.axios.post('/user/register',this.registerData)
        //console.log(res)
      //  window.toastr['success']('Abonnement créé avec succés !' )
        this.$router.push('/email/verify')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }

    },

    showPackInfo(){
      this.loadPackInfo = true
    },
    notShowPackInfo(){
      this.loadPackInfo = false
    },

    async loadData(){
      let res1 = await this.fetchPacks({limit : 'all'})
      console.log(res1)
      this.prices = res1.data.packs.data
      let basic = this.prices.find(element => (element.name.toLowerCase() == 'basic' ));
      this.defaulBasicPrice = basic.daily_rate

      let silver = this.prices.find(element => (element.name.toLowerCase() == 'silver' ));
      this.defaultSilverPrice = silver.daily_rate

      let gold = this.prices.find(element => (element.name.toLowerCase() == 'gold' ));
      this.defaultGoldPrice = gold.daily_rate
    }
  },
}
</script>
