<template>
<form @submit.prevent="submitData">
    <div class="px-8 py-8 lg:grid grid-cols-3 bg-white rounded sm:p-6">
        <div class="col-span-3 text-lg font-semibold">Doc {{index +1}} : {{documentType.name}}</div>
            <sw-input-group  
                :label="$t('estimates.file')"
                required
                class="mt-2"
                :error="attachmentError"
            >
                <div
                :id="'receipt-box'+index"
                class="relative flex items-center justify-center h-24 p-6 bg-transparent border-2 border-gray-300 border-dashed rounded-md image-upload-box"
                >
                <img
                    v-if="newDocument.attachment"
                    :src="newDocument.attachment"
                    class="absolute opacity-100 preview-logo"
                    style="max-height: 80%; animation: fadeIn 2s ease"
                />
                <div v-else class="flex flex-col items-center">
                    <cloud-upload-icon
                    class="h-5 mb-2 text-xl leading-6 text-gray-400"
                    />
                    <p class="text-xs leading-4 text-center text-gray-400">
                    Faites glisser un fichier ici ou 
                    <span id="pick-avatar" class="cursor-pointer text-primary-500" >parcourez</span>
                    pour choisir un fichier
                    </p>
                </div>
                </div>
            
                <sw-avatar
                    :preview-avatar="newDocument.attachment"
                    :enable-cropper="false"
                    :trigger="'#receipt-box'+index"
                    accept=".pdf"
                    @changed="onchange"
                >
                <template v-slot:icon>
                    <cloud-upload-icon
                    class="h-5 mb-2 text-xl leading-6 text-gray-400"
                    />
                </template>
                </sw-avatar>
            
            </sw-input-group>  
            <sw-input-group  
                :label="$t('documents.date')"
                required
                :error="dateError"
                class="mt-2 lg:ml-2"
            >
                <base-date-picker
                    v-model="newDocument.date_delivrance"
                    :calendar-button="true"
                    calendar-button-icon="calendar"
                    :invalid="$v.newDocument.date_delivrance.$error"
                    class="mt-2"
                />

            </sw-input-group>   
                <div v-if="isEdit || newDocument.id" class=" flex justify-centerlg:m-auto mt-2">
                    <router-link :to="`documents/${newDocument.id}/view`">
                        <eye-icon class="h-5 mr-3 text-gray-600" />
                    </router-link>
                    <router-link :to="`documents/${newDocument.id}/edit`">
                        <pencil-icon class="h-5 mr-3 text-gray-600" />
                    </router-link>
                    <router-link  to="#">
                        <trash-icon class="h-5 mr-3 text-gray-600" />
                    </router-link>
                </div>  
                <div v-else class=" mt-2 lg:m-auto">
                     <sw-button
                        type="submit"
                        size="lg"
                        variant="primary"
                        :loading="isLoading"
                        :disabled="isLoading"
                    >
                        <cloud-upload-icon class="w-6 h-6 mr-1 -ml-2"  />
                        {{ $t('documents.upload') }}
                    </sw-button>
                </div>    
            </div>
</form>
</template>

<script>
import {mapActions,mapGetters} from "vuex"
import moment from 'moment'
import {CloudUploadIcon, TrashIcon, PencilIcon,EyeIcon,} from '@vue-hero-icons/solid'
const {
  required,
} = require('vuelidate/lib/validators')
export default {
    name :'DocumentRow',
    components :{
        CloudUploadIcon,  TrashIcon, PencilIcon,EyeIcon
    },
  props: {
      index : {
          type :Number,
          default : 1
      },
      documentType : {
          type :Object,
          required :true
      },
      isEdit : {
          type : Boolean,
          default :false
      },
      user : {
          type : Object,
          default :null
      },
      document : {
          type : Object,
          default :null
      }
  },
  
  data () {
    return {
        isLoading: false,
        newDocument : {
          date_delivrance :null,
          type_document_id : null,
          attachment : null,
          fileObject : null,
        },
        isFileAvailable : false,
    }
  },
  validations() {
    return {
        newDocument : {
            date_delivrance : {
                required
            },
            attachment : {
                required
            }
        }
    }
  },
  created () {
      console.log(this.document)
      this.newDocument.type_document_id = this.documentType.id
      this.loadDocument()
  },
  computed :{
    dateError() {
      if (!this.$v.newDocument.date_delivrance.$error) {
        return ''
      }

      if (!this.$v.newDocument.date_delivrance.required) {
        return this.$t('validation.required')
      }
    },
    attachmentError() {
         if (!this.$v.newDocument.attachment.$error) {
        return ''
      }

      if (!this.$v.newDocument.attachment.required) {
        return this.$t('validation.required')
      }
    }
  },
  methods: {
    ...mapActions('document',['addDocument','fetchMyDocuments','getDocumentFile']),

    loadDocument() {
        console.log(this.user.id+' '+ this.documentType.id)
         Promise.all([
            this.fetchMyDocuments({type_document_id : this.documentType.id}),
            // this.getDocumentFile(this.newDocument.id)
         ])
         .then(async ([res1]) => {
            if(res1.data.documents) {
                this.newDocument = res1.data.documents[0]
                this.newDocument.attachment = res1.data.documents[0].file
                this.newDocument.date_delivrance = moment(
                    res1.data.documents[0].date_delivrance
                ).toString()
            }
            console.log(this.newDocument)
            //  if (res2.data.error) {
            //     this.isFileAvailable = false
            //     return true
            // }

            // this.isFileAvailable = true
            // this.newDocument.attachment = res2.data.image

         })
         .catch((error) => {
          console.log(error)
        })
       
    },
    onchange(data) {
      this.newDocument.attachment = data.image
      this.newDocument.fileObject = data.file

      console.log(this.newDocument)
    },
     async getFile() {
      let res = await this.getDocumentFile(this.newDocument.id)
        console.log(res)
      if (res.data.error) {
        this.isFileAvailable = false
        return true
      }

      this.isFileAvailable = true
      this.newDocument.attachment = res.data.image
    },

    async submitData(){
        console.log(this.newDocument)
        this.$v.newDocument.$touch()
        if (this.$v.$invalid) {
            return true
        }
        let data = new FormData()

        if (this.newDocument.fileObject) {
            data.append('attachment', this.newDocument.fileObject)
            data.append('type_document_id', this.newDocument.type_document_id)
            data.append('date_delivrance', this.newDocument.date_delivrance)
            data.append('file', this.newDocument.fileObject.name)

        }
        
        this.isLoading = true
        console.log('upload commencé')
        let response
        try {
            response = await this.addDocument(data)

            console.log('upload terminée')
            this.isLoading = false

            if (response.data.success) {
                
                console.log('upload success')

                window.toastr['success'](this.$t('documents.upload_successfully'))
                this.newDocument.id = response.data.document.id

                return true
            }
            else {
                console.log('upload error')

                window.toastr['error'](response.data.error)
                this.resetFormData()
                this.isLoading = false
            }
            
        }catch(error) {
            console.log(error)
            this.isLoading = false
            this.resetFormData()
            window.toastr['error'](error)
        }
        
    },
    resetFormData() {
      this.newDocument.attachment = null
      this.newDocument.fileObject= null
      this.isLoading= false
    },
  },
    
}
</script>