<template>
<div>
  <div  v-if="user.role != 'user'" class="grid gap-6 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8">
    <!-- users -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4"
      to="/admin/users"
     
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
          {{getUsers}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $t('users.title') }}
        </span>
      </div>
      <div class="flex items-center">
        <contact-icon class="w-10 h-10 text-primary-500 xl:w-10 xl:h-10" />
      </div>
    </router-link>

    <!-- type documents -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4"
      to="/admin/settings/document-types"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
         {{getTypeDocuments}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $t('document_types.title') }}
        </span>
      </div>
      <div class="flex items-center">
        <documents-icon class="w-10 h-10 text-primary-500 xl:w-10 xl:h-10" />
      </div>
    </router-link>

    <!-- Documents -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4"
      to="/admin/documents"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
          {{getDocuments}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $t('documents.title') }}
        </span>
      </div>
      <div class="flex items-center">
        <estimate-icon class="w-10 h-10 xl:w-10 text-primary-500 xl:h-10" />
      </div>
    </router-link>

    <!-- recaps -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4"
      to="/admin/recaps"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
          {{getRecaps}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $tc('recaps.title',1) }}
        </span>
      </div>
      <div class="flex items-center">
        <calendar-icon class="w-10 h-10 text-primary-500 xl:w-10 xl:h-10" />
      </div>
    </router-link>  
  </div>
  <div v-else class="grid gap-6 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8">
  
    <!-- type documents -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4"
      to="#"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
         {{getTypeDocuments}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $t('document_types.title') }}
        </span>
      </div>
      <div class="flex items-center">
        <documents-icon class="w-10 h-10 text-primary-500 xl:w-10 xl:h-10" />
      </div>
    </router-link>

    <!-- Documents -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4"
      to="/admin/documents"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
          {{getDocuments}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $t('documents.title') }}
        </span>
      </div>
      <div class="flex items-center">
        <estimate-icon class="w-10 h-10 xl:w-10 text-primary-500 xl:h-10" />
      </div>
    </router-link>

    <!-- recaps -->
    <router-link
      slot="item-title"
      class="relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4"
      to="/admin/recaps"
    >
      <div>
        <span
          v-if="getDashboardDataLoaded"
          class="text-xl font-semibold leading-tight text-black xl:text-3xl"
        >
          {{getRecaps}}
        </span>
        <span class="block mt-1 text-sm leading-tight text-gray-500 xl:text-lg">
          {{ $tc('recaps.title',1) }}
        </span>
      </div>
      <div class="flex items-center">
        <calendar-icon class="w-10 h-10 text-primary-500 xl:w-10 xl:h-10" />
      </div>
    </router-link>  
  </div>
</div>
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DollarIcon from '../../components/icon/DollarIcon'
import ContactIcon from '../../components/icon/ContactIcon'
import InvoiceIcon from '../../components/icon/InvoiceIcon'
import EstimateIcon from '../../components/icon/EstimateIcon'
import CalendarIcon from '../../components/icon/CalenderIcon'
import DocumentsIcon from '../../components/icon/DocumentsIcon'
import VCalendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    DollarIcon,
    ContactIcon,
    InvoiceIcon,
    EstimateIcon,
    VCalendar,
    DatePicker,
    DocumentsIcon,
    CalendarIcon,
  },
  data() {
    return {
      ...this.$store.state.dashboard,
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'currentUser',
    }),
    ...mapGetters('dashboard', [
      'getUsers',
      'getDocuments',
      'getTypeDocuments',
      'getRecaps',
      'getDashboardDataLoaded',
    ]),
  },

  created(){
    this.loadData()
  },

  methods:{
    ...mapActions('dashboard', ['loadData']),

    async loadData(params) {
      await this.$store.dispatch('dashboard/loadData', params)
      this.isLoaded = true
    },

   
  }

}
</script>
