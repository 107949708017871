export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const ADD_DOCUMENT = 'ADD_DOCUMENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_MULTIPLE_DOCUMENTS = 'DELETE_MULTIPLE_DOCUMENTS'
export const SET_SELECTED_DOCUMENTS = 'SET_SELECTED_DOCUMENTS'
export const SET_TOTAL_DOCUMENTS = 'SET_TOTAL_DOCUMENTS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_DOCUMENTS = 'RESET_SELECTED_DOCUMENTS'
export const UPDATE_DOCUMENT_STATUS = 'UPDATE_DOCUMENT_STATUS'
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
