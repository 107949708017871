export const SET_RECAPS = 'SET_RECAPS'
export const ADD_RECAP = 'ADD_RECAP'
export const UPDATE_RECAP = 'UPDATE_RECAP'
export const DELETE_RECAP = 'DELETE_RECAP'
export const DELETE_MULTIPLE_RECAPS = 'DELETE_MULTIPLE_RECAPS'
export const SET_SELECTED_RECAPS = 'SET_SELECTED_RECAPS'
export const SET_TOTAL_RECAPS = 'SET_TOTAL_RECAPS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_RECAPS = 'RESET_SELECTED_RECAPS'
export const UPDATE_RECAP_STATUS = 'UPDATE_RECAP_STATUS'
export const VIEW_RECAP = 'VIEW_RECAP'
