var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentUser.role != "user"
    ? _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("documents.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("documents.document", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalDocuments,
                          expression: "totalDocuments",
                        },
                      ],
                      attrs: { size: "lg", variant: "primary-outline" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _vm.currentUser.role != "user"
                ? _c(
                    "sw-filter-wrapper",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showFilters,
                          expression: "showFilters",
                        },
                      ],
                      staticClass: "relative grid grid-flow-col grid-rows",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$tc("documents.user", 1) },
                        },
                        [
                          _c("base-customer-select", {
                            ref: "userSelect",
                            on: {
                              select: _vm.onSelectUser,
                              deselect: _vm.clearUserSearch,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2 xl:mx-8",
                          attrs: { label: _vm.$t("documents.status") },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.status,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": false,
                              label: "name",
                              placeholder: _vm.$t("general.select_a_status"),
                            },
                            on: {
                              select: _vm.setActiveTab,
                              remove: function ($event) {
                                return _vm.clearStatusSearch()
                              },
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            label: _vm.$t("documents.date"),
                            color: "black-light",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            model: {
                              value: _vm.filters.date_delivrance,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "date_delivrance", $$v)
                              },
                              expression: "filters.date_delivrance",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "absolute text-sm leading-snug text-black cursor-pointer",
                          staticStyle: { top: "10px", right: "15px" },
                          on: { click: _vm.clearFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("documents.no_documents"),
                description: _vm.$t("documents.list_of_documents"),
              },
            },
            [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-dropdown",
            { attrs: { right: "", split: "", text: "Split Menu" } },
            [
              _c("sw-dropdown-item", [_vm._v("Item 1")]),
              _vm._v(" "),
              _c("sw-dropdown-item", [_vm._v("Item 2")]),
              _vm._v(" "),
              _c("sw-dropdown-divider"),
              _vm._v(" "),
              _c("sw-dropdown-item", [_vm._v("Item 3")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-sm", staticStyle: { top: "50px" } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.documents.length))]),
                      _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                      _c("b", [_vm._v(_vm._s(_vm.totalDocuments))]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.documents && _vm.documents.length,
                    expression: "documents && documents.length",
                  },
                ],
                staticClass:
                  "absolute z-10 items-center pl-4 mt-2 select-none md:mt-6",
              }),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c(
                    "sw-table-column",
                    {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "no-click",
                      },
                    },
                    [_c("span")]
                  ),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      "sort-as": "name",
                      "filter-as": "name",
                      label: _vm.$t("documents.partenaire"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("documents.partenaire"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: `documents/${row.user_id}/view/${row.id}`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(row.name) +
                                      "\n        "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      924325480
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("documents.nb_draft"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.nb_draft"))
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(row.partenaire.totalDraftDocuments) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2625736131
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("documents.nb_accepted"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.nb_accepted"))
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.partenaire.totalAcceptedDocuments
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3431576259
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("documents.nb_rejected"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.nb_rejected"))
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.partenaire.totalRejectedDocuments
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3909167171
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("documents.nb_expired"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.nb_expired"))
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(row.partenaire.totalExpiredDocuments) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      809488515
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("documents.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                { attrs: { containerClass: "w-56" } },
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        "tag-name": "router-link",
                                        to: {
                                          path: `documents/${row.user_id}/view/${row.id}`,
                                        },
                                      },
                                    },
                                    [
                                      _c("eye-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.view")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2489910241
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("documents.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("documents.document", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchUserData,
                "table-class": "table",
              },
            },
            [
              _c(
                "sw-table-column",
                {
                  attrs: {
                    sortable: false,
                    filterable: false,
                    "cell-class": "no-click",
                  },
                },
                [_c("div", { staticClass: "flex items-center" })]
              ),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  "sort-as": "name",
                  label: _vm.$t("documents.type"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("documents.type")))]),
                        _vm._v(" "),
                        row.documents.length > 0
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: {
                                    path: `documents/${row.documents[0].id}/view`,
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(row.name)),
                                ]),
                              ]
                            )
                          : _c("span", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(row.name)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  "sort-as": "validity",
                  label: _vm.$t("document_types.validity"),
                  show: "validity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("document_types.validity"))),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(
                              row.validity != -1 ? row.validity : "Toujours"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$tc("documents.status", 1),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("documents.status", 1))),
                        ]),
                        _vm._v(" "),
                        row.documents.length > 0
                          ? _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.documents[0].status
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.documents[0].status
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$utils.getStatusTranslation(
                                        row.documents[0].status
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, label: _vm.$t("documents.date") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("documents.date")))]),
                        _vm._v(
                          "            \n          " +
                            _vm._s(
                              row.documents.length > 0
                                ? row.documents[0].formattedDateDelivrance
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("documents.days_left"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("documents.days_left"))),
                        ]),
                        _vm._v(
                          "            \n          " +
                            _vm._s(
                              row.documents.length > 0 && row.validity != -1
                                ? _vm.calcDaysLeft(row)
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("general.actions"),
                  "header-class": "text-center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("general.actions")))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            !row.documents[0]
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.addDocument(row.name, row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("plus-circle-icon", {
                                      staticClass: "h-5 text-gray-600",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.documents[0]
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.editDocument(
                                          row.name,
                                          row.documents[0].id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.documents[0]
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDocument(
                                          row.documents[0].id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 text-gray-600",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.documents[0]
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: {
                                        path: `documents/${row.documents[0].id}/view`,
                                      },
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", [[_c("span")]], 2),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }