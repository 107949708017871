import Vue from 'vue'

import BaseModal from './modal/BaseModal.vue'
import SwBaseModal from './base-modal/SwBaseModal.vue'
import BaseLoader from './BaseLoader.vue'
import BaseCustomerSelect from './BaseCustomerSelect.vue'
import BaseEstimateSelect from './BaseEstimateSelect.vue'


import BaseCustomInput from './BaseCustomInput.vue'

import CustomerSelectPopup from './popup/CustomerSelectPopup.vue'
import TaxSelectPopup from './popup/TaxSelectPopup.vue'
import NoteSelectPopup from './popup/NoteSelectPopup.vue'

import BaseDatePicker from '../base/BaseDatePicker.vue'
import BaseTimePicker from './BaseTimePicker.vue'
import BasePage from './BasePage.vue'

import GlobalSearch from '../GlobalSearch.vue'

import DotIcon from '../../components/icon/DotIcon.vue'
import SaveIcon from '../../components/icon/SaveIcon.vue'
import GreenIcon from '../../components/icon/GreenIcon.vue'
import RedIcon from '../../components/icon/RedIcon.vue'
import WhatsappIcon from '../../components/icon/WhatsApp'
import ViberIcon from '../../components/icon/ViberIcon'
import PhoneIcon from '../../components/icon/PhoneIcon'
import MailIcon from '../../components/icon/MailIcon'
import MapsIcon from '../../components/icon/MapsIcon'

import SwSelect from '@bytefury/spacewind/src/components/sw-select'

import BaseCheckbox from './base-checkbox/BaseCheckbox.vue'
import FleshLeft from '../../components/icon/FleshLeft.vue'
import FleshRight from '../../components/icon/FleshRight.vue'

import GoogleMap from '../../components/maps/AddGoogleMap'


Vue.component('base-modal', BaseModal)
Vue.component('sw-base-modal', SwBaseModal)
Vue.component('global-search', GlobalSearch)

Vue.component('base-page', BasePage)

Vue.component('base-date-picker', BaseDatePicker)

Vue.component('base-loader', BaseLoader)
Vue.component('sw-select', SwSelect)
Vue.component('base-checkbox', BaseCheckbox)

Vue.component('base-customer-select', BaseCustomerSelect)
Vue.component('base-estimate-select', BaseEstimateSelect)

Vue.component('base-custom-input', BaseCustomInput)

Vue.component('customer-select-popup', CustomerSelectPopup)
Vue.component('tax-select-popup', TaxSelectPopup)
Vue.component('note-select-popup', NoteSelectPopup)

Vue.component('base-time-picker', BaseTimePicker)

Vue.component('dot-icon', DotIcon)
Vue.component('save-icon', SaveIcon)
Vue.component('green-icon', GreenIcon)
Vue.component('red-icon', RedIcon)
Vue.component('whatsapp-icon', WhatsappIcon)
Vue.component('viber-icon', ViberIcon)
Vue.component('phone-icon', PhoneIcon)
Vue.component('mail-icon', MailIcon)
Vue.component('maps-icon', MapsIcon)
Vue.component('flesh-right-icon', FleshRight)
Vue.component('flesh-left-icon', FleshLeft)

Vue.component('google-map', GoogleMap)