var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              { attrs: { label: _vm.$t("estimates.file"), required: "" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative flex items-center justify-center h-24 p-6 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                    attrs: { id: "receipt-box" },
                  },
                  [
                    _vm.previewReceipt
                      ? _c("img", {
                          staticClass: "absolute opacity-100 preview-logo",
                          staticStyle: {
                            "max-height": "80%",
                            animation: "fadeIn 2s ease",
                          },
                          attrs: { src: _vm.previewReceipt },
                        })
                      : _c(
                          "div",
                          { staticClass: "flex flex-col items-center" },
                          [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400",
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-xs leading-4 text-center text-gray-400",
                              },
                              [
                                _vm._v(
                                  "\n               Faites glisser un fichier ici ou \n                "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cursor-pointer text-primary-500",
                                    attrs: { id: "pick-avatar" },
                                  },
                                  [_vm._v("parcourez")]
                                ),
                                _vm._v(
                                  "\n                pour choisir un fichier\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                ),
                _vm._v(" "),
                _c("sw-avatar", {
                  attrs: {
                    "preview-avatar": _vm.previewReceipt,
                    "enable-cropper": false,
                    trigger: "#receipt-box",
                    accept: ".pdf",
                  },
                  on: { changed: _vm.onChange },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("cloud-upload-icon", {
                            staticClass:
                              "h-5 mb-2 text-xl leading-6 text-gray-400",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeUploadModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.previewReceipt
              ? _c(
                  "sw-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                    },
                  },
                  [
                    _c("cloud-upload-icon", {
                      staticClass: "h-5 mb-2 text-xl leading-6 text-gray-400",
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.upload")) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }