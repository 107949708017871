export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_DASHBOARD_DATA_LOADED_STATE = 'SET_DASHBOARD_DATA_LOADED_STATE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const UPDATE_INVOICE_STATUS = 'UPDATE_INVOICE_STATUS'

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_INVALID_DOCUMENT = 'DELETE_INVALID_DOCUMENT'
export const UPDATE_DOCUMENT_STATUS = 'UPDATE_DOCUMENT_STATUS'
export const DELETE_RECAP = 'DELETE_RECAP'

export const DELETE_DEMENAGEMENT = 'DELETE_DEMENAGEMENT'
export const UPDATE_DEMENAGEMENT_STATUS = 'UPDATE_DEMENAGEMENT_STATUS'
