import * as types from './mutation-types'

export default {
  [types.SET_INITIAL_DATA](state, data) {
    state.contacts = data.customersCount
    state.users = data.usersCount
    state.invoices = data.invoicesCount

    state.documents = data.documentsCount
    state.recaps = data.recapsCount
    state.type_documents = data.typeDocumentsCount

    state.expenses = data.expenses
    state.recentInvoices = data.invoices
    state.newContacts = data.contacts
    state.newUsers = data.users
    state.totalDueAmount = data.totalDueAmount
    state.dueInvoices = data.dueInvoices

    state.dueDocuments = data.dueDocuments
    state.invalidDocuments = data.invalidDocuments
    state.recentRecaps = data.recaps

    state.weeklyInvoices.days = data.weekDays
    state.weeklyInvoices.counter = data.counters

    if (state.chartData && data.chartData) {
      state.chartData.months = data.chartData.months
      state.chartData.invoiceTotals = data.chartData.invoiceTotals
      state.chartData.expenseTotals = data.chartData.expenseTotals
      state.chartData.netProfits = data.chartData.netProfits
      state.chartData.receiptTotals = data.chartData.receiptTotals
    }

    state.salesTotal = data.salesTotal
    state.totalReceipts = data.totalReceipts
    state.totalExpenses = data.totalExpenses
    state.netProfit = data.netProfit
  },

  [types.SET_DASHBOARD_DATA_LOADED_STATE](state, data) {
    state.isDashboardDataLoaded = data
  },

  [types.UPDATE_INVOICE_STATUS](state, data) {
    let pos = state.dueInvoices.findIndex((invoice) => invoice.id === data.id)

    if (state.dueInvoices[pos]) {
      state.dueInvoices[pos].status = data.status
    }
  },

  [types.DELETE_INVOICE](state, data) {
    let index = state.dueInvoices.findIndex(
      (invoice) => invoice.id === data.ids[0]
    )
    state.dueInvoices.splice(index, 1)
  },

  [types.DELETE_DOCUMENT](state, data) {
    let index = state.dueDocuments.findIndex(
      (doc) => doc.id === data.ids[0]
    )
    state.dueDocuments.splice(index, 1)
  },

  [types.DELETE_INVALID_DOCUMENT](state, data) {
    let index = state.invalidDocuments.findIndex(
      (doc) => doc.id === data.ids[0]
    )
    state.invalidDocuments.splice(index, 1)
  },

  [types.UPDATE_DOCUMENT_STATUS](state, data) {
    let pos = state.dueDocuments.findIndex(
      (document) => document.id === data.id
    )

    if (state.dueDocuments[pos]) {
      state.dueDocuments[pos].status = data.status
    }
  },

  [types.DELETE_RECAP](state, data) {
    let index = state.recentRecaps.findIndex(
      (recap) => recap.id === data.ids[0]
    )
    state.recentRecaps.splice(index, 1)
  },
}
