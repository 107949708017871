var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.company_info"),
        description: _vm.$t("wizard.company_info_desc"),
      },
    },
    [
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.next()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "grid grid-cols-1 mb-4 md:grid-cols-2 md:mb-6" },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$tc("settings.company_info.company_logo"),
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                          attrs: { id: "logo-box" },
                        },
                        [
                          _vm.previewLogo
                            ? _c("img", {
                                staticClass:
                                  "absolute opacity-100 preview-logo",
                                staticStyle: {
                                  "max-height": "80%",
                                  animation: "fadeIn 2s ease",
                                },
                                attrs: { src: _vm.previewLogo },
                              })
                            : _c(
                                "div",
                                { staticClass: "flex flex-col items-center" },
                                [
                                  _c("cloud-upload-icon", {
                                    staticClass:
                                      "h-5 mb-2 text-xl leading-6 text-gray-400",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-xs leading-4 text-center text-gray-400",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Drag a file here or\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer text-primary-500",
                                          attrs: { id: "pick-avatar" },
                                        },
                                        [_vm._v("browse")]
                                      ),
                                      _vm._v(
                                        "\n                to choose a file\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("sw-avatar", {
                        attrs: {
                          trigger: "#logo-box",
                          "preview-avatar": _vm.previewLogo,
                        },
                        on: {
                          changed: _vm.onChange,
                          uploadHandler: _vm.onUploadHandler,
                          handleUploadError: _vm.onHandleUploadError,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("cloud-upload-icon", {
                                  staticClass:
                                    "h-5 mb-2 text-xl leading-6 text-gray-400",
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.company_name"),
                        error: _vm.companyNameError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.companyData.name.$error,
                          type: "text",
                          name: "name",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.companyData.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.companyData.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.companyData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "companyData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.country"),
                        error: _vm.countryError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.companyData.country_id.$error },
                        attrs: {
                          options: _vm.countries,
                          searchable: true,
                          "allow-empty": false,
                          "show-labels": false,
                          placeholder: _vm.$t("general.select_country"),
                          "track-by": "id",
                          label: "name",
                        },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6",
                },
                [
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("wizard.state") } },
                    [
                      _c("sw-input", {
                        attrs: { name: "state", type: "text" },
                        model: {
                          value: _vm.companyData.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "state", $$v)
                          },
                          expression: "companyData.state",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("wizard.city") } },
                    [
                      _c("sw-input", {
                        attrs: { name: "city", type: "text" },
                        model: {
                          value: _vm.companyData.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "city", $$v)
                          },
                          expression: "companyData.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 gap-4 mb-6 md:grid-cols-2" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("wizard.address"),
                            error: _vm.address1Error,
                          },
                        },
                        [
                          _c("sw-textarea", {
                            attrs: {
                              invalid:
                                _vm.$v.companyData.address_street_1.$error,
                              placeholder: _vm.$t("general.street_1"),
                              name: "billing_street1",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyData.address_street_1.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyData.address_street_1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyData,
                                  "address_street_1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "companyData.address_street_1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-1 lg:mt-2 md:mt-2",
                          attrs: { error: _vm.address2Error },
                        },
                        [
                          _c("sw-textarea", {
                            attrs: {
                              invalid:
                                _vm.$v.companyData.address_street_2.$error,
                              placeholder: _vm.$t("general.street_2"),
                              name: "billing_street2",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyData.address_street_2.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyData.address_street_2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyData,
                                  "address_street_2",
                                  $$v
                                )
                              },
                              expression: "companyData.address_street_2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$t("wizard.zip_code") } },
                        [
                          _c("sw-input", {
                            attrs: { type: "text", name: "zip" },
                            model: {
                              value: _vm.companyData.zip,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyData,
                                  "zip",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "companyData.zip",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: { label: _vm.$t("wizard.phone") },
                        },
                        [
                          _c("sw-input", {
                            attrs: { type: "text", name: "phone" },
                            model: {
                              value: _vm.companyData.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyData,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "companyData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("wizard.save_cont")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }