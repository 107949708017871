<template>
  <base-page>
    <sw-page-header :title="currentUser.role == 'user' ? $tc('recaps.title',2) : $tc('recaps.title',1)">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />

        <sw-breadcrumb-item
          to="#"
         :title="currentUser.role == 'user' ? $tc('recaps.title',2) : $tc('recaps.title',1)"
          active
        />
      </sw-breadcrumb>

      <template slot="actions">
        <sw-button
          v-show="totalRecaps"
          size="lg"
          variant="primary-outline"
          @click="toggleFilter"
        >
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="w-4 h-4 ml-2 -mr-1" />
        </sw-button>
        <sw-button
          @click="addRecap()"
          variant="primary"
          size="lg"
          class="ml-4"
          v-if="currentUser.role != 'user'"
        >
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('recaps.add_recap') }}
        </sw-button>
      
      </template>
    </sw-page-header>

    <slide-y-up-transition>
      <sw-filter-wrapper
        v-show="showFilters"
        class="relative grid grid-flow-col grid-rows"
      >
        <sw-input-group v-if="currentUser.role != 'user'" :label="$tc('documents.user', 1)" class="mt-2">
          <base-customer-select
            ref="userSelect"
            @select="onSelectUser"
            @deselect="clearUserSearch"
          />
        </sw-input-group>

        <sw-input-group
          :label="$t('recaps.date')"
          color="black-light"
          class="mt-2"
        >
          <VueDatePicker 
            type="month" 
            placeholder="Choisir un mois"
            fullscreen-mobile
            v-model="filters.recap_date"
          />
        </sw-input-group>

        <label
          class="absolute text-sm leading-snug text-black cursor-pointer"
          style="top: 10px; right: 15px"
          @click="clearFilter"
          >{{ $t('general.clear_all') }}
        </label>
      </sw-filter-wrapper>
    </slide-y-up-transition>

    <sw-empty-table-placeholder
    
      v-show="showEmptyScreen"
      :title="$t('recaps.no_recaps')"
      :description="$t('recaps.list_of_recaps')"
    >
      <moon-walker-icon class="mt-5 mb-4" />

    </sw-empty-table-placeholder>
 
    <div v-show="!showEmptyScreen" class="relative table-container">
      <div class="relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid ">
        <p class=" text-sm" style="top: 50px">
          {{ $t('general.showing') }}: <b>{{ recaps.length }}</b>
          {{ $t('general.of') }} <b>{{ totalRecaps }}</b>
        </p>
      </div>

      <div
        v-show="recaps && recaps.length"
        class="absolute z-10 items-center pl-4 mt-2 select-none md:mt-6"
      >
        
      </div>

      <sw-table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
         <span></span>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          sort-as="recap_date"
          :label="$t('recaps.date')"

        >
          <template slot-scope="row">
              <span>{{ $t('recaps.date') }}</span>
              {{format(row.recap_date)}}
          </template>

        </sw-table-column>
         
        <sw-table-column
          :sortable="true"
          sort-as="partenaire_name"
          :label="$t('recaps.partenaire')"
          v-if="currentUser.role != 'user'"
        >
          <template slot-scope="row">
              <span>{{ $t('recaps.partenaire') }}</span>
              {{row.partenaire_name}}
          </template>

        </sw-table-column>

        <sw-table-column
          :sortable="false"
          :label="$t('recaps.recap_admin')"
        >
          <template slot-scope="row">
            <span> {{ $t('recaps.recap_admin') }}</span>
            <sw-badge
                :bg-color="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').bgColor"
                :color="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').color"
                :font-size="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').fontSize"
                class="px-1 text-xs"
              >
              {{ (row.recap_admin)
                ?
                ( currentUser.role != 'user' ) ? 'Envoyé' : 'Reçu' 
                : 'NON'
              }}
            </sw-badge>
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="false"
          :label="currentUser.role == 'user' ?  $t('recaps.my_recap') :  $t('recaps.recap_user')"
        >
          <template slot-scope="row">
            <span> {{ $t('recaps.recap_user') }}</span>.
            <sw-badge
                :bg-color="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').bgColor"
                :color="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').color"
                :font-size="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').fontSize"
                class="px-1 text-xs"
              >
              {{ (row.recap_partenaire)
                ?
                ( currentUser.role != 'user' ) ?  'Reçu' :'Envoyé' 
                : 'NON'
              }}
            </sw-badge>
          </template>
        </sw-table-column>
        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span> {{ $t('recaps.action') }} </span>
            <div class="flex justify-center"> 
              <sw-dropdown-item
                  @click="addRecap(row.recap_date)"
                  v-if="currentUser.role == 'user' && !row.recap_partenaire"
                >
                  <plus-circle-icon class="h-5 mr-3 text-gray-600" />
              </sw-dropdown-item>
              <sw-dropdown-item
                  tag-name="router-link"
                  :to="{ path: `recaps/${row.id}/view/${row.media[0].id}` }"
                >
                  <eye-icon class="h-5 mr-3 text-gray-600" />
              </sw-dropdown-item>              
            </div>
          </template>
          
        </sw-table-column>
        <sw-table-column
          :sortable="false"
          :filterable="false"
        >
          <template>
            <span> </span>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Permissions from '../../stub/permissions'
import Ls from '../../services/ls'
import moment from 'moment'
import MoonWalkerIcon from '@/components/icon/MoonwalkerIcon'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import {
  PlusIcon,
  CalculatorIcon,
  DocumentIcon,
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  EyeIcon,
  XCircleIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  TrashIcon,
  PencilIcon,
  HashtagIcon,
   TruckIcon,
    ViewGridAddIcon,
    CloudUploadIcon,
  LightBulbIcon,
  
} from '@vue-hero-icons/solid'

import { DotsHorizontalIcon, 
  PlusCircleIcon
 } from '@vue-hero-icons/outline'

export default {
  components: {
    MoonWalkerIcon,
    CalculatorIcon,
    DotsHorizontalIcon,
    DocumentIcon,
    PlusIcon,
    FilterIcon,
    XIcon,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    CheckCircleIcon,
    PaperAirplaneIcon,
    DocumentTextIcon,
    XCircleIcon,
    EyeIcon,
    HashtagIcon,
    TruckIcon,
    ViewGridAddIcon,
    LightBulbIcon,
    CloudUploadIcon,
    PlusCircleIcon,
    VueDatePicker
  },

  data() {
    return {
      showFilters: false,
      currency: null,
      previewReceipt: null,
      newRecap : [{
          recap_date :null,
          type_id : null,
          attachment : null,
          fileObject : null,
          status : ''
      }],
      isRequestOngoing: true,
      activeTab: this.$t('general.draft'),
      closed :false,
      helpDecision : false,
      permissionsStub : Permissions,

      filters: {
        user: '',
        recap_date: null,
      },
      my_recaps : [],
    }
  },

  created() {
    this.permissionsStub = Permissions
   
  },

  computed: {
    showEmptyScreen() {
        console.log(this.totalRecaps)
      return !this.totalRecaps && !this.isRequestOngoing
    },

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },

    ...mapGetters('users', ['users']),

    ...mapGetters('recap', [
      'selectedRecaps',
      'totalRecaps',
      'recaps',
      'selectAllField',
    ]),

    ...mapGetters('user', ['currentUser','userPermissions']),
   
    selectField: {
      get: function () {
        return this.selectedRecaps
      },
      set: function (val) {
        this.selectRecap(val)
      },
    },

    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllRecaps()
    }
  },

  methods: {
    ...mapActions('recap', [
      'fetchRecaps',
      'fetchRecap',
      'fetchMyRecaps',
      'resetSelectedRecaps',
      'selectRecap',
      'selectAllRecaps',
      'deleteRecap',
      'deleteMultipleRecaps',
      'setSelectAllState',
      'markAsAccepted',
      'markAsRejected',
    ]),

    ...mapActions('modal', ['openModal']),
    
    addRecap(date) {
      this.openModal({
        title: Ls.get('currentRole') != 'user' ?  this.$tc('recaps.add_new_recap',1) :  this.$tc('recaps.add_new_recap',2) ,
        componentName: 'RecapModal',
        refreshData: this.$refs.table.refresh,
        data : date ? {recap_date : date} : null
      })
    },

    format(date) {
      return moment(date).format('MM-YYYY')
    },

    async editRecap(id) {
      let response = await this.fetchRecap(id)
      let recap = response.data.recap
      this.openModal({
        title: Ls.get('currentRole') != 'user' ? this.$tc('recaps.edit_recap',1) : this.$tc('recaps.edit_recap',2),
        componentName: 'RecapModal',
        id: id,
        data: {recap , type_id : recap.type_recap_id},
        refreshData: this.$refs.table.refresh,
      })
    },
 

    refreshTable() {
      this.$refs.table.refresh()
    },
    initialiseRecaps (){
        Promise.all([
          this.fetchRecapTypes(),
          this.fetchMyRecaps()
        ])
        .then(async([res1,res2]) => {

          this.my_recaps = res2.data.recaps
            console.log(res2)
        })
        .catch((error) => {
          console.log(error)
        })
        // if(this.currentUser.role == 'user') {
        //    let res = await this.fetchMyRecaps()
        //    this.my_recaps = res.data.recaps
        //     console.log(res)
        // }
        // console.log(this.recaps)
        
    },

    async fetchData({ page, filter, sort }) {

      let data = {
        user_id:
          this.filters.user === null
            ? this.filters.user
            : this.filters.user.id,
        recap_date: this.filters.recap_date ? moment(this.filters.recap_date).format('YYYY-MM-DD') : '',
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchRecaps(data)
      console.log(response.data)
      this.isRequestOngoing = false


      return {
        data: response.data.recaps.data,
        pagination: {
          totalPages: response.data.recaps.last_page,
          currentPage: page,
          count: response.data.recaps.count,
        },
      }
    },

    setStatusFilter(val) {
      if (this.activeTab == val.title) {
        return true
      }
      this.activeTab = val.title
      switch (val.title) {
        case this.$t('general.draft'):
          this.filters.status = {id:'DRAFT'}
          break
        case this.$t('general.sent'):
          this.filters.status = {id:'SENT'}
          break
        case this.$t('recaps.accepted'):
          this.filters.status = {id:'ACCEPTED'}
          break
        default:
          this.filters.status = {id:''}
          break
      }
    },

    setFilters() {
      this.resetSelectedRecaps()
      this.$refs.table.refresh()
    },

    clearFilter() {
      if (this.filters.user) {
        this.$refs.userSelect.$refs.baseSelect.removeElement(
          this.filters.user
        )
      }

      this.filters = {
        user: null,
        recap_date: null,
      }

      // this.activeTab = this.$t('general.all')
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    onSelectUser(user) {
      this.filters.user = user
    },

    async removeRecap(id) {
      this.id = id
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('recaps.confirm_delete', 1),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteRecap({ ids: [this.id] })

          if (res.data.success) {
            this.$refs.table.refresh()
            this.resetSelectedRecaps()
            window.toastr['success'](this.$tc('recaps.deleted_message', 1))
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async convertInToinvoice(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('recaps.confirm_conversion'),
        icon: '/assets/icon/file-alt-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willConvertInToinvoice) => {
        if (willConvertInToinvoice) {
          let res = await this.convertToInvoice(id)

          if (res.data) {
            window.toastr['success'](this.$t('recaps.conversion_message'))
            this.$router.push(`invoices/${res.data.invoice.id}/view`)
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async removeMultipleRecaps() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('recaps.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteMultipleRecaps()

          if (res.data.success) {
            this.$refs.table.refresh()
            this.resetSelectedRecaps()
            window.toastr['success'](this.$tc('recaps.deleted_message', 2))
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async clearUserSearch(removedOption, id) {
      this.filters.user = ''
      this.refreshTable()
    },

    async clearStatusSearch(removedOption, id) {
      this.filters.status = {id: '', name: ''}
      console.log(this.filters.status)
      this.activeTab = this.$t('general.all')
      this.refreshTable()
    },

    async onMarkAsSent(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('recaps.confirm_mark_as_sent'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willMarkAsSent) => {
        if (willMarkAsSent) {
          const data = {
            id: id,
            status: 'SENT',
          }

          let response = await this.markAsSent(data)
          this.refreshTable()

          if (response.data) {
            window.toastr['success'](
              this.$tc('recaps.mark_as_sent_successfully')
            )
          }
        }
      })
    },
    async sendRecap(Recap) {
      this.openModal({
        title: this.$t('recaps.send_recap'),
        componentName: 'SendRecapModal',
        id: recap.id,
        data: recap,
        variant: 'lg',
      })
    },
    setActiveTab(val) {
      switch (val.name) {
        case this.$t('general.draft'):
          this.activeTab = this.$t('general.draft')
          break
        case this.$t('general.sent'):
          this.activeTab = this.$t('general.sent')
          break
        case this.$t('recaps.accepted'):
          this.activeTab = this.$t('recaps.accepted')
          break
        default:
          this.activeTab = this.$t('general.all')
          break
      }
    },
  },
}
</script>
