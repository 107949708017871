<template>
  <div class="item-selector">
    <sw-select
      ref="baseSelect"
      v-model="estimateSelect"
      :options="estimates"
      :show-labels="false"
      :preserve-search="false"
      :placeholder="$t('customers.type_or_click')"
      label="estimate_number"
      class="multi-select-item"
      @close="checkEstimates"
      @value="onTextChange"
      @select="(val) => $emit('select', val)"
      @remove="deselectEstimate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      estimateSelect: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('estimate', ['estimates']),
  },

  created() {
    this.fetchEstimates()
  },

  methods: {
    ...mapActions('estimate', ['fetchEstimates']),
    async searchEstimates(search) {
      this.loading = true

      await this.fetchEstimates({ search })

      this.loading = false
    },
    onTextChange(val) {
      this.searchEstimates(val)
    },
    checkEstimates(val) {
      if (!this.estimates.length) {
        this.fetchEstimates()
      }
    },
    deselectEstimate() {
      this.estimateSelect = null
      this.$emit('deselect')
    },
  },
}
</script>
