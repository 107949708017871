<template>
  <div>
    <sw-base-modal ref="baseModal" :variant="variant">
      <template v-slot:header>
        <div
          class="absolute flex content-center justify-center w-5 cursor-pointer"
          style="top: 20px; right: 15px"
          @click="closeModal"
        >
          <x-icon />
        </div>
        <span>{{ modalTitle }}</span>
      </template>
      <component :is="component" />
    </sw-base-modal>
  </div>
</template>

<script>
import { XIcon } from '@vue-hero-icons/solid'
import { mapActions, mapGetters } from 'vuex'
// import TaxTypeModal from './TaxTypeModal'
// import ItemModal from './ItemModal'
// import EstimateTemplate from './EstimateTemplate'
// import InvoiceTemplate from './InvoiceTemplate'
// import CustomerModal from './CustomerModal'
// import CategoryModal from './CategoryModal'
// import BackupModal from './BackupModal'
// import PaymentMode from './PaymentModeModal'
// import ItemUnit from './ItemUnitModal'
import MailTestModal from './MailTestModal'
// import SendInvoiceModal from './SendInvoiceModal'
// import SendEstimateModal from './SendEstimateModal'
// import SendPaymentModal from './SendPaymentModal'
import FileDiskModal from './FileDiskModal'
// import SetDefaultDiskModal from './SetDefaultDiskModal'
// import CustomFieldModal from './CustomField/Index'
// import NoteSelectModal from './NoteModal'
// import SendAllModal from './SendAllModal'
import UploadDocumentModal from './UploadDocumentModal'
// import PaymentSubscribeModal from './PaymentSubscribeModal'
// import PayIfOnTrialModal from './PayIfOnTrialModal'
// import PackModal from './PackModal'
// import CompnayModal from './CompnayModal'
// import RoleModal from './RoleModal'
import ImageModal from './ImageModal'
// import MigrationModal from './MigrationModal'
import SendDocumentModal from './SendDocumentModal.vue'
import EditCompanyModal from './EditCompanyModal'
import DocumentTypeModal from './DocumentTypeModal.vue'
import DocumentModal from './DocumentModal.vue'
import RecapModal from './RecapModal.vue'

export default {
  components: {
    // TaxTypeModal,
    // ItemModal,
    // EstimateTemplate,
    // InvoiceTemplate,
    // CustomerModal,
    // CategoryModal,
    // BackupModal,
    // PaymentMode,
    // ItemUnit,
    MailTestModal,
    // SendInvoiceModal,
    // SendEstimateModal,
    // SendPaymentModal,
    XIcon,
    FileDiskModal,
    // SetDefaultDiskModal,
    // CustomFieldModal,
    // NoteSelectModal,
    // SendAllModal,
    UploadDocumentModal,
    // PaymentSubscribeModal,
    // PayIfOnTrialModal,
    // PackModal,
    // CompnayModal,
    // RoleModal,
    ImageModal,
    // MigrationModal,
    SendDocumentModal,
    EditCompanyModal,
    DocumentTypeModal,
    DocumentModal,
    RecapModal,

  },
  data() {
    return {
      component: '',
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalActive',
      'modalTitle',
      'componentName',
      'modalSize',
      'modalData',
      'variant',
    ]),
  },
  watch: {
    componentName(component) {
      if (!component) {
        return
      }
      this.component = component
    },
    modalActive(status) {
      if (status) {
        this.$refs.baseModal.open()
        return true
      }
      this.$refs.baseModal.close()
      return false
    },
  },
  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),
  },
}
</script>
