var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user.role != "user"
      ? _c(
          "div",
          { staticClass: "grid gap-6 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                attrs: { slot: "item-title", to: "/admin/users" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n          " +
                              _vm._s(_vm.getUsers) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$t("users.title")) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("contact-icon", {
                      staticClass: "w-10 h-10 text-primary-500 xl:w-10 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: {
                  slot: "item-title",
                  to: "/admin/settings/document-types",
                },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n         " +
                              _vm._s(_vm.getTypeDocuments) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$t("document_types.title")) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("documents-icon", {
                      staticClass: "w-10 h-10 text-primary-500 xl:w-10 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: { slot: "item-title", to: "/admin/documents" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n          " +
                              _vm._s(_vm.getDocuments) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$t("documents.title")) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("estimate-icon", {
                      staticClass: "w-10 h-10 xl:w-10 text-primary-500 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: { slot: "item-title", to: "/admin/recaps" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n          " +
                              _vm._s(_vm.getRecaps) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$tc("recaps.title", 1)) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("calendar-icon", {
                      staticClass: "w-10 h-10 text-primary-500 xl:w-10 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "grid gap-6 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                attrs: { slot: "item-title", to: "#" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n         " +
                              _vm._s(_vm.getTypeDocuments) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$t("document_types.title")) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("documents-icon", {
                      staticClass: "w-10 h-10 text-primary-500 xl:w-10 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                attrs: { slot: "item-title", to: "/admin/documents" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n          " +
                              _vm._s(_vm.getDocuments) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$t("documents.title")) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("estimate-icon", {
                      staticClass: "w-10 h-10 xl:w-10 text-primary-500 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                attrs: { slot: "item-title", to: "/admin/recaps" },
                slot: "item-title",
              },
              [
                _c("div", [
                  _vm.getDashboardDataLoaded
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-xl font-semibold leading-tight text-black xl:text-3xl",
                        },
                        [
                          _vm._v(
                            "\r\n          " +
                              _vm._s(_vm.getRecaps) +
                              "\r\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\r\n          " +
                          _vm._s(_vm.$tc("recaps.title", 1)) +
                          "\r\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("calendar-icon", {
                      staticClass: "w-10 h-10 text-primary-500 xl:w-10 xl:h-10",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }