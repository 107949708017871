<template>
  <div class="mt-6">
    <base-loader v-if="!getDashboardDataLoaded" />

    <div v-if="getInvalidDocuments.length > 0" class="bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full" role="alert">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" class="w-8 h-8 lg:w-4 lg:h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
      </svg>
      Attention ! Vous avez des documents expirés. Supprimez les pour libérer l'espace sur le systéme.
    </div>

    <div v-if="getDueDocuments.length > 0" class="bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full" role="alert">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="w-8 h-8 lg:w-4 lg:h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
      </svg>
      Vous avez des documents qui seront expirés aprés moin de {{x_day}} jours. Pensez de les modifier ou supprimer.
    </div>

    <div class="grid grid-cols-1 gap-6 mt-10 xl:grid-cols-2">
      <!-- Due Documents -->
      <div
        class="due-documents bg-white rounded-lg p-4"
      >
        <div class="relative z-10 flex items-center justify-between">
          <h6 class="mb-0 text-xl font-semibold leading-normal">
            {{ $t('dashboard.recent_documents_card.title') }} {{ x_day ? '( - '+ x_day+'jours)' : '' }}
          </h6>

          <sw-button
            tag-name="router-link"
            to="/admin/documents"
            variant="primary-outline"
          >
            {{ $t('dashboard.recent_documents_card.view_all') }}
          </sw-button>
        </div>

        <sw-table-component
         
          ref="doc_table"
          :data="getDueDocuments"
          :show-filter="false"
          table-class="table"
          variant="gray"
        >
         <sw-table-column
            :sortable="false"
          >
            <template>
              <span/>
            </template>
          </sw-table-column>
          <sw-table-column
            :sortable="true"
            :label="$t('documents.date2')"
            show="formattedDateDelivrance"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.date') }}</span>
              <span class="mt-6">{{ row.formattedDateDelivrance }}</span>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.type')"
            show="type_document.name"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.type') }}</span>
              <router-link
                :to="{ path: `documents/${row.id}/view` }"
                class="font-medium text-primary-500"
              >
                {{ row.type_document.name }}
              </router-link>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.user')"
            show="partenaire.name"
            v-if="user.role != 'user'"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.user') }}</span>
              <span>
                {{ row.partenaire.name }}
              </span>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.status')"
            sort-as="status"
          >
            <template slot-scope="row">
              <span> {{ $t('documents.status') }}</span>

              <sw-badge
                :bg-color="$utils.getBadgeStatusColor(row.status).bgColor"
                :color="$utils.getBadgeStatusColor(row.status).color"
              >
                {{
                  $utils.getStatusTranslation(row.status)
                }}
              </sw-badge>
            </template>
          </sw-table-column>

          <!-- <sw-table-column
            :sortable="true"
            :label="$t('dashboard.recent_documents_card.amount_due')"
            show="due_amount"
            sort-as="due_amount"
          >
            <template slot-scope="row">
              <span>{{ $t('dashboard.recent_documents_card.amount_due') }}</span>

              <div
                v-html="$utils.formatMoney(row.due_amount, row.user.currency)"
              />
            </template>
          </sw-table-column> -->

          <sw-table-column
            :sortable="false"
            :filterable="false"
            cell-class="action-dropdown dashboard-recent-document-options no-click"
          >
            <sw-dropdown slot-scope="row">
              <dot-icon slot="activator" />
              <!-- <sw-dropdown-item
                v-if="user.role == 'user'"
                @click="editDocument(row,row.name,row.id)"
              >
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item> -->

              <sw-dropdown-item
                tag-name="router-link"
                :to="`documents/${row.id}/view`"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>

              <sw-dropdown-item 
                @click="removeDocument('due',row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
              
              <sw-dropdown-item
                v-if="row.status !== 'ACCEPTED' && user.role != 'user'"
                @click="onMarkAsAccepted(row.id)"
              >
                <check-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('documents.mark_as_accepted') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status !== 'REJECTED' && user.role != 'user'"
                @click="onMarkAsRejected(row.id)"
              >
                <x-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('documents.mark_as_rejected') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </sw-table-column>
        </sw-table-component>
      </div>

      <!-- Due Documents -->
      <div
        class="due-documents  bg-white rounded-lg p-4"
      >
        <div class="relative z-10 flex items-center justify-between">
          <h6 class="mb-0 text-xl font-semibold leading-normal">
            {{ $t('dashboard.invalid_documents_card.title') }}
          </h6>

          <sw-button
            tag-name="router-link"
            to="/admin/documents"
            variant="primary-outline"
          >
            {{ $t('dashboard.recent_documents_card.view_all') }}
          </sw-button>
        </div>

        <sw-table-component
          variant="gray"
          ref="doc_invalid_table"
          :data="getInvalidDocuments"
          :show-filter="false"
          table-class="table"
        >
         <sw-table-column
            :sortable="false"
          >
            <template>
              <span/>
            </template>
          </sw-table-column>
          <sw-table-column
            :sortable="true"
            :label="$t('documents.date2')"
            show="formattedDateDelivrance"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.date') }}</span>
              <span class="mt-6">{{ row.formattedDateDelivrance }}</span>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.type')"
            show="type_document.name"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.type') }}</span>
              <router-link
                :to="{ path: `documents/${row.id}/view` }"
                class="font-medium text-primary-500"
              >
                {{ row.type_document.name }}
              </router-link>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.user')"
            show="partenaire.name"
            v-if="user.role != 'user'"
          >
            <template slot-scope="row">
              <span>{{ $t('documents.user') }}</span>
              <span>
                {{ row.partenaire.name }}
              </span>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            :label="$t('documents.status')"
            sort-as="status"
          >
            <template slot-scope="row">
              <span> {{ $t('documents.status') }}</span>

              <sw-badge
                :bg-color="$utils.getBadgeStatusColor(row.status).bgColor"
                :color="$utils.getBadgeStatusColor(row.status).color"
              >
                {{
                  $utils.getStatusTranslation(row.status)
                }}
              </sw-badge>
            </template>
          </sw-table-column>
          <sw-table-column
            :sortable="false"
            :filterable="false"
            cell-class="action-dropdown dashboard-recent-document-options no-click"
          >
            <sw-dropdown slot-scope="row">
              <dot-icon slot="activator" />
              <!-- <sw-dropdown-item
                v-if="user.role == 'user'"
                @click="editDocument(row,row.name,row.id)"
              >
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item> -->

              <sw-dropdown-item
                tag-name="router-link"
                :to="`documents/${row.id}/view`"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>

              <sw-dropdown-item 
                @click="removeDocument('invalid',row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </sw-table-column>
        </sw-table-component>
      </div>

      <!-- Recent recaps -->
      <div 
        class="recent-recaps  bg-white rounded-lg p-4"
      >
        <div class="relative z-10 flex items-center justify-between">
          <h6 class="mb-0 text-xl font-semibold leading-normal">
            {{ $t('dashboard.recent_recap_card.title') }}
          </h6>

          <sw-button
            tag-name="router-link"
            to="/admin/recaps"
            variant="primary-outline"

          >
            {{ $t('dashboard.recent_recap_card.view_all') }}
          </sw-button>
        </div>

        <sw-table-component
          ref="recap_table"
          :data="getRecentRecaps"
          :show-filter="false"
          table-class="table"
          variant="gray"
        >
          <sw-table-column
            :sortable="false"
          >
            <template>
              <span/>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            sort-as="recap_date"
            :label="$t('recaps.date')"
          >
            <template slot-scope="row">
                <span>{{ $t('recaps.date') }}</span>
                
                <router-link
                  :to="{ path: `recaps/${row.id}/view/${row.media[0].id}` }"
                  class="font-medium text-primary-500"
                >
                  {{format(row.recap_date)}}
                </router-link>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="true"
            sort-as="partenaire_name"
            :label="$t('recaps.partenaire')"
            v-if="user.role != 'user'"
          >
            <template slot-scope="row">
                <span>{{ $t('recaps.partenaire') }}</span>
                {{row.partenaire_name}}
            </template>

          </sw-table-column>

          <sw-table-column
            :sortable="false"
            :label="$t('recaps.recap_admin')"
          >
            <template slot-scope="row">
              <span> {{ $t('recaps.recap_admin') }}</span>
              <sw-badge
                  :bg-color="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').bgColor"
                  :color="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').color"
                  :font-size="$utils.getBadgeStatusColor(row.recap_admin ? 'ACCEPTED' : 'REJECTED').fontSize"
                  class="px-1 text-xs"
                >
                {{ (row.recap_admin)
                  ?
                  ( user.role != 'user' ) ? 'Envoyé' : 'Reçu' 
                  : 'NON'
                }}
              </sw-badge>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="false"
            :label="user.role == 'user' ?  $t('recaps.my_recap') :  $t('recaps.recap_partenaire')"
          >
            <template slot-scope="row">
              <span> {{ $t('recaps.recap_user') }}</span>.
              <sw-badge
                  :bg-color="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').bgColor"
                  :color="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').color"
                  :font-size="$utils.getBadgeStatusColor(row.recap_partenaire ? 'ACCEPTED' : 'REJECTED').fontSize"
                  class="px-1 text-xs"
                >
                {{ (row.recap_partenaire)
                  ?
                  ( user.role != 'user' ) ?  'Reçu' :'Envoyé' 
                  : 'NON'
                }}
              </sw-badge>
            </template>
          </sw-table-column>

          <sw-table-column
            :sortable="false"
            :filterable="false"
            cell-class="action-dropdown no-click"
          >
            <sw-dropdown slot-scope="row">
              <dot-icon slot="activator" />
                <sw-dropdown-item
                  @click="addRecap(row.recap_date)"
                  v-if="user.role == 'user' && !row.recap_partenaire"
                >
                  <plus-circle-icon class="h-5 mr-3 text-gray-600" />
                  {{ $t('general.add') }}
                </sw-dropdown-item>
              <sw-dropdown-item
                tag-name="router-link"
                :to="{ path: `recaps/${row.id}/view/${row.media[0].id}` }"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>

              <!-- <sw-dropdown-item
                @click="removeRecap(row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item> -->
            </sw-dropdown>
          </sw-table-column>
        </sw-table-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
import {
  PencilIcon,
  EyeIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  TrashIcon,
  DocumentTextIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    PencilIcon,
    EyeIcon,
    PaperAirplaneIcon,
    CheckCircleIcon,
    TrashIcon,
    DocumentTextIcon,
    XCircleIcon,
    PlusCircleIcon
  },

  data() {
    return {
      ...this.$store.state.dashboard,
      currency: {
        precision: 2,
        thousand_separator: ',',
        decimal_separator: '.',
        symbol: '$',
      },
      isLoaded: false,
      x_day : null,
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('user', {
      user: 'currentUser',
    }),

    ...mapGetters('dashboard', [
      'getDashboardDataLoaded',
      'getDueDocuments',
      'getInvalidDocuments',
      'getRecentRecaps',
    ]),
  },

  created(){
    this.laodDay()
  },

  methods: {
    ...mapActions('dashboard', ['loadData']),

    ...mapActions('document', ['deleteDocument','deleteInvalidDocument', 'fetchDocument', 'markAsAccepted', 'markAsRejected',]),

    ...mapActions('recap', ['deleteRecap']),
    ...mapActions('company', ['fetchCompanySettings']),
    ...mapActions('modal', ['openModal']),

     async laodDay() {
      let res = await this.fetchCompanySettings([
        'notification_x_day'
      ])

      this.x_day = res.data.notification_x_day ? res.data.notification_x_day : null
    },

    async loadData(params) {
      await this.$store.dispatch('dashboard/loadData', params)
      this.isLoaded = true
    },

    async removeRecap(id) {
      this.id = id
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('recaps.confirm_delete', 1),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let res = await this.deleteRecap({ ids: [this.id] })
            if (res.data.success) {
              window.toastr['success'](this.$tc('recaps.deleted_message', 1))
              this.refreshEstTable()
            } else if (res.data.error) {
              window.toastr['error'](res.data.message)
            }
          }
        })
    },

    refreshDocTable() {
      this.$refs.doc_table.refresh()
    },

    refreshDocInvalidTable() {
      this.$refs.doc_invalid_table.refresh()
    },

    refreshEstTable() {
      this.$refs.recap_table.refresh()
    },

    addRecap(date) {
      this.openModal({
        title: this.$t('recaps.add_new_recap') ,
        componentName: 'RecapModal',
        refreshData: this.$refs.recap_table.refresh,
        data : date ? {recap_date : date} : null
      })
    },

    async onMarkAsSent(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('recaps.confirm_mark_as_sent'),
          icon: '/assets/icon/check-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willMarkAsSent) => {
          if (willMarkAsSent) {
            const data = {
              id: id,
              status: 'SENT',
            }

            let response = await this.markRecapAsSent(data)
            this.refreshEstTable()

            if (response.data) {
              window.toastr['success'](
                this.$tc('recaps.mark_as_sent_successfully')
              )
            }
          }
        })
    },

    format(date) {
      return moment(date).format('MM-YYYY')
    },

    async editDocument(document,name,id) {
      // let response = await this.fetchDocument(id)
      // let document = response.data.document
      this.openModal({
        title: this.$t('documents.edit_document')+ ' de type '+name,
        componentName: 'DocumentModal',
        id: id,
        data: {document , type_id : document.type_document_id},
        refreshData: this.$refs.doc_table.refresh,
      })
      console.log('test')
      // this.loadData()
    },

    async removeDocument(from,id) {
      this.id = id
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('documents.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let res
            if(from == 'invalid')
              res = await this.deleteInvalidDocument({ ids: [this.id] })
            else res = await this.deleteDocument({ ids: [this.id] })
            if (res.data.success) {
              window.toastr['success'](this.$tc('documents.deleted_message',1))
              if(from == 'invalid')
                this.refreshDocInvalidTable()
              else
                this.refreshDocTable()
            } else if (res.data.error) {
              window.toastr['error'](res.data.message)
            }
          }
        })
    },

    async onMarkAsAccepted(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('documents.confirm_mark_as_accepted'),
          icon: '/assets/icon/check-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (markedAsRejected) => {
          if (markedAsRejected) {
            const data = {
              id: id,
            }
            let response = await this.markAsAccepted(data)
            this.refreshDocTable()

            if (response.data) {
              this.refreshDocTable()
              window.toastr['success'](
                this.$tc('documents.marked_as_accepted_message')
              )
            }
          }
        })
    },

    async onMarkAsRejected(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('documents.confirm_mark_as_rejected'),
          icon: '/assets/icon/times-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (markedAsRejected) => {
          if (markedAsRejected) {
            const data = {
              id: id,
            }
            let response = await this.markAsRejected(data)
            this.refreshDocTable()

            if (response.data) {
              this.refreshDocTable()
              window.toastr['success'](
                this.$tc('documents.marked_as_rejected_message')
              )
            }
          }
        })
    },

  },
}
</script>
