<template>
  <base-page v-if="document" class="xl:pl-96">
    <sw-page-header :title="pageTitle">
      <template slot="actions">
        <sw-button
            v-if="currentUser.role == 'user'"
          variant="primary"
          class="text-sm"
          @click="addDocument"
        >
           <plus-icon class="h-5 mr-3 " />
            {{ $t('general.add') }}
        </sw-button>
        <div  v-if="currentUser.role == 'user'" class="mr-3 ml-3 text-sm">
          <sw-button
            variant="primary-outline"
            @click="editDocument($route.params.id)"
          >
             <pencil-icon class="h-5 mr-3 " />
            {{ $t('general.edit') }}
          </sw-button>
        </div>
        <sw-button
          variant="primary"
          class="text-sm"
          @click="removeDocument($route.params.id)"
        >
           <trash-icon class="h-5 mr-3 " />
            {{ $t('general.delete') }}
        </sw-button>
        <sw-button
            v-if="isReceiptAvailable"
            :href="'/documents/'+this.$route.params.id+'/file'"
            tag-name="a"
            variant="primary-outline"
            class="ml-2"
          >
            <download-icon  class="h-5 mr-3 "  />
            {{ $t('documents.download') }}
          </sw-button>
        <sw-dropdown class="ml-3" v-if="currentUser.role != 'user'">
          <sw-button slot="activator" variant="primary" class="h-10">
            <dots-horizontal-icon class="h-5" />
          </sw-button>

          <sw-dropdown-item
            v-if="document.status !== 'ACCEPTED'"
            @click="onMarkAsAccepted($route.params.id)"
          >
            <check-circle-icon class="h-5 mr-3 text-gray-600" />
            {{ $t('documents.mark_as_accepted') }}
          </sw-dropdown-item>

          <sw-dropdown-item
            v-if="document.status !== 'REJECTED'"
            @click="onMarkAsRejected($route.params.id)"
          >
            <x-circle-icon class="h-5 mr-3 text-gray-600" />
            {{ $t('documents.mark_as_rejected') }}
          </sw-dropdown-item> 
        </sw-dropdown>
      </template>
    </sw-page-header>

    <!-- sidebar -->
    <div
      class="fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block"
    >
      <div
        class="flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full"
      >
        <sw-input
          v-model="searchData.searchText"
          :placeholder="$t('general.search')"
          class="mb-6"
          type="text"
          variant="gray"
          @input="onSearch"
        >
          <search-icon slot="rightIcon" class="h-5" />
        </sw-input>

        <div class="flex mb-6 ml-3" role="group" aria-label="First group">
          <sw-dropdown
            :close-on-select="false"
            align="left"
            position="bottom-start"
          >
            <sw-button slot="activator" size="md" variant="gray-light">
              <filter-icon class="h-5" />
            </sw-button>

            <div class="px-2 py-1 mb-2 border-b border-gray-200 border-solid">
              {{ $t('general.sort_by') }}
            </div>

            <sw-dropdown-item v-if="currentUser.role != 'user'" class="flex px-1 py-1 cursor-pointer">
              <sw-input-group class="-mt-2 text-sm font-normal">
                <sw-radio
                  id="filter_document_date"
                  v-model="searchData.orderByField"
                  :label="$t('documents.partenaire')"
                  name="filter"
                  size="sm"
                  value="user_id"
                  @change="onSearch"
                />
              </sw-input-group>
            </sw-dropdown-item>

            <sw-dropdown-item class="flex px-1 py-1 cursor-pointer">
              <sw-input-group class="-mt-2 font-normal">
                <sw-radio
                  id="filter_due_date"
                  :label="$t('documents.date')"
                  v-model="searchData.orderByField"
                  name="filter"
                  size="sm"
                  value="date_delivrance"
                  @change="onSearch"
                />
              </sw-input-group>
            </sw-dropdown-item>
          </sw-dropdown>

          <sw-button
            v-tooltip.top-center="{ content: getOrderName }"
            class="ml-1"
            size="md"
            variant="gray-light"
            @click="sortData"
          >
            <sort-ascending-icon v-if="getOrderBy" class="h-5" />
            <sort-descending-icon v-else class="h-5" />
          </sw-button>
        </div>
      </div>

      <base-loader v-if="isSearching" :show-bg-overlay="true" />

      <div
        v-else
        class="h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll"
      >
      <div v-if="currentUser.role == 'user'">
        <div v-for="(type, index) in documents" :key="index+'type'" > 
            <router-link
            v-for="(document, index) in type.documents"
            :to="`/admin/documents/${document.id}/view`"
            :id="'document-' + document.id"
            :key="index"
            :class="[
                'flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent',
                {
                'bg-gray-100 border-l-4 border-primary-500 border-solid': hasActiveUrl(
                    document.id
                ),
                },
            ]"
            style="border-bottom: 1px solid rgba(185, 193, 209, 0.41)"
            >
            <div class="flex-2">
                <div
                class="pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate"
                >
                {{type.name }}
                </div>

                <!-- <div
                class="mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600"
                >
                {{ document.document_number }}
                </div> -->

                <sw-badge
                :bg-color="$utils.getBadgeStatusColor(document.status).bgColor"
                :color="$utils.getBadgeStatusColor(document.status).color"
                :font-size="$utils.getBadgeStatusColor(document.status).fontSize"
                class="px-1 text-xs"
                >
                {{ $utils.getStatusTranslation(document.status) }}
                </sw-badge>
            </div>

            <div class="flex-1 whitespace-nowrap right">
            
                <div
                class="text-sm not-italic font-normal leading-5 text-right text-gray-600"
                >
                {{ document.formattedDateDelivrance }}
                </div>
            </div>
            </router-link>
        </div>
      </div>
      <router-link
        v-else
          v-for="(document, index) in documents"
          :to="`/admin/documents/${$route.params.user}/view/${document.id}`"
          :id="'document-' + document.id"
          :key="index"
          :class="[
            'flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent',
            {
              'bg-gray-100 border-l-4 border-primary-500 border-solid': hasActiveUrl(
                document.id
              ),
            },
          ]"
          style="border-bottom: 1px solid rgba(185, 193, 209, 0.41)"
        >
          <div class="flex-2">
            <div
              class="pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate"
            >
              {{ document.partenaire.name }}
            </div>

            <!-- <div
              class="mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600"
            >
              {{ document.document_number }}
            </div> -->

            <sw-badge
              :bg-color="$utils.getBadgeStatusColor(document.status).bgColor"
              :color="$utils.getBadgeStatusColor(document.status).color"
              :font-size="$utils.getBadgeStatusColor(document.status).fontSize"
              class="px-1 text-xs"
            >
              {{ $utils.getStatusTranslation(document.status) }}
            </sw-badge>
          </div>

          <div class="flex-1 whitespace-nowrap right">
              <div
              class="mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900"
              v-html="document.type_document.name "
            />
            <div
              class="text-sm not-italic font-normal leading-5 text-right text-gray-600"
            >
              {{ document.formattedDateDelivrance }}
            </div>
          </div>
        </router-link>
        <p
          v-if="!documents.length"
          class="flex justify-center px-4 mt-5 text-sm text-gray-600"
        >
          {{ $t('documents.no_matching_documents') }}
        </p>
      </div>
    </div>

    <div
      class="flex flex-col min-h-0 mt-8 overflow-hidden"
      style="height: 75vh"
    >
      <iframe
        :src="`${shareableLink}`"
        class="flex-1 border border-gray-400 border-solid rounded-md frame-style"
      />
    </div>
  </base-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Ls from '../../services/ls'
import {
  DotsHorizontalIcon,
  FilterIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  SearchIcon,
  LinkIcon,
  TrashIcon,
  PencilIcon,
  DownloadIcon,
  XCircleIcon,
  CheckCircleIcon,
  PlusIcon
} from '@vue-hero-icons/solid'

const _ = require('lodash')
export default {
  components: {
    DotsHorizontalIcon,
    FilterIcon,
    DownloadIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    SearchIcon,
    PlusIcon,
    LinkIcon,
    PencilIcon,
    TrashIcon,
    XCircleIcon,
    CheckCircleIcon,
  },
  data() {
    return {
      id: null,
      count: null,
      documents: [],
      document: null,
      isReceiptAvailable: false,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null,
      },
      isRequestOnGoing: false,
      isSearching: false,
      isSendingEmail: false,
      isMarkingAsSent: false,
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    pageTitle() {
      return this.document.type_document.name
    },
    getOrderBy() {
      if (
        this.searchData.orderBy === 'asc' ||
        this.searchData.orderBy == null
      ) {
        return true
      }
      return false
    },
    getOrderName() {
      if (this.getOrderBy) {
        return this.$t('general.ascending')
      }
      return this.$t('general.descending')
    },
    shareableLink() {
      return `/documents/${this.document.id}/show`
    },
  },
  watch: {
    $route(to, from) {
      this.loadDocument()
    },
  },
  created() {
    this.loadDocuments()
    this.loadDocument()
    this.onSearch = _.debounce(this.onSearch, 500)
  },
  methods: {
    ...mapActions('document', [
      'fetchMyDocuments',
      'fetchDocuments',
      'searchDocument',
      'searchMyDocument',
      'deleteDocument',
      'selectDocument',
      'fetchDocument',
      'getDocumentFile',      
      'markAsAccepted',
      'markAsRejected',
    ]),

    ...mapActions('modal', ['openModal']),

    hasActiveUrl(id) {
      return this.$route.params.id == id
    },

    async loadDocuments() {
        let response
        if(Ls.get('currentRole') == 'user'){
         response = await this.fetchMyDocuments({ limit: 'all' })
          if (response.data) {
            this.documents = response.data.documents
          }
        } else {
          response = await this.fetchDocuments({all_documents : true , limit:'all', user_id : this.$route.params.user})
          if (response.data) {
            this.documents = response.data.documents.data
          }
        }  
      console.log(response)
      
      setTimeout(() => {
        this.scrollToDocument()
      }, 500)
    },
    scrollToDocument() {
      const el = document.getElementById(`document-${this.$route.params.id}`)

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
        el.classList.add('shake')
      }
    },
    async loadDocument() {
      let response = await this.fetchDocument(this.$route.params.id)

      if (response.data) {
        this.document = response.data.document
      }
      this.getFile()
    },

    async onSearch() {
      let data = 'limit=all&all_documents=true&'
      if(this.$route.name == 'documents.view.admnin')
        data += 'user_id='+ this.$route.params.user +'&'
      if (
        this.searchData.searchText !== '' &&
        this.searchData.searchText !== null &&
        this.searchData.searchText !== undefined
      ) {
        data += `search=${this.searchData.searchText}&`
      }

      if (
        this.searchData.orderBy !== null &&
        this.searchData.orderBy !== undefined
      ) {
        data += `orderBy=${this.searchData.orderBy}&`
      }

      if (
        this.searchData.orderByField !== null &&
        this.searchData.orderByField !== undefined
      ) {
        data += `orderByField=${this.searchData.orderByField}`
      }
      this.isSearching = true
      let response
      if(Ls.get('currentRole') != 'user')
        response = await this.searchDocument(data)
      else response = await this.searchMyDocument(data)
      this.isSearching = false
      if (response.data) {
        console.log(response)
        this.documents = response.data.documents.data ? response.data.documents.data : response.data.documents
      }
    },
    sortData() {
      if (this.searchData.orderBy === 'asc') {
        this.searchData.orderBy = 'desc'
        this.onSearch()
        return true
      }
      this.searchData.orderBy = 'asc'
      this.onSearch()
      return true
    },

    async getFile() {
      let res = await this.getDocumentFile(this.$route.params.id)
        console.log(res)
      if (res.data.error) {
        this.isReceiptAvailable = false
        return true
      }

      this.isReceiptAvailable = true
    },

    async editDocument(id) {
      let response = await this.fetchDocument(id)
      let document = response.data.document
      this.openModal({
        title: this.$t('documents.edit_document'),
        componentName: 'DocumentModal',
        id: id,
        data: {document, type_id : document.type_document.id},
        // refreshData: this.loadDocument(),
      })
    },

    addDocument() {
      this.openModal({
        title: this.$t('documents.add_new_document') ,
        componentName: 'DocumentModal',
        // refreshData: this.loadDocument(),
      })
    },

    async removeDocument(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('documents.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            let request = await this.deleteDocument({ ids: [id] })
            if (request.data.success) {
              window.toastr['success'](this.$tc('documents.deleted_message', 1))
              this.$router.push('/admin/documents')
            } else if (request.data.error) {
              window.toastr['error'](request.data.message)
            }
          }
        })
    },

    async onMarkAsAccepted(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_mark_as_accepted'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsAccepted) => {
        if (markedAsAccepted) {
          const data = {
            id: id,
            status: 'ACCEPTED',
          }

          let response = await this.markAsAccepted(data)

          if (response.data) {
            this.isSearching = true
           let res = await this.fetchDocuments({all_documents : true , limit:'all', user_id : this.$route.params.user})
            if (res.data) {
              this.documents = res.data.documents.data
            }
            this.isSearching = false
            window.toastr['success'](
              this.$tc('documents.marked_as_accepted_message')
            )
          }
        }
      })
    },

    async onMarkAsRejected(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('documents.confirm_mark_as_rejected'),
        icon: '/assets/icon/times-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsRejected) => {
        if (markedAsRejected) {
          const data = {
            id: id,
            status: 'REJECTED',
          }

          let response = await this.markAsRejected(data)

          if (response.data) {
             this.isSearching = true
            let res = await this.fetchDocuments({all_documents : true , limit:'all', user_id : this.$route.params.user})
            if (res.data) {
              this.documents = res.data.documents.data
            }
            this.isSearching = false
            window.toastr['success'](
              this.$tc('documents.marked_as_rejected_message')
            )
          }
        }
      })
    },

  },
}
</script>
