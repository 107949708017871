<template>
  <form action="" @submit.prevent="submitData">
    <div class="p-8 sm:p-6">
      <sw-input-group
        :label="$t('documents.partenaire')"
        :error="partenaireError"
        required
        v-if="currentUser.role != 'user'"
      >
        <sw-select
          v-model="selectedPartenaire"
          :options="partenaires"
          :searchable="true"
          :show-labels="false"
          :allow-empty="false"
          :invalid="$v.selectedPartenaire.$error"
          class="mt-2"
          label ="name"
        />
      </sw-input-group>
      <sw-input-group
        :label="$t('recaps.date')"
        :error="dateError"
        required
      >
         <!-- <base-date-picker
            v-model="date"
            :calendar-button="true"
            calendar-button-icon="calendar"
            :invalid="$v.date.$error"
            class="mt-2"
        /> -->
       <VueDatePicker 
        type="month" 
        placeholder="Cliquez et Choisissez un mois"
        :disabled="isEdit || modalData != null"
        fullscreen-mobile
        v-model="recap_date"
       />

      </sw-input-group>
       <sw-input-group 
            :label="$t('estimates.file')"
            required         
            :error="fileError"  
          >
            <div
              id="receipt-box"
              class="relative flex items-center justify-center h-28 p-10 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box"
            >
              <img
                v-if="attachment"
                :src="attachment"
                class="absolute opacity-100 preview-logo"
                style="max-height: 80%; animation: fadeIn 2s ease"
              />
              <div v-else class="flex flex-col items-center">
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-500"
                />
                <p class="text-xs leading-4 text-center text-gray-500">
                Cliquez ici et 
                  <span id="pick-avatar" class="cursor-pointer text-primary-500" >choisissez</span>
                  un fichier
                </p>
              </div>
            </div>
           
            <sw-avatar
              :preview-avatar="attachment"
              :enable-cropper="false"
              trigger="#receipt-box"
              @changed="onChange"
            >
              <template v-slot:icon>
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-400"
                />
              </template>
            </sw-avatar>
           
        </sw-input-group>    
    </div>
    <div
      class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg"
    >
      <sw-button
        type="button"
        variant="primary-outline"
        class="mr-3 text-sm"
        @click="closeRecapModal"
      >
        {{ $t('general.cancel') }}
      </sw-button>
      <sw-button variant="primary" type="submit" :loading="isLoading">
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ !isEdit ? $t('general.save') : $t('general.update') }}
      </sw-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CloudUploadIcon } from '@vue-hero-icons/solid'
import moment from 'moment'
import Ls from '../../../services/ls'
const { required, minLength, requiredIf, numeric } = require('vuelidate/lib/validators')
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
export default {
    components : {
        CloudUploadIcon,
        VueDatePicker
    },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      attachment: null,
      fileObject: null,
      recap_date : null,
      selectedPartenaire : null,
      partenaires : [],
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      'refreshData',
    ]),
    ...mapGetters('user', ['currentUser']),
    fileError() {
      if (!this.$v.attachment.$error) {
        return ''
      }

      if (!this.$v.attachment.required) {
        return this.$tc('validation.required')
      }
    },
    dateError () {
      if (!this.$v.recap_date.$error) {
        return ''
      }
      if (!this.$v.recap_date.required) {
        return this.$tc('validation.required')
      }
    },
     partenaireError () {
      if (!this.$v.selectedPartenaire.$error) {
        return ''
      }
      if (!this.$v.selectedPartenaire.required) {
        return this.$tc('validation.required')
      }
    },

    isUser(){
      return Ls.get('currentRole') == 'user'
    }
    
  },
  validations: {
    recap_date :{
       required
    },
    attachment : {
      required : requiredIf(function(){return !this.isEdit || this.isUser})
    },
    selectedPartenaire : {
      required : requiredIf(function(){ return !this.isUser})
    }
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true
        this.setData()
      } else {
        this.isEdit = false
      }
    },
    recap_date(val) {
        // if(val)
        //     return moment(val).format('YYYY-MM-DD')
        console.log(val)
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData()
      }
    },
  },

  mounted() {
    if (this.modalDataID) {
      this.isEdit = true
      this.setData()
    }   
    if(this.modalData) {
      if(this.modalData.recap_date)
        this.recap_date = this.modalData.recap_date
    }
  },

  created() {
    // this.getFile()
    this.loadUsers()
    this.fetchCurrentUser()
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('recap', ['addRecap', 'updateRecap','getRecapFile']),
     ...mapActions('users', ['fetchUsers']),
     ...mapActions('user', ['fetchCurrentUser']),

    async loadUsers(){
      let response = await this.fetchUsers({limit:'all'})
      if(response.data.users)
        this.partenaires = response.data.users.data
    },
   
    // async getFile() {
    //   let res = await this.getRecapFile(this.modalDataID)

    //   if (res.data.error) {
    //     this.isReceiptAvailable = false
    //     return true
    //   }
    //     console.log(res.data)
    //   this.isReceiptAvailable = true
    //   this.attachment = res.data.image
    //   this.fileObject = new File([''],res.data.media.file_name, {type: res.data.type, size : res.data.media.size})
    //   console.log(this.fileObject)
    // },

    resetFormData() {
      // this.recap_date = null
      this.attachment = null
      this.fileObject = null
      // this.$v.recap_date.$reset()
      this.$v.attachment.$reset()
    },
    
    onChange(data) {
      this.attachment = data.image
      this.fileObject = data.file
      console.log(this.fileObject)
    },


    async submitData() {
      this.$v.recap_date.$touch()
      this.$v.attachment.$touch()

      if (this.$v.$invalid) {
        return true
      }
      this.recap_date = moment(this.recap_date).format('YYYY-MM-DD')
      console.log(this.recap_date)
      console.log(this.fileObject)
     
      this.isLoading = true
      let response
      let data = new FormData()

      if (this.fileObject) {
        data.append('attachment', this.fileObject)
        data.append('recap_date', this.recap_date)
        
        if(this.isUser && !this.isEdit ) {
          data.append('recap_partenaire', this.fileObject.name)
          data.append('partenaire_id', this.currentUser.id)
        }
        else if(this.isUser) {
          data.append('recap_partenaire', this.fileObject.name)
        }
        else {
          data.append('recap_admin', this.fileObject.name)
          data.append('partenaire_id', this.selectedPartenaire.id)
          data.append('partenaire_name', this.selectedPartenaire.name)
        }
        if(this.isEdit)
          data.append('_method', 'PUT')
        console.log('if')
          
      }
      else if(this.isEdit) {
        data.append('recap_date', this.recap_date)    
        data.append('partenaire_id', this.selectedPartenaire.id)
        data.append('partenaire_name', this.selectedPartenaire.name)        
        console.log('else')
        data.append('_method', 'PUT')
      }

      try {
        if (!this.isEdit) {
            response = await this.addRecap(data)
          } else {
              console.log(data.entries())
              response = await this.updateRecap({data : data, id : this.modalDataID} )
          }

        if (response.data) {
          if (!this.isEdit) {
            window.toastr['success'](
              this.$t('recaps.created_message')
            )
          } else {
            window.toastr['success'](
              this.$t('recaps.updated_message')
            )
            console.log(response.data)
            this.$router.push(`/admin/recaps/${this.$route.params.id}/view/${response.data.mediaId}`)
           
          }
          window.hub.$emit('newRecap', response.data.recap)
          this.refreshData ? this.refreshData() : ''
          this.closeRecapModal()
          this.isLoading = false
          return true
        }
        window.toastr['error'](response.data.error)
      }catch(error) {
            console.log(error)
            this.isLoading = false
            if(!this.isEdit)
              this.resetFormData()
            // window.toastr['error'](error)
        }

    },

    setData() {
        this.recap_date = this.modalData.recap_date
        this.selectedPartenaire = this.modalData.users[0]
        // this.recap_date = moment(
        //           this.modalData.recap.recap_date
        //         ).format('YYYY-MM-DD'),
        console.log(this.modalData)
        console.log(this.recap_date)
    },

    closeRecapModal() {
      this.resetFormData()
      this.closeModal()
    },
  },
}
</script>
