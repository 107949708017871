var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sw-card", { attrs: { variant: "setting-card" } }, [
    _c(
      "div",
      {
        staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c("div", [
          _c("h6", { staticClass: "sw-section-title" }, [
            _vm._v("\n        Votre contrat avec Tunvita\n      "),
          ]),
          _vm._v(" "),
          _c("p", {
            staticClass: "mt-2 text-sm leading-snug text-gray-500",
            staticStyle: { "max-width": "680px" },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    !_vm.loadContract
      ? _c(
          "div",
          {
            staticClass:
              "flex flex-col w-full min-h-0 mt-8 overflow-hidden sw-scroll",
            staticStyle: { height: "75vh" },
          },
          [
            _c("div", { attrs: { id: "iframe-container" } }),
            _vm._v(" "),
            _vm.signatureLink
              ? _c("iframe", {
                  staticClass:
                    "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                  attrs: { src: `${_vm.signatureLink}` },
                })
              : _c("div", [_vm._v("\n    pas de contrat en ce moment\n  ")]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }