<template>
  <base-page v-if="recap" class="xl:pl-96">
    <sw-page-header :title="pageTitle">
      <template slot="actions">
        <sw-button
          variant="primary"
          class="text-sm"
          @click="addRecap"
          v-if="currentUser.role != 'user'"
        >
           <plus-icon class="h-5 mr-3 " />
            {{ $t('general.add') }}
        </sw-button>
        <div class="mr-3 ml-3 text-sm">
          <sw-button
            variant="primary-outline"
            v-if="canEdit"
            @click="editRecap($route.params.id)"
          >
             <pencil-icon class="h-5 mr-3 " />
            {{ $t('general.edit') }}
          </sw-button>
        </div>
        <sw-button
          variant="primary"
          class="text-sm"
          v-if="canEdit || currentUser.role != 'user'"
          @click="removeRecap($route.params.id, $route.params.media)"
        >
           <trash-icon class="h-5 mr-3 " />
            {{ $t('general.delete') }}
        </sw-button>
        <sw-button
            v-if="isReceiptAvailable"
            :href="'/recaps/'+$route.params.id+'/file/'+$route.params.media"
            tag-name="a"
            variant="primary-outline"
            class="ml-2"
          >
            <download-icon  class="h-5 mr-3 "  />
            {{ $t('recaps.download') }}
          </sw-button>
       
      </template>
    </sw-page-header>

    <!-- sidebar -->
    <div
     
      class="fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block"
    >
      <div  v-if="currentUser.role != 'user'">
        <div class="flex items-center justify-between px-4 pt-8 pb-2 border border-b-0 border-gray-200 border-solid height-full" >
          <sw-input
            v-model="searchData.searchText"
            :placeholder="$t('general.search')"
            class=""
            type="text"
            variant="gray"
            @input="onSearch"
          >
            <search-icon slot="rightIcon" class="h-5" />
          </sw-input>
          <div class="flex ml-3" role="group" aria-label="First group">
            <sw-dropdown
              :close-on-select="false"
              align="left"
              position="bottom-start"
            >
              <sw-button slot="activator" size="md" variant="gray-light">
                <filter-icon class="h-5" />
              </sw-button>

              <div class="px-2 py-1 mb-2 border-b border-gray-200 border-solid">
                {{ $t('general.sort_by') }}
              </div>

              <sw-dropdown-item class="flex px-1 py-1 cursor-pointer">
                <sw-input-group class="-mt-2 text-sm font-normal">
                  <sw-radio
                    id="filter_recap_date"
                    v-model="searchData.orderByField"
                    :label="$t('recaps.date')"
                    name="filter"
                    size="sm"
                    value="recap_date"
                    @change="onSearch"
                  />
                </sw-input-group>
              </sw-dropdown-item>
              <sw-dropdown-item class="flex px-1 py-1 cursor-pointer" v-if="currentUser.role != 'user'">
                <sw-input-group class="-mt-2 text-sm font-normal">
                  <sw-radio
                    id="filter_recap_name"
                    v-model="searchData.orderByField"
                    :label="$t('recaps.partenaire')"
                    name="filter"
                    size="sm"
                    value="partenaire_name"
                    @change="onSearch"
                  />
                </sw-input-group>
              </sw-dropdown-item>
            </sw-dropdown>

            <sw-button
              v-tooltip.top-center="{ content: getOrderName }"
              class="ml-1"
              size="md"
              variant="gray-light"
              @click="sortData"
            >
              <sort-ascending-icon v-if="getOrderBy" class="h-5" />
              <sort-descending-icon v-else class="h-5" />
            </sw-button>
          </div>
          </div>
          <div class="flex items-center justify-between px-4 pt8 pb-2 border border-t-0 border-gray-200 border-solid height-full">
            <!-- <base-customer-select
              v-if="currentUser.role != 'user'"
              class="w-full"
              ref="userSelect"
              @select="onSelectUser"
              @deselect="clearUserSearch"
            /> -->
            <VueDatePicker 
              type="month" 
              placeholder="Filtrer par mois"
              fullscreen-mobile
              clearable 
              v-model="filtersRecapDate"
            />
          </div>
          
        </div>
        <div v-else
          class="flex text-lg font-semibold text-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full"
        >
          Récap et facture du mois {{format(recap.recap_date)}}
        </div>
        <base-loader v-if="isSearching" :show-bg-overlay="true" />
      <div
        v-else
        class="h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll"
      >
        <div v-if="currentUser.role != 'user'">
          <div v-for="(recap, i) in recaps" :key="i+'recap'" > 
            <router-link
              v-for="(media, index) in recap.media"
              :to="`/admin/recaps/${recap.id}/view/${media.id}`"
              :id="'recap-' + media.id"
              :key="index+'media'"
              :class="[
                'flex justify-between p-4  overflow-auto items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent',
                {
                  'bg-gray-100  border-l-4 border-primary-500 border-solid': hasActiveUrl(
                    media.id
                  ),
                },
              ]"
              style="border-bottom: 1px solid rgba(185, 193, 209, 0.41)"
            >
              <div class="flex-2">
                <div
                  class="pr-2 mb-2  w-48 text-sm not-italic font-normal leading-5 text-black capitalize truncate"
                >
                  {{ media.name }}
                </div>

                <!-- <div
                  class="mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600"
                >
                  {{ recap.partenaire_name }}
                </div> -->

                <sw-badge
                  :bg-color="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').bgColor"
                  :color="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').color"
                  :font-size="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').fontSize"
                  class="px-1 text-xs"
                >
                  {{ media.custom_properties.user_role == 'user' ? $t('recaps.recap_user') : $t('recaps.recap_admin') }}
                </sw-badge>
              </div>

              <div class="flex-1 whitespace-nowrap right">
                  <div
                  class="mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900"
                  v-html="recap.partenaire_name"
                />
                <div
                  class="text-sm not-italic font-normal leading-5 text-right text-gray-600"
                >
                  {{ format(recap.recap_date) }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <router-link
          v-else
          v-for="(media, index) in recap.media"
          :to="`/admin/recaps/${recap.id}/view/${media.id}`"
          :id="'recap-' + media.id"
          :key="index+'-media-'+media.id"
          :class="[
            'flex justify-between overflow-auto p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent',
            {
              'bg-gray-100 border-l-4 border-primary-500 border-solid': hasActiveUrl(
                media.id
              ),
            },
          ]"
          style="border-bottom: 1px solid rgba(185, 193, 209, 0.41)"
        >
          <div class="flex-2">
            <div
              class="pr-2 mb-2 w-48 text-sm not-italic font-normal leading-5 text-black capitalize truncate"
            >
              {{ media.name }}
            </div>

            <!-- <div
              class="mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600"
            >
              {{ recap.recap_number }}
            </div> -->

            <sw-badge
              :bg-color="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').bgColor"
              :color="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').color"
              :font-size="$utils.getBadgeStatusColor(media.custom_properties.user_role == 'user' ? 'DRAFT' : 'ACCEPTED').fontSize"
              class="px-1 text-xs"
            >
              {{ media.custom_properties.user_role == 'user' ? $t('recaps.my_recap') : $t('recaps.recap_admin') }}
            </sw-badge>
          </div>

          <div class="flex-1 whitespace-nowrap right">
              <div
              class="mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900"
              v-html="''"
            />
            <div
              class="text-sm not-italic font-normal leading-5 text-right text-gray-600"
            >
              {{ format(recap.recap_date) }}
            </div>
          </div>
        </router-link>
        <p
          v-if="!recaps.length"
          class="flex justify-center px-4 mt-5 text-sm text-gray-600"
        >
          {{ $t('recaps.no_matching_recaps') }}
        </p>
      </div>
    </div>

    <div
      class="flex flex-col min-h-0 mt-8 overflow-hidden"
      style="height: 75vh"
    >
      <iframe
        :src="`${shareableLink}`"
        class="flex-1 border border-gray-400 border-solid rounded-md frame-style"
      />
    </div>
  </base-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Ls from '../../services/ls'
import 'moment/locale/fr'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import {
  DotsHorizontalIcon,
  FilterIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  SearchIcon,
  LinkIcon,
  TrashIcon,
  PencilIcon,
  DownloadIcon,
  XCircleIcon,
  CheckCircleIcon,
  PlusIcon
} from '@vue-hero-icons/solid'

const _ = require('lodash')
export default {
  components: {
    DotsHorizontalIcon,
    FilterIcon,
    DownloadIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    SearchIcon,
    PlusIcon,
    LinkIcon,
    PencilIcon,
    TrashIcon,
    XCircleIcon,
    VueDatePicker,
    CheckCircleIcon,
  },
  data() {
    return {
      id: null,
      count: null,
      recaps: [],
      recap: null,
      isReceiptAvailable: false,
      canEdit : false,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null,
      },
      isRequestOnGoing: false,
      isSearching: false,
      isSendingEmail: false,
      isMarkingAsSent: false,
      filters : {
        user : null,
        recap_date : null,
      },
      filtersRecapDate : null,
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    pageTitle() {
      moment.locale('fr')
      return moment(this.recap.recap_date).format('MMMM YYYY')
    },
    getOrderBy() {
      if (
        this.searchData.orderBy === 'asc' ||
        this.searchData.orderBy == null
      ) {
        return true
      }
      return false
    },
    getOrderName() {
      if (this.getOrderBy) {
        return this.$t('general.ascending')
      }
      return this.$t('general.descending')
    },
    shareableLink() {
      return `/recaps/${this.recap.id}/show/${this.$route.params.media}`
    },
  },
  watch: {
    $route(to, from) {
      this.loadRecap()
    },
    filtersRecapDate (val) {
      this.onSearch()
    }
  },
  created() {
    console.log(this.$route.params)
    this.loadRecaps()
    this.loadRecap()
    this.onSearch = _.debounce(this.onSearch, 500)
  },
  methods: {
    ...mapActions('recap', [
      'fetchRecaps',
      'searchRecap',
      'deleteRecap',
      'selectRecap',
      'fetchRecap',
      'getRecapFile',      
      'markAsAccepted',
      'markAsRejected',
    ]),

    ...mapActions('modal', ['openModal']),

    hasActiveUrl(id) {
      return this.$route.params.media == id
    },

    format(date) {
      moment.locale('fr')
      return moment(date).format('MMMM YYYY')
    },

    onSelectUser(user) {
      this.filters.user = user
      this.onSearch()
    },
    async clearUserSearch(removedOption, id) {
      this.filters.user = ''
      this.onSearch()
    },

    async loadRecaps() {
        let response = await this.fetchRecaps({limit : 'all'})
      // console.log(response)
      if (response.data) {
        this.recaps = response.data.recaps.data
      }
      setTimeout(() => {
        this.scrollToRecap()
      }, 500)
    },
    scrollToRecap() {
      const el = document.getElementById(`recap-${this.$route.params.id}`)

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
        el.classList.add('shake')
      }
    },
    async loadRecap() {
      let response = await this.fetchRecap(this.$route.params.id)
      console.log(response)
      if (response.data) {
        this.recap = response.data.recap
        let media = response.data.recap.media.filter(med => med.id == this.$route.params.media);
        console.log(media)
        if(media.length > 0)
          this.canEdit = media[0].custom_properties.user_role == this.currentUser.role
      }
      this.getFile()
    },

    async onSearch() {
      let data = 'limit=all&'
      if(this.filters.user) {
        data += `user_id=${this.filters.user.id}&`
      }
      if(this.filtersRecapDate) {
        data += `recap_date=${moment(this.filtersRecapDate).format('YYYY-MM-DD')}&`
      }
      if (
        this.searchData.searchText !== '' &&
        this.searchData.searchText !== null &&
        this.searchData.searchText !== undefined
      ) {
        data += `search=${this.searchData.searchText}&`
      }

      if (
        this.searchData.orderBy !== null &&
        this.searchData.orderBy !== undefined
      ) {
        data += `orderBy=${this.searchData.orderBy}&`
      }

      if (
        this.searchData.orderByField !== null &&
        this.searchData.orderByField !== undefined
      ) {
        data += `orderByField=${this.searchData.orderByField}`
      }
      this.isSearching = true
      let response = await this.searchRecap(data)
      this.isSearching = false
      if (response.data) {
        this.recaps = response.data.recaps.data
      }
    },
    sortData() {
      if (this.searchData.orderBy === 'asc') {
        this.searchData.orderBy = 'desc'
        this.onSearch()
        return true
      }
      this.searchData.orderBy = 'asc'
      this.onSearch()
      return true
    },

    async getFile() {
      let res = await this.getRecapFile(this.$route.params.id)
        console.log(res)
      if (res.data.error) {
        this.isReceiptAvailable = false
        return true
      }

      this.isReceiptAvailable = true
    },

    async editRecap(id) {
      let response = await this.fetchRecap(id)
      let recap = response.data.recap
      this.isSearching = true
      this.openModal({
        title:  Ls.get('currentRole') != 'user' ? this.$tc('recaps.edit_recap',1) : this.$tc('recaps.edit_recap',2),
        componentName: 'RecapModal',
        id: id,
        data: recap,
      })
      .then((res) => {
        this.isSearching = false
      })
    },

    addRecap() {
      this.openModal({
        title: this.$tc('recaps.add_new_recap',1) ,
        componentName: 'RecapModal',
      })
    },

    async removeRecap(id,media) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('recaps.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            let request = await this.deleteRecap({ ids: [id] , media : media })
            if (request.data.success) {
              window.toastr['success'](this.$tc('recaps.deleted_message', 1))
              this.$router.push('/admin/recaps')
            } else if (request.data.error) {
              window.toastr['error'](request.data.message)
            }
          }
        })
    },

    async onMarkAsAccepted(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('recaps.confirm_mark_as_accepted'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsAccepted) => {
        if (markedAsAccepted) {
          const data = {
            id: id,
            status: 'ACCEPTED',
          }
          let response = await this.markAsAccepted(data)

          if (response.data) {
            this.isSearching = true
           let res = await this.fetchRecaps({user_id : this.$route.params.user ,limit:'all'})
            if (res.data) {
              this.recaps = res.data.recaps.data
            }
            this.isSearching = false
            window.toastr['success'](
              this.$tc('recaps.marked_as_accepted_message')
            )
          }
        }
      })
    },

    async onMarkAsRejected(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('recaps.confirm_mark_as_rejected'),
        icon: '/assets/icon/times-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (markedAsRejected) => {
        if (markedAsRejected) {
          const data = {
            id: id,
            status: 'REJECTED',
          }

          let response = await this.markAsRejected(data)

          if (response.data) {
             this.isSearching = true
            let res = await this.fetchRecaps({user_id : this.$route.params.user, limit:'all'})
            if (res.data) {
              this.recaps = res.data.recaps.data
            }
            this.isSearching = false
            window.toastr['success'](
              this.$tc('recaps.marked_as_rejected_message')
            )
          }
        }
      })
    },

  },
}
</script>
